import axiosInstance from "./AxiosConfig";

export const createCreditSimulatorApi = async values => axiosInstance.post(`/credit-simulator/create`, values);

export const archiveCreditSimulatorApi = async (creditSimulatorId, shouldArchive) =>
  axiosInstance.post(`/credit-simulator/archive`, { credit_id: creditSimulatorId, archive: shouldArchive });

export const duplicateCreditSimulatorApi = async (creditSimulatorId, newName) =>
  axiosInstance.post(`/credit-simulator/duplicate`, { credit_id: creditSimulatorId, credit_name: newName });

export const fetchAllCreditSimulatorsApi = async userId =>
  axiosInstance.get("/credit-simulator", {
    params: {
      method: "user",
      id: userId,
    },
  });

export const fetchCreditSimulatorApi = async creditSimulatorId =>
  axiosInstance.get("/credit-simulator", {
    params: {
      method: "credit-simulator",
      id: creditSimulatorId,
    },
  });

export const updateCreditSimulatorApi = async (creditSimulatorId, values) =>
  axiosInstance.put(`/credit-simulator/edit/${creditSimulatorId}`, values);

export const deleteCreditSimulatorApi = async creditSimulatorId =>
  axiosInstance.delete(`/credit-simulator/delete/${creditSimulatorId}`);
