import React from "react";
import { ReactComponent as ClassicStar } from "../../../../assets/pdf/icons/star.svg";
import { ReactComponent as BrightStar } from "../../../../assets/pdf/icons/bright_star.svg";

function Star({ width = "23px", color = "var(--gold)", className = "", onClick, isBright }) {
  return isBright ? (
    <BrightStar
      style={{ "--color": color, "fill": "var(--color)", "cursor": onClick ? "pointer" : "auto", "marginTop": "2px" }}
      width={width}
      height={width}
      className={className + (onClick ? " clickable-icon" : "")}
      onClick={onClick}
    />
  ) : (
    <ClassicStar
      style={{ "--color": color, "fill": "var(--color)", "cursor": onClick ? "pointer" : "auto" }}
      width={width}
      height={width}
      className={className + (onClick ? " clickable-icon" : "")}
      onClick={onClick}
    />
  );
}

export default Star;
