import "./CirclePercentagePdf.css";
import React from "react";
// data = {
//   percentage,
//   euroAmount,
//   percentageText,
//   euroAmountText,
// };
function CirclePercentagePdf({ data }) {
  function formatEuroAmount(amount) {
    return String(amount).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  function getFontSizeEuroAmount(content) {
    if (content.length < 6) return "2.3rem";
    if (content.length < 9) return "2rem";
    return "1.7rem";
  }

  return (
    <div className='circle-growth-container pdf-mb'>
      <div className='circle-growth-item'>
        <div className='circle-growth-percentage-amount' style={{ "--percentage": `${data.percentage}%` }}>
          {data.percentage}%
        </div>
        <p className='circle-growth-text'>{data.percentageText}</p>
      </div>
      <div className='circle-growth-item'>
        <div
          className='circle-growth-percentage-amount'
          style={{ fontSize: getFontSizeEuroAmount(formatEuroAmount(data.euroAmount)) }}>
          {formatEuroAmount(data.euroAmount)} €
        </div>
        <p className='circle-growth-text'>{data.euroAmountText}</p>
      </div>
    </div>
  );
}

export default CirclePercentagePdf;
