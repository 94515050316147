import "./FinancingPlanForm.css";
import React, { useEffect, useState } from "react";
import Toggle from "../../../components/molecules/formComponents/toggle/Toggle";
import FinancingPlanFdc from "./financingPlanFdc/FinancingPlanFdc";
import FinancingPlanMurs from "./financingPlanMurs/FinancingPlanMurs";
import {
  createDownloadLink,
  formatNumberWithSpaces,
  formatObjectForPosting,
  normalizeString,
  removeSpaces,
  roundUp500,
} from "../../../utils/Utils";
import UnfilledButton from "../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import { FinancingPlanContext } from "./financingPlanContext/FinancingPlanContext";
import { useContext } from "react";
import AppContext from "../../../context/AppContext";
import { set, useForm } from "react-hook-form";
import Input from "../../molecules/formComponents/input/Input";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { formatMultiplesInputsValues } from "../../molecules/formComponents/multiplesInputs/MultiplesInputs";
import { createFinancingPlanApi, fetchFinancingPlanApi, updateFinancingPlanApi } from "../../../api/FinancingPlanApi";
import { useAuth } from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import ArrowSm from "../../atoms/icons/general/arrowSm/ArrowSm";
import { downloadFinancingPlanPdfApi } from "../../../api/DownloadApi";
import { fetchUserEstimationsApi } from "../../../api/EstimationApi";
import LoginForm from "../account/loginForm/LoginForm";
import { useWindowScroll } from "@uidotdev/usehooks";

function FinancingPlanForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    unregister,
    watch,
    getValues,
    clearErrors,
  } = useForm();
  const inputForm = { register, setValue, unregister, watch };
  const [multipleInputsDefaultValues, setMultipleInputsDefaultValues] = useState({
    fdc: {
      postes: [],
      apports: [],
    },
    murs: { postes: [] },
  });
  const [estimationsInfos, setEstimationsInfos] = useState({ fdc: [], murs: [] });
  const [displayedEstimationsInfos, setDisplayedEstimationsInfos] = useState([]);
  const watchEstimation = watch("estimation");
  const [linkedEstimationInfos, setLinkedEstimationInfos] = useState(null);
  const [estimationsDropdownVisible, setEstimationsDropdownVisible] = useState(false);
  const { planTypeParam, planIdParam } = useParams();
  const [URLSearchParams, setURLSearchParams] = useSearchParams();
  const { getUuid } = useAuth();

  const [windowScroll] = useWindowScroll();

  const {
    createNotification,
    setAppLoaderVisible,
    isDownloadNotificationLoading,
    setIsDownloadNotificationLoading,
    setModalVisible,
    setModalContent,
  } = useContext(AppContext);

  const navigate = useNavigate();

  const defaultValues = {
    murs: { taux_emprunt: 5, ratio_frais_notaire: 7.5 },
    fdc: { taux_emprunt: 4, fonds_roulement: 10000 },
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    switch (planTypeParam) {
      case "fdc":
        setValue("plan_type_id", 0);
        break;
      case "murs":
        setValue("plan_type_id", 1);
        break;
      default:
        navigate("/404");
    }

    setChosenEstimation(null);
    setDisplayedEstimationsInfos(estimationsInfos[planTypeParam]);
  }, [planTypeParam]);

  useEffect(() => {
    if (!watchEstimation || watchEstimation === "...") {
      return setValue("estimation_id", null);
    }
  }, [watchEstimation]);

  async function fetchAllData() {
    const estimationId = URLSearchParams.get("estimation-id");

    if (planIdParam || estimationId) setAppLoaderVisible(true);

    const estimationsList = await fetchEstimationsInfos();

    if (estimationId) {
      setChosenEstimation(
        estimationsList[planTypeParam].find(estimation => Number(estimation.id) === Number(estimationId)),
      );

      URLSearchParams.delete("estimation-id");
      setURLSearchParams(URLSearchParams, { replace: true });
      return setAppLoaderVisible(false);
    }
    if (planIdParam) await fetchFinancingPlan(planIdParam, estimationsList[planTypeParam]);
  }

  async function fetchEstimationsInfos() {
    try {
      const res = (await fetchUserEstimationsApi(getUuid(), false, true)).data;

      const estimationsList = {
        fdc: res["Fonds de commerce"]
          .filter(estimation => !estimation.archived && estimation.validated)
          .map(estimation => ({
            ...estimation.financing_plan_data,
            allYearsChecked: false,
            id: estimation.id,
            ebe_by_year: estimation.financing_plan_data.ebe_by_year
              .map(ebe => formatEstimationEbe(ebe))
              .sort((a, b) => a.year - b.year),
          })),
        murs: res["Murs commerciaux"]
          .filter(estimation => !estimation.archived && estimation.validated)
          .map(estimation => ({
            ...estimation.financing_plan_data,
            allYearsChecked: false,
            id: estimation.id,
          })),
      };

      setEstimationsInfos(estimationsList);
      setDisplayedEstimationsInfos(estimationsList[planTypeParam]);

      return estimationsList;
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de vos estimations. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
      return estimationsInfos;
    }
  }

  async function fetchFinancingPlan(planId, estimationsList) {
    try {
      const res = (await fetchFinancingPlanApi(planId)).data.financing_plan;

      setValue("financing_plan_name", res.financing_plan_name);
      setValue("estimation_id", res.estimation_id || null);

      if (res.estimation_id) {
        const estimation = estimationsList.find(est => Number(est.id) === Number(res.estimation_id));
        if (estimation) {
          setValue("estimation", estimation.estimation_name);
          setLinkedEstimationInfos({ ...estimation, type: planTypeParam });
        } else {
          setValue("estimation", "Estimation non trouvée");
        }
      } else {
        setValue("estimation", "Non rattaché à une estimation");
      }

      if (res.financing_plan_type === "murs") fillMursValues(res);
      else fillFdcValues(res);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de votre plan de financement, veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setAppLoaderVisible(false);
    }
  }

  async function formSubmit(values, download) {
    const postValues = planTypeParam === "murs" ? formatMursValues(values) : formatFdcValues(values);

    if (!getUuid())
      return showLoginModal(postValues, async () => {
        const planId = await saveFinancingPlan(postValues, download);

        if (download) downloadFinancingPlan(planId);
      });
    const planId = await saveFinancingPlan(postValues, download);

    if (download) downloadFinancingPlan(planId);
  }

  async function saveFinancingPlan(postValues, download) {
    let planId;

    try {
      if (planIdParam) {
        const res = (await updateFinancingPlanApi(postValues, planIdParam)).data.financing_plan;
        planId = res.financing_plan_id;

        fillMursValues(res);
      } else {
        const res = (await createFinancingPlanApi(postValues, planTypeParam)).data.financing_plan;
        planId = res.financing_plan_id;

        navigate(`/plan-financement/${planTypeParam}/${res.financing_plan_id}`, { replace: true });
        fillMursValues(res);
      }

      if (!download) createNotification(<>Votre plan de financement a été sauvegardé avec succès</>);
      setModalVisible(false);
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors de la sauvegarde de votre plan de financement, veuillez réessayer
          <br />
          Code d'erreur : {error.response.status}
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      return planId;
    }
  }

  function formatEstimationEbe(ebe) {
    return {
      ebe_retraite: ebe.ebe_retraite,
      year: ebe.year.split("/")[2],
      checked: false,
    };
  }

  async function downloadFinancingPlan(planId) {
    try {
      setIsDownloadNotificationLoading(true);

      const res = await downloadFinancingPlanPdfApi(planId);

      const link = createDownloadLink(res.data);
      link.click();
      link.remove();

      createNotification(<>Le plan de financement a été téléchargé avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du téléchargement du plan de financement. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setIsDownloadNotificationLoading(false);
    }
  }

  function showLoginModal(postValues, callback) {
    setModalContent({
      title: "Connexion",
      content: (
        <LoginForm
          onSuccess={() => {
            callback(postValues);
          }}
          isModal={true}
        />
      ),
    });
    setModalVisible(true);
  }

  function formatFdcValues(values) {
    const estimation_id = values.estimation_id || null;
    values = formatObjectForPosting(values);

    delete values.estimation_id;
    if (values.fdc) {
      delete values.fdc.estimation_id;
    }

    const postes = formatMultiplesInputsValues(values.fdc.postes);
    const apports = formatMultiplesInputsValues(values.fdc.apports);
    delete values.fdc.postes;
    delete values.fdc.apport;

    for (const key in values.fdc) {
      if (!values.fdc[key]) values.fdc[key] = 0;
    }

    return {
      financing_plan_name: values.financing_plan_name,
      ...values.fdc,
      inputs: { postes: postes, apports: apports },
      estimation_id: estimation_id,
    };
  }

  function formatMursValues(values) {
    const estimation_id = values.estimation_id || null;
    values = formatObjectForPosting(values);

    delete values.estimation_id;
    if (values.murs) {
      delete values.murs.estimation_id;
    }

    const postes = formatMultiplesInputsValues(values.murs.postes);
    delete values.murs.postes;

    for (const key in values.murs) {
      if (!values.murs[key]) values.murs[key] = 0;
    }

    return {
      financing_plan_name: values.financing_plan_name,
      ...values.murs,
      inputs: { postes: postes },
      estimation_id: estimation_id,
    };
  }

  function fillMursValues(values) {
    setMultipleInputsDefaultValues(prev => ({ ...prev, murs: values.inputs }));

    delete values.financing_plan_id;
    delete values.financing_plan_name;
    delete values.user_id;
    Object.keys(values).forEach(key => setValue(`murs.${key}`, values[key]));
  }

  function fillFdcValues(values) {
    setMultipleInputsDefaultValues(prev => ({ ...prev, fdc: values.inputs }));

    delete values.financing_plan_id;
    delete values.financing_plan_name;
    delete values.user_id;

    Object.keys(values).forEach(key => setValue(`fdc.${key}`, values[key]));
  }

  function getValidation(visible) {
    return {
      required: {
        value: visible,
        message: "Ce champ est obligatoire",
      },
    };
  }

  function switchPlanType(e) {
    navigate(`/plan-financement/${e.target.checked ? "murs" : "fdc"}`, { replace: true });
  }

  function getFormValues(names) {
    const result = {};

    names.map(name => {
      result[name] = removeSpaces(getValues(`${planTypeParam}.${name}`));
    });

    return result;
  }

  function setFormValue(name, value, isPercentage) {
    if (isPercentage) value = Math.round(value * 10) / 10;
    else value = Math.round(value);

    if (value != getValues(`${planTypeParam}.${name}`))
      setValue(`${planTypeParam}.${name}`, formatNumberWithSpaces(value, true));
  }

  async function savePlan(download) {
    handleSubmit(async data => {
      await formSubmit(data, download);
    })();
  }

  function setChosenEstimation(estimation) {
    let inputName = planTypeParam === "murs" ? "murs.prix_murs" : "fdc.prix_vente";
    let inputValue = removeSpaces(getValues(inputName));

    if (estimation) {
      setValue("estimation", estimation.estimation_name);
      setValue("estimation_id", estimation.id);
      setLinkedEstimationInfos({ ...estimation, type: planTypeParam });

      inputValue = roundUp500(planTypeParam === "murs" ? estimation?.value_murs : estimation?.value_fdc);
    } else {
      setValue("estimation", "...");
      setValue("estimation_id", null);
      setLinkedEstimationInfos({ type: planTypeParam });
    }

    if (inputValue) setValue(inputName, formatNumberWithSpaces(inputValue, true));
    clearErrors("estimation");
    setEstimationsDropdownVisible(false);
  }

  function closeDropdown() {
    setTimeout(() => {
      setEstimationsDropdownVisible(false);
    }, 300);
  }

  function onSearchbarChange(e) {
    if (e.target.value === "") return setDisplayedEstimationsInfos(estimationsInfos[planTypeParam]);

    setDisplayedEstimationsInfos(
      estimationsInfos[planTypeParam].filter(estimation =>
        normalizeString(estimation.estimation_name).includes(normalizeString(e.target.value)),
      ),
    );
  }

  return (
    <FinancingPlanContext.Provider
      value={{
        register,
        linkedEstimationInfos,
        setLinkedEstimationInfos,
        getFormValues,
        setFormValue,
        getValues,
        setValue,
        errors,
        getValidation,
        inputForm,
        planIdParam,
        watch,
        defaultValues,
        multipleInputsDefaultValues,
      }}>
      <div className='financing-plan-page-container'>
        <form onSubmit={savePlan}>
          <fieldset className='borderless-fieldset full-page-form'>
            <div className='centered'>
              <p className='form-subtitle my-sm'>
                Note : certains montants calculés automatiquement sont modifiables manuellement
              </p>
              {!planIdParam && (
                <Toggle
                  useForm={inputForm}
                  bgColor='var(--sky-blue)'
                  label1='Murs commerciaux'
                  label='Fonds de commerce'
                  name='plan_type_id'
                  onChange={switchPlanType}
                />
              )}
            </div>
            <div className='input-row-financing-plan'>
              <Input
                useForm={inputForm}
                label='Intitulé du plan de financement'
                bgColor='var(--pale-blue)'
                name='financing_plan_name'
                error={errors?.financing_plan_name?.message}
              />
              <Input
                useForm={inputForm}
                label='Rattacher ce plan de financement à une estimation'
                placeholder='Cliquez et déroulez, ou cherchez par mot-clé...'
                bgColor='var(--pale-blue)'
                name='estimation'
                onChange={onSearchbarChange}
                icon='search'
                preventAutoComplete
                validation={{}}
                onFocus={() => {
                  setEstimationsDropdownVisible(true);
                }}
                onBlur={closeDropdown}>
                <input type='hidden' {...register("estimation_id")} />
                <ul className={"estimations-list" + (estimationsDropdownVisible ? "" : " h-none")}>
                  <li
                    className={getValues("estimation_id") === null ? "selected" : ""}
                    onClick={() => setChosenEstimation(null)}>
                    ...
                  </li>
                  {displayedEstimationsInfos.map((estimation, key) => (
                    <li
                      key={key}
                      className={Number(getValues("estimation_id")) === Number(estimation.id) ? "selected" : ""}
                      onClick={() => setChosenEstimation(estimation)}>
                      {estimation.estimation_name}
                    </li>
                  ))}
                  {displayedEstimationsInfos.length === 0 && <li className='estimations-no-result'>Aucun résultat</li>}
                </ul>
              </Input>
            </div>
          </fieldset>
          <div className='financing-plan-container'>
            <FinancingPlanMurs visible={watch("plan_type_id")} />
            <FinancingPlanFdc visible={!watch("plan_type_id")} />
          </div>
        </form>
        <div className={`financing-plan-buttons ${windowScroll.y > 400 ? "show" : ""}`}>
          <div className='financing-plan-save-download-buttons'>
            <UnfilledButton onClick={() => savePlan()} padding='10px 25px'>
              Enregistrer
            </UnfilledButton>
            <FilledButton onClick={() => savePlan(true)} padding='10px 25px' isLoading={isDownloadNotificationLoading}>
              Télécharger
            </FilledButton>
          </div>
          {getUuid() && (
            <Link className='link-with-arrow' to='/mon-compte/mes-outils/mes-plans-de-financement'>
              <ArrowSm /> Accéder à mes plans de financement
            </Link>
          )}
        </div>
      </div>
    </FinancingPlanContext.Provider>
  );
}

export default FinancingPlanForm;
