import "./ContributionCalculator.css";
import React from "react";
import BreadCrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../components/molecules/banner/Banner";
import ContributionCalculatorForm from "../../../components/forms/contributionCalculatorForm/ContributionCalculatorForm";
import { useAuth } from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import ArrowSm from "../../../components/atoms/icons/general/arrowSm/ArrowSm";

function ContributionCalculator() {
  const { getUuid } = useAuth();
  return (
    <>
      <section className='container page-container'>
        <BreadCrumbs
          routes={[
            {
              to: "/",
              name: "Accueil",
            },
            {
              to: "/calculateur-cotisations",
              name: "Calculateur de cotisations",
            },
          ]}
        />
        <Banner title='Calculateur de cotisations' subtitle='Estimez le montant des cotisations patronales' />
        <ContributionCalculatorForm />
      </section>
    </>
  );
}

export default ContributionCalculator;
