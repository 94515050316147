import React, { useContext, useState } from "react";
import "./UpdateDeliverableModal.css";
import AppContext from "../../../../../context/AppContext";
import { useForm } from "react-hook-form";
import Input from "../../../../molecules/formComponents/input/Input";
import { defaultValidation } from "../../../../../utils/formValidation/FormValidation";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import { updateDeliverableApi } from "../../../../../api/FolderApi";

function UpdateDeliverableModal({ deliverable, onUpdateSuccess = () => {} }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      deliverableName: deliverable.name,
    },
  });
  const { setModalVisible, createNotification } = useContext(AppContext);

  async function onSubmit(data) {
    try {
      await updateDeliverableApi(deliverable.id, data.deliverableName);

      createNotification(<>Le livrable a été modifié avec succès</>);

      onUpdateSuccess();
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la modification du livrable</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setModalVisible(false);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='info-update-livrable'>
        <p>Modifiez le nom du livrable.</p>
      </div>
      <Input
        validation={defaultValidation}
        useForm={{ register, setValue }}
        error={errors.deliverableName?.message}
        name='deliverableName'
        label='Nom du livrable'
        placeholder='Nouveau nom du livrable'
      />
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' type='submit'>
          Enregistrer
        </FilledButton>
      </div>
    </form>
  );
}

export default UpdateDeliverableModal;
