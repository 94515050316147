import React, { Fragment } from "react";
import "./DescriptiveText.css";
import H3Pdf from "../h3Pdf/H3Pdf";

// data = [{
//   title: '',
//   isTitleBold: false,
//   content: [
//     {
//       normalText: '',
//       boldText: '',
//     },
//   ],
// }];

function DescriptiveText({ data }) {
  return (
    <>
      <div className='default-margin-placeholder' />
      {data.map((section, index) => (
        <Fragment key={section.id}>
          <p className={`descriptive-text-title ${section.isTitleBold ? "pdf-bold" : ""}  ${!index ? "mt-0" : ""}`}>
            {section.title}
          </p>
          {section.content.map((content, index) => (
            <Fragment key={index}>
              <p className='descriptive-text-content'>
                {content.normalText} <span className='pdf-bold'>{content.boldText}</span>
              </p>
              <div possible-page-break='true' />
            </Fragment>
          ))}
        </Fragment>
      ))}
    </>
  );
}

export default DescriptiveText;
