import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/icons/folder/folder.svg";

function Folder({ width = "30px", color = "var(--white)", className = "", onClick }) {
  return (
    <Icon
      style={{ "--color": color, "fill": "var(--color)", "cursor": onClick ? "pointer" : "auto" }}
      width={width}
      height={width}
      className={className + (onClick ? " clickable-icon" : "")}
      onClick={onClick}
    />
  );
}

export default Folder;
