import { generateHrefPdf } from "../summaryPdf/SummaryPdf";
import "./AnnexPdf.css";
import React from "react";

function AnnexPdf({ subtitles = [] }) {
  function getIconName(subtitle) {
    switch (subtitle) {
      case "Méthodes d'estimation":
        return "gear.svg";
      case "Points forts et points faibles de l'affaire":
      case "Points forts et points faibles du bien":
        return "stars.svg";
      case "Graphiques":
        return "bar_chart.svg";
      case "Informations complémentaires":
        return "light_bulb.svg";
      default:
        return null;
    }
  }
  return (
    <>
      <div className='annex-pdf-id' />
      <h2 h2-title='Annexes' importance-level={1} className='annex-title'>
        Annexes
      </h2>
      <div className='annex-pdf-subtitles'>
        {subtitles.map((subtitle, index) => {
          const icon = getIconName(subtitle);

          return (
            <a href={"#" + generateHrefPdf(subtitle)} key={index}>
              {icon && <img src={require(`../../../assets/pdf/annex/${icon}`)} alt={icon} />}
              {subtitle}
            </a>
          );
        })}
      </div>
    </>
  );
}

export default AnnexPdf;
