import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import NumberInput from "../../../../molecules/formComponents/numberInput/NumberInput";
import { max2250Validation } from "../../../../../utils/formValidation/FormValidation.js";
import Radio from "../../../../molecules/formComponents/radio/Radio";
import Select from "../../../../molecules/formComponents/select/Select";
import Textarea from "../../../../molecules/formComponents/textarea/Textarea";
import { ParcoursContext } from "../../../../../context/ParcoursContext";
import { formatObjectForPosting } from "../../../../../utils/Utils.js";
import Tuto from "../../../../atoms/icons/general/tuto/Tuto.js";
import AppContext from "../../../../../context/AppContext.js";

function InfosComplementaires({ index }) {
  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    reset,
  } = useForm();
  const [dropdownsValues, setDropdownsValues] = useState({});
  const inputForm = { watch, setValue, register, unregister };

  const {
    handleTabs,
    step,
    submitFormsMc,
    postValues,
    getStepToGo,
    setGoToStep,
    resetFormsRef,
    setStep,
    fetchDropdownValues,
    fillInputsFromEstimationValues,
    estimationValues,
  } = useContext(ParcoursContext);
  const { showTooltip } = useContext(AppContext);

  useEffect(() => {
    fillDropdownsValues();
  }, []);

  useEffect(() => {
    submitFormsMc.current[index] = handleSubmit(formSubmit);
    resetFormsRef.current[index] = () => reset();
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(["infos_complementaires"], setValue, estimationValues);

    setValue("notes.note_step_9", estimationValues?.notes?.note_step_9);
  }, [estimationValues]);

  async function formSubmit(values) {
    await postValues(formatObjectForPosting(values));

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    }
  }

  async function fillDropdownsValues() {
    setDropdownsValues(await fetchDropdownValues("murs_normes_accessibilite"));
  }

  return (
    <>
      <form onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
        <fieldset className='borderless-fieldset'>
          <h2>informations complémentaires</h2>
          <Select
            label={
              <>
                accessibilité pmr (personne à mobilité réduite)
                <Tuto onClick={() => showTooltip("step_11_accessibilite_pmr.webp")} />
              </>
            }
            error={errors?.infos_complementaires?.accessibilite_pmr?.id?.message}
            name='infos_complementaires.accessibilite_pmr.id'
            useForm={inputForm}>
            {dropdownsValues?.murs_normes_accessibilite?.map(value => (
              <option key={value?.id} value={value?.id}>
                {value?.description}
              </option>
            ))}
          </Select>
          <Radio
            direction='column'
            label="le local dispose-t-il d'une terrasse / jardin / terrain / piscine ?"
            useForm={inputForm}
            error={errors?.infos_complementaires?.equipement_exterieur?.id?.message}
            name='infos_complementaires.equipement_exterieur.id'
            content={[
              {
                label: "Sans objet ou locaux en étage uniquement",
                value: 1,
              },
              {
                label: "Non",
                value: 2,
              },
              {
                label: "Oui",
                value: 3,
              },
            ]}
          />
          <div className='row-1000 mb-sm'>
            <Radio
              direction='column'
              label={
                <>
                  un logement habitable est-il inclus dans le local ?
                  <Tuto onClick={() => showTooltip("step_10_logement_inclus.webp")} />
                </>
              }
              useForm={inputForm}
              error={errors?.infos_complementaires?.logement_inclus?.id?.message}
              name='infos_complementaires.logement_inclus.id'
              content={[
                {
                  label: "Non",
                  value: 1,
                },
                {
                  label: "Oui",
                  value: 2,
                },
              ]}
            />
            <Radio
              direction='column'
              label='la clientèle peut-elle stationner facilement pour accéder au local ?'
              useForm={inputForm}
              error={errors?.infos_complementaires?.stationnement_facile?.id?.message}
              name='infos_complementaires.stationnement_facile.id'
              content={[
                {
                  label: "Non",
                  value: 1,
                },
                {
                  label: "Oui",
                  value: 2,
                },
              ]}
            />
          </div>
        </fieldset>
        <fieldset className='borderless-fieldset'>
          <h2>notes et remarques</h2>
          <Textarea
            useForm={inputForm}
            validation={max2250Validation}
            error={errors?.notes?.note_step_9?.message}
            name='notes.note_step_9'
          />
        </fieldset>
        <fieldset className='borderless-fieldset'>
          <h2 className='flex gap-sm'>
            coefficients de pondération
            <Tuto onClick={() => showTooltip("step_9_coeff_ponderation.webp")} />
          </h2>
          <div className='form-subtitle'>A appliquer sur les valeurs obtenues à l'issue de l'estimation initiale</div>
          <NumberInput
            validation={{}}
            useForm={inputForm}
            icon='percent'
            label="fourchette de taux d'effort théorique"
            name='infos_complementaires.coef_ponderation.coef_1'
          />
          <NumberInput
            validation={{}}
            useForm={inputForm}
            icon='percent'
            label='fourchette de loyer effectif actuel'
            name='infos_complementaires.coef_ponderation.coef_2'
          />
          <NumberInput
            validation={{}}
            useForm={inputForm}
            icon='percent'
            label='fourchette de rentabilité attendue'
            name='infos_complementaires.coef_ponderation.coef_3'
            {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
          />
        </fieldset>
      </form>
    </>
  );
}

export default InfosComplementaires;
