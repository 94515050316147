import React from "react";
import "./Contact.css";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../components/molecules/banner/Banner";
import { Link } from "react-router-dom";
import ContactForm from "../../../components/forms/account/contactForm/ContactForm";

function Contact() {
  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            {
              to: "/",
              name: "Accueil",
            },
            {
              to: "/contact",
              name: "Contact",
            },
          ]}
        />
        <Banner title='Nous contacter' />
        <p>Une question ? Pour nous contacter, c'est par ici</p>
        <div className='contact-page-container'>
          <fieldset className='borderless-fieldset full-page-form'>
            <ContactForm />
            <div className='peronal-data-information'>
              <p>
                Les informations recueillies à partir de ce formulaire font l’objet d’un traitement informatique destiné
                à GALAXICO aux fins de traitement de votre demande de contact et de prospection commerciale. Ces données
                seront conservées pendant trois ans à compter de la date de votre dernier contact. Les champs identifiés
                par un astérisque portent sur des informations obligatoires ; à défaut de les renseigner, votre demande
                de contact ne pourra pas être traitée. Vous disposez sur ces informations nominatives vous concernant
                d’un droit d’accès, de rectification, de portabilité, d’effacement de celles-ci, de limitation et
                d’opposition à leur traitement ainsi que du droit de définir des directives générales et particulières
                définissant la manière dont vous entendez que soient exercés ces droits après votre décès. Vous pouvez
                exercer vos droits en vous adressant à GALAXICO par e-mail :{" "}
                <a href='mailto:contact@estimermoncommerce.fr'>contact@estimermoncommerce.fr</a> ou par courrier : 41
                rue de Cronstadt 75015 PARIS. Pour plus d’informations sur vos droits, veuillez consulter notre{" "}
                <Link to='/politique-confidentialite'>Politique de Confidentialité</Link>. Vous avez la possibilité
                d’introduire une réclamation auprès de la CNIL.
              </p>
            </div>
          </fieldset>
        </div>
      </section>
    </>
  );
}

export default Contact;
