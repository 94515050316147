import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/icons/account/copy.svg";

function Copy({ width = "30px", color = "var(--dark-blue)", className = "", onClick }) {
  return (
    <Icon
      style={{ "--color": color, "stroke": "var(--color)", "cursor": onClick ? "pointer" : "" }}
      width={width}
      height={width}
      className={className + (onClick ? " clickable-icon" : "")}
      onClick={onClick}
    />
  );
}

export default Copy;
