import "./HomepageTextImg.css";
import React, { useRef } from "react";
import { useWindowSize } from "@uidotdev/usehooks";

function HomepageTextImg({ text1, text2, img, imgAlt }) {
  const ref = useRef(null);
  const { width } = useWindowSize();

  return (
    <div ref={ref} className='homepage-text-img-container'>
      {width > 1200 ? (
        <>
          <p className='text-orange'>{text1}</p>
          <p>{text2}</p>
          <img src={img} alt={imgAlt} />
        </>
      ) : (
        <>
          <p className='text-orange'>{text1}</p>
          <p>{text2}</p>
          <img src={img} alt={imgAlt} />
        </>
      )}
    </div>
  );
}

export default HomepageTextImg;
