import React, { Fragment, useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../../components/molecules/banner/Banner";
import { useNavigate, useParams } from "react-router-dom";
import FilledButton from "../../../../components/molecules/buttons/filledButton/FilledButton";
import AppContext from "../../../../context/AppContext";
import {
  addSecondaryYearApi,
  deleteSecondaryYearApi,
  fetchEstimationWithSecondaryYearsApi,
} from "../../../../api/EstimationApi";
import Card from "../../../../components/molecules/cards/card/Card";
import "./Overview.css";
import UnfilledButton from "../../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import { formatEuroAmount } from "../../../../utils/Utils";
import Download from "../../../../components/atoms/icons/general/download/Download";
import Plus from "../../../../components/atoms/icons/general/plus/Plus";
import Rapport from "../../../../components/atoms/icons/account/rapport/Rapport";
import FinancingPlan from "../../../../components/atoms/icons/header/rapport/Rapport";
import { Link } from "react-router-dom";
import Trash from "../../../../components/atoms/icons/general/trash/Trash";
import AddBilanYearModal from "../../../../components/forms/modals/addBilanYearModal/AddBilanYearModal";
import ArrowSm from "../../../../components/atoms/icons/general/arrowSm/ArrowSm";

function Overview() {
  const { estimationIdParam } = useParams();
  const [estimationData, setEstimationData] = useState([]);
  const [shouldPreventPdfAccess, setShouldPreventPdfAccess] = useState(true);
  const [estimationType, setEstimationType] = useState(0); // 0 = mc / 1 = others
  const [mainYear, setMainYear] = useState("");
  const [isEstimationValidated, setIsEstimationValidated] = useState(false);
  const { createNotification, setModalVisible, setModalContent, setAppLoaderVisible } = useContext(AppContext);
  const unfinishedBilan = estimationData.find(bilan => !bilan.validated);
  const navigate = useNavigate();

  useEffect(() => {
    if (!/^\d+$/.test(estimationIdParam)) return navigate("/404");
    setAppLoaderVisible(true);

    fetchEstimationWithSecondaryYears();
  }, [estimationIdParam]);

  async function fetchEstimationWithSecondaryYears() {
    try {
      const data = (await fetchEstimationWithSecondaryYearsApi(estimationIdParam)).data;
      if (!data.base_estimation.length) return navigate("/404");

      let estimationYears = [];
      data.base_estimation[0].isMainYear = true;

      setIsEstimationValidated(data.base_estimation[0].validated);
      setMainYear(getBilanYear(data.base_estimation[0]));

      if (data.related_estimations) {
        estimationYears = [...data.base_estimation, ...data?.related_estimations].sort(
          (a, b) => getBilanYear(a) - getBilanYear(b),
        );
        setEstimationType(1);
      } else {
        estimationYears = data.base_estimation;
        setEstimationType(0);
      }

      let shouldPreventPdfAccess = false;

      estimationYears.forEach(
        bilan => (shouldPreventPdfAccess = bilan.validated && !shouldPreventPdfAccess ? false : bilan),
      );

      setShouldPreventPdfAccess(shouldPreventPdfAccess);
      setEstimationData(estimationYears);
      setAppLoaderVisible(false);
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors de la récupération de votre estimation.{" "}
          <span className='outfit-bold text-underline cursor-pointer' onClick={() => window.location.reload()}>
            Veuillez réessayer
          </span>
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function getBilanYear(bilan) {
    return Number(bilan.infos.date_bilan?.slice(-4));
  }

  function showAddYearForm(futureBilan, estimationData) {
    if (!isEstimationValidated) return;

    const existingYears = estimationData.map(bilan => getBilanYear(bilan));
    const mainYear = getBilanYear(estimationData.filter(bilan => bilan.isMainYear)[0]);
    const preSelectedYear = futureBilan ? existingYears.at(-1) + 1 : existingYears.at(0) - 1;
    let options = [];
    for (let i = 1; i < 15; i++) {
      options.push(mainYear + i);
      options.push(mainYear - i);
    }
    options = options.filter(option => !existingYears.includes(option)).sort();

    setModalContent({
      title: "Ajouter une année",
      content: <AddBilanYearModal options={options} preSelectedYear={preSelectedYear} onSubmit={addEstimationYear} />,
    });

    setModalVisible(true);
  }

  async function deleteSecondaryYear(id) {
    try {
      await deleteSecondaryYearApi(id);

      await fetchEstimationWithSecondaryYears();
      createNotification(<>Le bilan a été supprimé avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression du bilan. Veuillez réessayer plus tard</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
    setModalVisible(false);
  }

  async function addEstimationYear(date) {
    try {
      const id = (await addSecondaryYearApi(estimationIdParam, date)).data.id;

      setModalVisible(false);
      navigate(`/detail-parcours/secondaire/${id}`);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la création du bilan. Veuillez réessayer plus tard</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function checkPdfAccess(e, shouldPreventPdfAccess) {
    const unfinishedBilan = estimationData.find(bilan => !bilan.validated);

    if (!unfinishedBilan) return;

    e.preventDefault();
    setModalContent({
      title: "Estimation incomplète",
      content: (
        <>
          <p>
            {unfinishedBilan.type.id === 3
              ? "Le parcours n'a pas été terminé."
              : `L'année ${getBilanYear(unfinishedBilan)} n'a pas été complétée.`}
            <br />
            Veuillez l{unfinishedBilan.type.id === 3 ? "e" : "a"} compléter
            {!unfinishedBilan.isMainYear && " ou la supprimer"} afin d'accéder à votre rapport.
          </p>
          <div className='modal-buttons-row mt-md'>
            <UnfilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                setModalContent({ title: "", content: <></> });
              }}>
              Annuler
            </UnfilledButton>
            {!unfinishedBilan.isMainYear && (
              <UnfilledButton
                padding='10px 25px'
                onClick={() => {
                  setModalVisible(false);
                  setModalContent({ title: "", content: <></> });
                  showDeleteSecondaryYearModal(null, unfinishedBilan);
                }}>
                Supprimer l'année
              </UnfilledButton>
            )}
            <FilledButton
              padding='10px 25px'
              to={getParcoursUrl(unfinishedBilan)}
              onClick={() => {
                setModalVisible(false);
                setModalContent({ title: "", content: <></> });
              }}>
              Accéder {unfinishedBilan.type.id === 3 ? "au parcours" : "à l'année"}
            </FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  function showDeleteSecondaryYearModal(e, bilan) {
    e?.preventDefault();

    setModalContent({
      title: "Supprimer l'année",
      content: (
        <>
          <p>Voulez-vous supprimer l'année {getBilanYear(bilan)} ? </p>
          <div className='modal-buttons-row mt-md'>
            <UnfilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                setModalContent({ title: "", content: <></> });
              }}>
              Annuler
            </UnfilledButton>
            <FilledButton padding='10px 25px' onClick={() => deleteSecondaryYear(bilan.id)}>
              Supprimer
            </FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  function getInfoField(field, estimationData) {
    if (field === "activite") return estimationData[0]?.infos?.activite?.description;

    if (field === "adresse")
      return (
        estimationData[0]?.infos?.rue +
        " " +
        estimationData[0]?.infos?.code_postal +
        " " +
        estimationData[0]?.infos?.ville
      );

    return estimationData[0]?.infos[field];
  }

  function getParcoursUrl(bilan) {
    if (!bilan) return "";

    let type;

    switch (bilan?.type?.id) {
      case 1:
        type = "fonds-de-commerce";
        break;
      case 2:
        type = "titres-de-societe";
        break;
      case 3:
        type = "murs-commerciaux";
        break;
    }

    if (bilan.isMainYear) return `/detail-parcours/${type}/${bilan?.id}`;

    return `/detail-parcours/secondaire/${bilan?.id}`;
  }

  function getPdfUrl(estimationData) {
    if (!estimationType) return `/pdf/${estimationData[0]?.id}`;
    return `/pdf/${estimationData.filter(estimation => getBilanYear(estimation) === mainYear)[0]?.id}`;
  }

  return (
    <section className='page-container container'>
      <Breadcrumbs
        routes={[
          { name: "Accueil", to: "/" },
          { name: "Estimer : Synthèse", to: `/synthese/${estimationIdParam}` },
        ]}
      />
      <Banner
        title='Synthèse'
        subtitle={
          <Link className='link-with-arrow' to='/mon-compte/mes-outils/mes-estimations'>
            <ArrowSm /> Accéder à mes outils (estimations)
          </Link>
        }
      />
      <div className='synthese-container'>
        <div>
          <Card borderRadius padding='30px'>
            <h2 className='synthese-title'>Modifier / consulter mon estimation</h2>
            <hr className='synthese-hr' />
            {estimationType === 0 ? (
              <>
                <p className='synthese-bilans-subtitle'>
                  Vous pouvez modifier votre estimation en cliquant sur le bouton ci-dessous
                </p>
                <div className='centered mt-sm'>
                  <FilledButton padding='10px 25px' to={getParcoursUrl(estimationData[0])}>
                    Accéder au parcours d'estimation
                  </FilledButton>
                </div>
              </>
            ) : (
              <>
                <p className='synthese-bilans-subtitle'>
                  Consolidez facilement votre estimation en intégrant des années supplémentaires (en cliquant sur
                  <Plus width='20px' color='var(--blue)' />)
                </p>
                <div className='synthese-bilans-container'>
                  {estimationData.length < 4 && (
                    <>
                      <div
                        onClick={() => showAddYearForm(false, estimationData)}
                        data-main-year={mainYear}
                        className={`synthese-bilan-button ${isEstimationValidated ? "" : "disabled"}`}>
                        <Rapport className='synthese-rapport-icon' />
                        <UnfilledButton padding='5px' borderRadius='50%'>
                          <Plus color='var(--blue)' width='20px' className='clickable-icon' />
                        </UnfilledButton>
                      </div>
                      <div className='synthese-bilan-line' />
                    </>
                  )}
                  {estimationData.map((bilan, key) => {
                    return (
                      <Fragment key={key}>
                        {key > 0 && <div className='synthese-bilan-line' />}
                        <Link
                          to={getParcoursUrl(bilan)}
                          className={`synthese-bilan-button ${bilan.isMainYear ? "synthese-main-bilan" : ""}`}>
                          <Rapport className='synthese-rapport-icon' />
                          {!bilan.isMainYear && (
                            <Trash
                              color='var(--white)'
                              onClick={e => showDeleteSecondaryYearModal(e, bilan)}
                              className='synthese-delete-bilan-icon'
                              width='25px'
                            />
                          )}
                          <p>
                            Comptes <br />
                            <span>{getBilanYear(bilan)}</span>
                          </p>
                          <FilledButton padding='2px 5px' fontSize='0.8rem'>
                            Accéder
                          </FilledButton>
                        </Link>
                      </Fragment>
                    );
                  })}
                  {estimationData.length < 4 && (
                    <>
                      <div className='synthese-bilan-line' />
                      <div
                        onClick={() => showAddYearForm(true, estimationData)}
                        data-main-year={mainYear}
                        className={`synthese-bilan-button ${isEstimationValidated ? "" : "disabled"}`}>
                        <Rapport className='synthese-rapport-icon' />
                        <UnfilledButton padding='5px' borderRadius='50%'>
                          <Plus color='var(--blue)' width='20px' className='clickable-icon' />
                        </UnfilledButton>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </Card>
          <FilledButton
            fontSize='1.3rem'
            padding='20px'
            onClick={e => checkPdfAccess(e, shouldPreventPdfAccess)}
            to={getPdfUrl(estimationData)}
            target='_blank'
            className='get-rapport-button'>
            <Download /> Obtenir mon rapport d'estimation <Download />
          </FilledButton>
        </div>
        <div>
          <Card borderRadius padding='30px'>
            <h2 className='synthese-title'>
              {estimationType == 0 ? getInfoField("nom", estimationData) : getInfoField("enseigne", estimationData)}
            </h2>
            <hr className='synthese-hr' />
            <table className='synthese-infos-table'>
              <tbody>
                <tr>
                  <th>Adresse</th>
                  <td>{getInfoField("adresse", estimationData)}</td>
                </tr>
                {estimationType == 0 ? (
                  <>
                    <tr>
                      <th>Nom du bien</th>
                      <td>{getInfoField("nom", estimationData)}</td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <th>Raison sociale</th>
                      <td>{getInfoField("raison_sociale", estimationData)}</td>
                    </tr>
                    <tr>
                      <th>Enseigne</th>
                      <td>{getInfoField("enseigne", estimationData)}</td>
                    </tr>
                    <tr>
                      <th>N° siret</th>
                      <td>{getInfoField("siret", estimationData)}</td>
                    </tr>
                    <tr>
                      <th>Capital Social</th>
                      <td>{formatEuroAmount(getInfoField("capital_social", estimationData))}</td>
                    </tr>
                  </>
                )}
                <tr>
                  <th>Activité</th>
                  <td>{getInfoField("activite", estimationData)}</td>
                </tr>
              </tbody>
            </table>
          </Card>
          {(estimationType === 0 || (estimationType === 1 && estimationData[0]?.type?.id === 1)) && (
            <FilledButton
              to={`/plan-financement/${estimationType === 0 ? "murs" : "fdc"}?estimation-id=${estimationIdParam}`}
              padding='10px 25px'
              bgColor='var(--light-blue)'
              color='var(--dark-blue)'
              className='overview-financing-plan-button'
              disabled={!!unfinishedBilan}
              data-tooltip={unfinishedBilan ? `Le bilan ${getBilanYear(unfinishedBilan)} n'a pas été complété.` : ""}>
              <FinancingPlan />
              Ajouter un plan de financement
            </FilledButton>
          )}
        </div>
      </div>
    </section>
  );
}

export default Overview;
