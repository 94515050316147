import React, { useEffect, useRef, useState } from "react";
import { defaultValidation } from "../../../../utils/formValidation/FormValidation";
import "./Input.css";
import Magnifier from "../../../atoms/icons/general/magnifier/Magnifier.js";
import Warning from "../../../atoms/icons/general/warning/Warning";
import { toggleCollapseInput } from "../../../../utils/Utils.js";

// icon : euro / percent / search
// useForm = {
//   register: () => {},
//   unregister: () => {},
//   setValue: () => {},
//   watch: () => {},
// },
function Input({
  useForm = {},
  label,
  name,
  type = "text",
  defaultValue,
  validation = defaultValidation,
  error,
  icon,
  placeholder,
  visible = true,
  bgColor,
  tip,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onKeyDown = () => {},
  children,
  preventAutoComplete,
  value,
  disabled,
  className = "",
  shouldStickBottom = true, //false if the input should be aligned to the top of the row
  showWordsCount,
  showCharsCount,
}) {
  const { register = () => {}, unregister = () => {}, setValue = () => {}, watch = () => {} } = useForm;
  const [wordsCount, setWordsCount] = useState(0);
  const [charsCount, setCharsCount] = useState(0);
  const watcher = watch(name);
  const ref = useRef(null);

  const registerProps = visible ? register(name, validation) : {};

  useEffect(() => {
    toggleCollapseInput(ref.current, visible, 0);

    if (!visible) {
      unregister(name, { keepValue: true });
    } else {
      register(name, validation);
    }
  }, [visible]);

  useEffect(() => {
    showWordsCount && updateWordsCount(watcher);
    showCharsCount && updateCharsCount(watcher);
  }, [watcher]);

  function updateWordsCount(value) {
    value && setWordsCount(value.split(" ").filter(word => word.length).length);
  }

  function updateCharsCount(value) {
    value && setCharsCount(value.length);
  }

  function handleChange(e) {
    setValue(name, e.target.value);
    registerProps?.onChange(e);
    onChange(e);
  }

  return (
    <div
      className={`w-100 d-flex ${className} ${shouldStickBottom ? "" : "mt-0 mb-auto"}`}
      ref={ref}
      style={{ display: visible ? "block" : "none" }}>
      <div style={{ "--bg-color": bgColor }} className={`field-container ${bgColor ? "filled-input" : ""}`}>
        <label className='form-label' htmlFor={name}>
          {label}
        </label>
        <p className={`label-tip ${tip ? "" : " d-none"}`}>{tip}</p>
        <div className='input-words-count'>
          {showWordsCount && <>{wordsCount} mots</>}
          {showWordsCount && showCharsCount && " - "}
          {showCharsCount && <>{charsCount} caractères</>}
        </div>
        <span className={`input-container ${icon ? "before-" + icon : ""} ${error ? " with-error" : ""}`}>
          {icon === "search" && <Magnifier className='before-search-content' color='var(--dark-blue-alt)' />}
          <input
            {...registerProps}
            disabled={(visible !== undefined && !visible) || disabled}
            tabIndex={visible ? 0 : -1}
            placeholder={placeholder}
            className={icon ? `before-${icon}` : ""}
            id={name}
            value={value}
            autoComplete={preventAutoComplete ? "off" : "on"}
            type={type}
            onChange={e => {
              registerProps.onChange && registerProps.onChange(e);
              handleChange && handleChange(e);
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            defaultValue={type === "file" ? "" : defaultValue}
          />
          {children}
          {error && (
            <div className='form-error'>
              <Warning /> <p>{error}</p>
            </div>
          )}
        </span>
      </div>
    </div>
  );
}

export default Input;
