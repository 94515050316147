import React, { useContext } from "react";
import H2Pdf from "../h2Pdf/H2Pdf";
import { PdfContext } from "../../../context/PdfContext";

function NegativeValuePdf({ type = 1 }) {
  const { getEnseigneName, mainYear } = useContext(PdfContext);

  return (
    <>
      {type === 1 ? (
        <>
          <div className='estimation-result-container mb-pdf'>
            <p className='value'>0 €</p>
          </div>
          <div className='pdf-text-container pdf-vertical-margin'>
            <p className='pdf-large-text'>
              En fonction des divers éléments que vous avez renseignés, malheureusement la valeur du fonds de commerce
              {" " + getEnseigneName(mainYear)} est nulle.
            </p>
            <p className='pdf-bold text-center pdf-large-text'>Pourquoi cette non-valeur ?</p>
            <p className='pdf-bold pdf-large-text'>
              Car la rentabilité de l'affaire (la moyenne des EBE retraités) est négative.
            </p>
            <p>Dans le cadre d'une transmission, cela ne permet donc pas&nbsp;:</p>
            <ul className='dots-list'>
              <li>
                A un repreneur potentiel de projeter une reprise dans laquelle il pourra rembourser rembourser son prêt
                et se rémunérer
              </li>
              <li>Aux conseils du repreneur de valider la faisabilité de reprise</li>
              <li>A une banque de donner le feu vert au financement du projet</li>
            </ul>
            <p>
              De ce fait, malgré le chiffre d'affaires réalisé, la reprise éventuelle du fonds de commerce ne présente
              pas d'intérêt en tant que tel, car l'absence de rentabilité annihile sa valeur potentielle.
            </p>
            <p>
              Toutefois, en fonction de la qualité d'emplacement des locaux d'activités (s'il y en a) de leur surface et
              des caractéristiques du bail commercial (destination et lover, entre autres), ainsi que de leur
              cessibilité, ceux-ci peuvent avoir une valeur en tant que simple droit au bail.
            </p>
            <p>
              Cette valeur, qu'il n'est pas possible d'estimer ici, dépend de l'offre et de la demande spécifique à la
              zone d'implantation, ainsi que de son niveau d'attractivité.
            </p>
            <p>
              Enfin. le matériel et le mobilier peuvent faire l'obiet d'une valorisation à part. celle-ci dépendant de
              leur valeur comptable (en fonction des amortissements déjà réalisés) et de leur potentiel de cessibilité
              sur le marché.
            </p>
          </div>
          <div page-break='true' />
        </>
      ) : (
        <>
          <div className='pdf-text-container'>
            <p className='pdf-large-text pdf-bold'>
              En fonction des divers éléments que vous avez renseignés, nous estimons que malheureusement la valeur des
              titres de la société {getEnseigneName(mainYear)} est nulle.
            </p>
            <p>Pourquoi cette non-valeur ? Il y a deux possibilités&nbsp;:</p>
            <ul className='dots-list'>
              <li className='pdf-bold'> La rentabilité de l'affaire, après étude des retraitements, est négative.</li>
            </ul>
            <p>
              Cela ne permet donc pas à un repreneur potentiel de projeter une reprise dans laquelle il pourra se
              rémunérer, ni à ses conseils de valider la faisabilité de reprise, ni à une banque de donner le feu vert
              au financement du projet.
            </p>
            <p>
              De ce fait, malgré le chiffre d'affaires réalisé, la reprise éventuelle de l'affaire ne présente pas
              d'intérêt en tant que tel, car l'absence de rentabilité annihile sa valeur potentielle.
            </p>
            <ul className='dots-list'>
              <li className='pdf-bold'>
                Le montant des dettes (hors comptes courants d'associés, que l'on peut potentiellement décider
                d'abandonner lors d'une cession, contrairement aux autres dettes) excède (ou égalise) la valeur du fonds
                de commerce - dont la valeur se trouve en annexe (additionné de l'actif circulant et des éventuelles
                immobilisations financières).
              </li>
            </ul>
            <p>De ce fait, la valeur des titres de la société est égale ou inférieure à zéro</p>
            <p className='pdf-large-text'>Y a-t-il d'autres possibilités ?</p>
            <p>
              Certains schémas de reprises peuvent amener d'éventuels repreneurs à formuler des offres, avec pour
              objectif de réaliser de la croissance externe, de bénéficier de déficit (dans une optique fiscale), ou de
              considérer d'autres facteurs que l'unique rentabilité escomptée (par exemple des facteurs humains,
              culturels, historiques, symboliques). Dans ces cas la valeur sera celle de l'offre qui sera faite,
              probablement très basse.
            </p>
            <p>
              De plus, en fonction de la qualité d'emplacement des locaux d'activités (s'il y en a), de leur surface et
              des caractéristiques du bail commercial (destination et loyer, entre autres), ainsi que de leur
              cessibilité, ceux-ci peuvent avoir une valeur en tant que simple droit au bail. Cette valeur, qu'il n'est
              pas possible d'estimer ici, dépend de l'offre et de la demande spécifique à la zone d'implantation, que
              les professionnels du secteur devraient être en mesure de chiffrer.
            </p>
            <p>
              Enfin, les autres actifs (matériel & mobilier) de la société peuvent faire l'objet d'une cession à part,
              celle-ci dépendant de leur valeur comptable (en fonction des amortissements déjà réalisés) et de leur
              potentiel de cessibilité sur le marché.
            </p>
          </div>
          <div page-break='true' />
        </>
      )}
    </>
  );
}

export default NegativeValuePdf;
