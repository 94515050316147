import React, { useEffect, useContext, useState } from "react";
import { ParcoursContext } from "../../../../../context/ParcoursContext";
import { useForm } from "react-hook-form";
import axios from "axios";
import NumberInput from "../../../../molecules/formComponents/numberInput/NumberInput";
import { max2250Validation, numberValidation } from "../../../../../utils/formValidation/FormValidation.js";
import Radio from "../../../../molecules/formComponents/radio/Radio";
import Select from "../../../../molecules/formComponents/select/Select";
import Textarea from "../../../../molecules/formComponents/textarea/Textarea.js";
import { fetchDropdownValuesApi } from "../../../../../api/ParcoursApi.js";
import { formatObjectForPosting, getSelectValue, removeSpaces } from "../../../../../utils/Utils.js";

function CaracteristiquesLocal({ index }) {
  const {
    register,
    reset,
    handleSubmit,
    unregister,
    setValue,
    formState: { errors },
    watch,
  } = useForm();
  const inputForm = { watch, setValue, register, unregister };
  const {
    step,
    resetFormsRef,
    handleTabs,
    moveToNextStep,
    setStep,
    submitFormsMc,
    fetchDropdownValues,
    postValues,
    setGoToStep,
    getStepToGo,
    fillInputsFromEstimationValues,
    estimationValues,
  } = useContext(ParcoursContext);
  const [dropdownsValues, setDropdownsValues] = useState({});

  useEffect(() => {
    fillDropdownsValues();
  }, []);

  useEffect(() => {
    submitFormsMc.current[index] = handleSubmit(formSubmit);
    resetFormsRef.current[index] = () => reset();
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(["caracteristiques_local"], setValue, estimationValues);
    setValue("notes.note_step_8", estimationValues?.notes?.note_step_8);
  }, [estimationValues, dropdownsValues]);

  async function formSubmit(values) {
    await postValues(formatObjectForPosting(values));

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  async function fillDropdownsValues() {
    setDropdownsValues(await fetchDropdownValues("murs_etat_local"));
  }

  return (
    <>
      <form onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
        <fieldset className='borderless-fieldset'>
          <h2>caractéristiques du local</h2>
          <div className='row-1000 mb-sm'>
            <Radio
              label='local d’angle'
              direction='column'
              useForm={inputForm}
              error={errors?.caracteristiques_local?.local_angle?.id?.message}
              name='caracteristiques_local.local_angle.id'
              content={[
                {
                  label: "Sans objet",
                  value: 1,
                },
                {
                  label: "Non",
                  value: 2,
                },
                {
                  label: "Oui",
                  value: 3,
                },
              ]}
            />
            <Radio
              label='le local est-il aménagé ?'
              direction='column'
              useForm={inputForm}
              error={errors?.caracteristiques_local?.local_amenage?.id?.message}
              name='caracteristiques_local.local_amenage.id'
              content={[
                {
                  label: "Non",
                  value: 1,
                },
                {
                  label: "Oui",
                  value: 2,
                },
              ]}
            />
          </div>
          <div className='row-1000 mb-sm'>
            <Radio
              direction='column'
              label="le local dispose-t-il d'une réserve (espace de stockage) ?"
              useForm={inputForm}
              error={errors?.caracteristiques_local?.reserve?.id?.message}
              name='caracteristiques_local.reserve.id'
              content={[
                {
                  label: "Non",
                  value: 1,
                },
                {
                  label: "Oui",
                  value: 2,
                },
              ]}
            />
            <Radio
              direction='column'
              label="le local dispose-t-il d'un conduit d'extraction des fumées ?"
              useForm={inputForm}
              error={errors?.caracteristiques_local?.extraction?.id?.message}
              name='caracteristiques_local.extraction.id'
              content={[
                {
                  label: "Non",
                  value: 1,
                },
                {
                  label: "Oui",
                  value: 2,
                },
              ]}
            />
          </div>
          <div className='row-1000 mb-sm'>
            <Select
              label="quel est l'état général du local ?"
              error={errors?.caracteristiques_local?.etat_local?.id?.message}
              name='caracteristiques_local.etat_local.id'
              useForm={inputForm}>
              {dropdownsValues?.murs_etat_local?.map(value => (
                <option key={value.id} value={value.id}>
                  {value.description}
                </option>
              ))}
            </Select>
            <NumberInput
              useForm={inputForm}
              label='largeur de façade (en mètres linéaires) si local avec pignon sur rue'
              error={errors?.caracteristiques_local?.largeur_facade?.message}
              name='caracteristiques_local.largeur_facade'
            />
          </div>
          <Textarea
            useForm={inputForm}
            name='notes.note_step_8'
            validation={max2250Validation}
            error={errors?.notes?.note_step_8?.message}
            className='mt-md'
            label='notes et remarques'
            {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
          />
        </fieldset>
      </form>
    </>
  );
}

export default CaracteristiquesLocal;
