import TitleIcon from "../../atoms/pdf/icons/TitleIcon";
import { getTitleWithoutNumber } from "../summaryPdf/SummaryPdf";
import "./H3Pdf.css";
import React from "react";

function H3Pdf({ title, icon, className = "pdf-mt", notOnSummary, importanceLevel = 2 }) {
  return (
    <div
      className={`h3-pdf-container ${className}`}
      h3-title={getTitleWithoutNumber(title)}
      not-on-summary={notOnSummary ? "true" : null}
      importance-level={importanceLevel}>
      <h3>{title}</h3>
      {icon && <TitleIcon type={icon} />}
    </div>
  );
}

export default H3Pdf;
