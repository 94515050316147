import axiosInstance from "./AxiosConfig";

export const createFinancingPlanApi = async (values, planType) =>
  axiosInstance.post(`/financing-plan/${planType}/create`, values);

export const updateFinancingPlanApi = async (values, planId) =>
  axiosInstance.put(`/financing-plan/edit/${planId}`, values);

export const deleteFinancingPlanApi = async planId => axiosInstance.delete(`/financing-plan/delete/${planId}`);

export const archiveFinancingPlanApi = async (planId, shouldArchive) =>
  axiosInstance.post(`/financing-plan/archive`, { financing_plan_id: planId, archive: shouldArchive });

export const duplicateFinancingPlanApi = async (planId, newName) =>
  axiosInstance.post(`/financing-plan/duplicate`, { financing_plan_id: planId, financing_plan_name: newName });

export const fetchFinancingPlanApi = async planId =>
  axiosInstance.get("/financing-plan", {
    params: {
      method: "financing-plan",
      id: planId,
    },
  });

export const fetchAllFinancingPlanApi = async userId =>
  axiosInstance.get("/financing-plan", {
    params: {
      method: "user",
      id: userId,
    },
  });
