import React, { Fragment, useContext, useEffect, useState } from "react";
import H2Pdf from "../../../components/pdfComponents/h2Pdf/H2Pdf";
import PagePdf from "../../../components/pdfComponents/pagePdf/PagePdf";
import { PdfContext } from "../../../context/PdfContext";
import CoverPdf from "../coverPdf/CoverPdf";
import VisitingCard from "../../organisms/visitingCard/VisitingCard";
import descriptiveElements from "../../../uploads/PdfModels/mc_descriptive_elements.json";
import strongWeakPoints from "../../../uploads/PdfModels/mc_strong_weak_points.json";
import H3Pdf from "../h3Pdf/H3Pdf";
import MethodePdf from "../methodePdf/MethodePdf";
import { formatNumberWithSpaces, roundUp500 } from "../../../utils/Utils";
import FrameTextPdf from "../frameTextPdf/FrameTextPdf";
import StrongWeakPointsGroupPdf from "../strongWeakPointsGroupPdf/StrongWeakPointsGroupPdf";
import DescriptiveText from "../descriptiveText/DescriptiveText";
import BusinessImagesPdf from "../imagesPdf/BusinessImagesPdf";
import AnnexPdf from "../annexPdf/AnnexPdf";

function PdfMc() {
  const {
    visitingCard,
    rapportData,
    formatDataForStrongWeakPoints,
    formatDataForDescriptiveText,
    mainYear,
    AdditionalInformation,
  } = useContext(PdfContext);
  const [descriptiveTextData, setDescriptiveTextData] = useState(null);
  const [strongWeakPointsData, setStrongWeakPointsData] = useState(null);
  const [userNote, setUserNote] = useState(null);

  useEffect(() => {
    formatDataForStrongWeakPoints(rapportData?.forces_faiblesses, strongWeakPoints, setStrongWeakPointsData);
  }, [rapportData, strongWeakPoints]);

  useEffect(() => {
    formatDataForDescriptiveText(mainYear, descriptiveElements, setDescriptiveTextData);
  }, [mainYear, descriptiveElements]);

  useEffect(() => {
    setUserNote(mainYear?.notes?.note_step_9);
  }, [mainYear]);

  return (
    <>
      <div>
        <CoverPdf />
        <div page-break='true' />
        <BusinessImagesPdf />
        <div page-break='true' />
        {/* Visiting card */}
        {visitingCard && (
          <>
            <VisitingCard {...visitingCard} />
            {visitingCard?.mentions && (
              <div className='pdf-mt pdf-text-container'>
                <p className='pdf-bold'>Mentions légales</p>
                <p>{visitingCard.mentions}</p>
              </div>
            )}
            <div page-break='true' />
          </>
        )}
        {/* Introduction */}
        <>
          <H2Pdf title='Introduction : les méthodes de valorisation' number={1} />
          <div className='pdf-text-container pdf-mt'>
            <p>La valeur des murs commerciaux dépend de plusieurs paramètres principaux :</p>
            <p className='pdf-bold'>l'emplacement, la situation locative et la rentabilité escomptée.</p>
            <p>
              L'expérience terrain nous a appris que la valeur de murs commerciaux doit constituer un juste équilibre
              entre le souhait du propriétaire et l'approche de repreneurs potentiels (occupants ou investisseurs), la
              finançabilité du projet et son équilibre économique global.
            </p>
            <p>
              Cette valeur doit donc respecter avant tout des normes de marché, soit pour donner au cédant de réelles
              chances de trouver un repreneur, soit pour être cohérent avec les règles fiscales dans le cas de
              transmission ou de définition de patrimoine, et ainsi limiter le risque d'exposition à un redressement
              potentiel.
            </p>
            <p className='pdf-bold'>Il existe deux approches pour calculer la valeur des murs commerciaux :</p>
            <p className='pdf-gold-text pdf-large-text'>Méthode N°1 :</p>
            <p>
              C'est l'approche d'un acquéreur (souvent l'occupant des locaux) qui utilisera lui-même le local pour son
              exploitation. Cette méthode se base sur la situation locative, elle-même composée par le taux d'effort
              théorique pour l'activité exercée et par le loyer effectif en vigueur. Le taux d'effort théorique
              représente le niveau de loyer acceptable (au niveau national) pour l'activité concernée. Le loyer effectif
              en vigueur est celui que l'occupant paye au propriétaire actuellement.
            </p>
            <p className='pdf-gold-text pdf-large-text'>Méthode N°2 :</p>
            <p className='pdf-bold'>
              C'est l'approche d'un investisseur, dont l'objectif est de générer des revenus locatifs.
            </p>
            <p>
              Cette méthode se base sur les taux de rentabilité attendue en fonction des qualités d'emplacement, tout en
              intégrant le facteur de loyer effectif en vigueur.
            </p>
            <p>
              Dans le projet de cession de votre bien, il est fondamental de prendre en compte ces différentes approches
              complémentaires, car la valeur finale (le prix effectif de cession) représente un point de rencontre entre
              cédant et repreneur (ce dernier étant généralement entouré de conseils comptables et juridiques, et de
              partenaires financiers, dont l'avis compte dans le processus de validation du prix).
            </p>
            <p>
              Dans un projet autre qu'une transaction, le propriétaire, bien que conseillé et encadré par des normes
              fiscales et de bon sens, reste seul décisionnaire de la valeur de son bien. Dans ce cas il pourra se
              situer plus librement dans les fourchettes de valeurs ci-dessous.
            </p>
          </div>
          <div page-break='true' />
        </>
        {/* Descriptive elements */}
        <>
          <H2Pdf title='Les éléments constitutifs du bien' number={2} className='pdf-mb' />
          {descriptiveTextData?.map(section => (
            <Fragment key={section.id}>
              <H3Pdf title={section.title} icon={section.icon} className='mt-md' />
              <DescriptiveText data={section.content} />
              {section.conclusion && (
                <div className='pdf-text-container pdf-mb-sm'>
                  <p>{section.conclusion}</p>
                </div>
              )}
            </Fragment>
          ))}
          <div page-break='true' />
        </>
        {/* User note */}
        {userNote && (
          <>
            <H3Pdf title="Notes de l'utilisateur" />
            <div className='pdf-text-container'>
              <p>{userNote}</p>
            </div>
            <div page-break='true' />
          </>
        )}
        {/* Estimation (method 1) */}
        <>
          <H2Pdf title="L'estimation" number={3} />
          <MethodePdf methodeNumber='Méthode n°1' methodeApproach='Approche occupant-acquéreur' />
          <div className='pdf-estimation-value pdf-mt'>
            {formatNumberWithSpaces(roundUp500(rapportData?.calcs.methode_1.value), true, "€")}
          </div>{" "}
          <div className='pdf-text-container pdf-mb'>
            <p className='asterisk-value'>
              Montant de {formatNumberWithSpaces(rapportData?.calcs.methode_1.value, true, "€")} arrondi à{" "}
              {formatNumberWithSpaces(roundUp500(rapportData?.calcs.methode_1.value), true, "€")}
            </p>
          </div>
          <div className='pdf-text-container'>
            Cette valeur est la moyenne des valeurs ci-dessous, obtenues à partir de l'analyse sur mesure de la totalité
            des éléments constitutifs du bien&nbsp;:
          </div>
          <FrameTextPdf className='method-estimation pdf-vertical-margin'>
            <div className='pdf-text-container'>
              {rapportData?.calcs.methode_1.taux_effort.low_amount ? (
                <p>
                  <span className='pdf-bold'>En tenant compte du taux d'effort théorique</span>
                  <br />
                  la valeur des murs commerciaux est comprise
                  <br />
                  <span className='pdf-bold'>
                    entre {formatNumberWithSpaces(rapportData?.calcs.methode_1.taux_effort.low_amount, true, "€")} et{" "}
                    {formatNumberWithSpaces(rapportData?.calcs.methode_1.taux_effort.high_amount, true, "€")}, soit une
                    moyenne de {formatNumberWithSpaces(rapportData?.calcs.methode_1.taux_effort.mid_amount, true, "€")}
                  </span>
                </p>
              ) : (
                <></>
              )}
              <p className={rapportData?.calcs.methode_1.taux_effort.low_amount ? "pdf-mt" : ""}>
                <span className='pdf-bold'>En tenant compte du loyer effectif actuel</span>
                <br />
                la valeur des murs commerciaux est comprise
                <br />
                <span className='pdf-bold'>
                  entre {formatNumberWithSpaces(rapportData?.calcs.methode_1.loyer.low_amount, true, "€")} et{" "}
                  {formatNumberWithSpaces(rapportData?.calcs.methode_1.loyer.high_amount, true, "€")}, soit une moyenne
                  de {formatNumberWithSpaces(rapportData?.calcs.methode_1.loyer.mid_amount, true, "€")}
                </span>
              </p>
            </div>
          </FrameTextPdf>
          <div page-break='true' />
        </>
        {/* Estimation (method 2) */}
        <>
          <MethodePdf methodeNumber='Méthode n°2' methodeApproach='Approche investisseur' />
          <div className='pdf-estimation-value pdf-mt'>
            {formatNumberWithSpaces(roundUp500(rapportData?.calcs.methode_2.value), true, "€")}
          </div>
          <div className='pdf-text-container pdf-mb'>
            <p className='asterisk-value'>
              Montant de {formatNumberWithSpaces(rapportData?.calcs.methode_2.value, true, "€")} arrondi à{" "}
              {formatNumberWithSpaces(roundUp500(rapportData?.calcs.methode_2.value), true, "€")}
            </p>
          </div>
          <div className='pdf-text-container'>
            Cette valeur est la moyenne des valeurs ci-dessous, obtenues à partir de l'analyse sur mesure de la totalité
            des éléments constitutifs du bien&nbsp;:
          </div>
          <FrameTextPdf className='method-estimation pdf-vertical-margin'>
            <div className='pdf-text-container'>
              <p>
                <span className='pdf-bold'>En tenant compte de la rentabilité attendue</span>
                <br />
                la valeur des murs commerciaux est comprise
                <br />
                <span className='pdf-bold'>
                  entre {formatNumberWithSpaces(rapportData?.calcs.methode_2.rentabilite.low_amount, true, "€")} et{" "}
                  {formatNumberWithSpaces(rapportData?.calcs.methode_2.rentabilite.high_amount, true, "€")}, soit une
                  moyenne de {formatNumberWithSpaces(rapportData?.calcs.methode_2.rentabilite.mid_amount, true, "€")}
                </span>
              </p>
            </div>
          </FrameTextPdf>
          <h3 className='pdf-mb estimation-result-title'>Résultat global</h3>
          <div className='estimation-result-container'>
            <p>
              La moyenne des approches
              <br />
              occupant-acquéreur et investisseur
              <br />
              est de <br />
              <span className='value'>{formatNumberWithSpaces(roundUp500(rapportData?.calcs.global), true, "€")}</span>
            </p>
          </div>
          <div className='pdf-text-container pdf-mb'>
            <p className='asterisk-value'>
              Montant de {formatNumberWithSpaces(rapportData?.calcs.global, true, "€")} arrondi à{" "}
              {formatNumberWithSpaces(roundUp500(rapportData?.calcs.global), true, "€")}
            </p>
          </div>
          <div page-break='true' />
        </>
        {/* Annexes */}
        <>
          <AnnexPdf subtitles={["Points forts et points faibles du bien", "Informations complémentaires"]} />
        </>
        <div page-break='true' />
        {/* Strong / weak points */}
        <>
          <H2Pdf importanceLevel={2} title='Points forts et points faibles du bien' number={4} />
          {strongWeakPointsData?.map(category => (
            <StrongWeakPointsGroupPdf data={category} key={category.id} />
          ))}
          <div page-break='true' />
        </>
        {/* Strong weak points conclusion */}
        <>
          <div className='pdf-text-container'>
            <p>
              En fonction des spécificités de votre local d'activité, cette évaluation peut être affinée par les
              critères suivants :
            </p>
          </div>
          <FrameTextPdf className='pdf-vertical-margin'>
            <div className='pdf-text-container'>
              <p>
                <span className='pdf-bold'>La surface réelle et la surface pondérée.</span> Cette dernière réduit la
                première en tenant compte des espaces non directement liés à l'activité commerciale, tels que les
                sanitaires, les lieux de passage, mais tient également compte de la répartition éventuelle sur plusieurs
                niveaux, la facilité d'accès à une partie des locaux, etc.
              </p>
              <p>
                <span className='pdf-bold'>L'état général des locaux et des aménagements</span>, la quantité de travaux,
                rénovations et mises aux normes nécessaires ou indispensables.
              </p>
              <p>
                <span className='pdf-bold'>La visibilité du local</span>, déterminée par la présence en angle de rue,
                les longueurs linéaires de façade et de vitrine, la proximité de flux importants.
              </p>
              <p>
                <span className='pdf-bold'>La diversité des activités permises</span> dans les locaux par le règlement
                de copropriété et par les normes en vigueur.
              </p>
            </div>
          </FrameTextPdf>
          <div className='pdf-text-container'>
            <p className='pdf-bold'>
              Cette liste n'est pas exhaustive. Il convient de prendre en compte toute autre éventuelle spécificité,
              dont la nature évidente peut impacter à la hausse ou à la baisse la valeur des murs commerciaux.
            </p>
          </div>
          <div page-break='true' />
        </>
        {/* Additional information */}
        {AdditionalInformation}
      </div>
    </>
  );
}

export default PdfMc;
