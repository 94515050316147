import React, { useState, useContext, useEffect } from "react";
import { set, useForm } from "react-hook-form";
import "./CreditSimulatorForm.css";
import NumberInput from "../../molecules/formComponents/numberInput/NumberInput";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import Input from "../../molecules/formComponents/input/Input";
import UnfilledButton from "../../molecules/buttons/unfilledButton/UnfilledButton";
import {
  createCreditSimulatorApi,
  fetchCreditSimulatorApi,
  updateCreditSimulatorApi,
} from "../../../api/CreditSimulatorApi";
import AppContext from "../../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { CreditSimulatorContext } from "./creditSimulatorContext/CreditSimulatorContext";
import { createDownloadLink, removeSpaces } from "../../../utils/Utils";
import { decimalValidation } from "../../../utils/formValidation/FormValidation";
import { downloadCreditSimulatorPdfApi } from "../../../api/DownloadApi";
import LoginForm from "../account/loginForm/LoginForm";

function CreditSimulatorForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    unregister,
    watch,
    getValues,
  } = useForm();
  const inputForm = { register, setValue, unregister, watch };
  const {
    createNotification,
    setAppLoaderVisible,
    isDownloadNotificationLoading,
    setIsDownloadNotificationLoading,
    setModalVisible,
    setModalContent,
  } = useContext(AppContext);
  const [isSaving, setIsSaving] = useState(false);
  const { simulatorIdParam } = useParams();
  const { getUuid } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (simulatorIdParam) {
      fetchCreditSimulator(simulatorIdParam);
    }
  }, [simulatorIdParam]);

  async function fetchCreditSimulator(creditSimulatorId) {
    try {
      const { data: creditSimulator } = await fetchCreditSimulatorApi(creditSimulatorId);

      if (creditSimulator) {
        const formattedDate = formatDate(creditSimulator.credit_simulator.date_depart);
        setValue("credit_simulator_name", creditSimulator.credit_simulator.credit_name);
        setValue("montantPret", creditSimulator.credit_simulator.montant_pret);
        setValue("tauxHorsAssurance", creditSimulator.credit_simulator.taux_hors_assurance);
        setValue("tauxAssurance", creditSimulator.credit_simulator.taux_assurance);
        setValue("duree", creditSimulator.credit_simulator.duree);
        setValue("dateDepart", formattedDate);
      }
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du chargement de la simulation de prêt. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setAppLoaderVisible(false);
    }
  }

  async function handleSaveCreditSimulator(download) {
    handleSubmit(async data => {
      await onSubmit(data, download);
    })();
  }

  async function onSubmit(data, download) {
    download = download === true;

    if (!getUuid()) {
      showLoginModal(async () => {
        const simulatorId = await saveCreditSimulator(data, download);
        if (download) downloadCreditSimulation(simulatorId);
      });
      return;
    }

    const simulatorId = await saveCreditSimulator(data, download);
    if (download) downloadCreditSimulation(simulatorId);
  }

  async function saveCreditSimulator(data, hideNotification) {
    let creditSimulatorId = simulatorIdParam;

    try {
      const formattedData = {
        credit_name: data.credit_simulator_name,
        montant_pret: parseInt(removeSpaces(data.montantPret), 10),
        taux_hors_assurance: parseFloat(data.tauxHorsAssurance.replace(",", ".")),
        taux_assurance: parseFloat(data.tauxAssurance.replace(",", ".")),
        duree: parseInt(data.duree, 10),
        date_depart: data.dateDepart,
      };

      if (simulatorIdParam) {
        await updateCreditSimulatorApi(simulatorIdParam, formattedData);
      } else {
        const res = await createCreditSimulatorApi(formattedData);
        const response = res.data;

        if (response.credit_simulator.credit_id) {
          creditSimulatorId = response.credit_simulator.credit_id;

          navigate(`/simulateur-credit/${creditSimulatorId}`, { replace: true });
        } else throw new Error();
      }

      if (!hideNotification) createNotification(<>La simulation de prêt a été sauvegardée avec succès.</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la création de la simulation de prêt. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      return creditSimulatorId;
    }
  }

  async function downloadCreditSimulation(simulatorId) {
    try {
      setIsDownloadNotificationLoading(true);

      const res = await downloadCreditSimulatorPdfApi(simulatorId);

      const link = createDownloadLink(res.data);
      link.click();
      link.remove();

      createNotification(<>La simulation de prêt a été téléchargée avec succès.</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du téléchargement de la simulation de prêt. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setIsDownloadNotificationLoading(false);
    }
  }

  function showLoginModal(callback) {
    setModalContent({
      title: "Connexion",
      content: <LoginForm onSuccess={callback} isModal={true} />,
    });
    setModalVisible(true);
  }

  function formatDate(dateString) {
    const [day, month, year] = dateString.split(" ")[0].split("/");
    return `${year}-${month}-${day}`;
  }

  function resetCreditSimulatorForm() {
    reset({
      credit_simulator_name: "",
      montantPret: "",
      tauxHorsAssurance: "",
      tauxAssurance: "",
      duree: "",
      dateDepart: "",
    });
  }

  return (
    <CreditSimulatorContext.Provider value={{ simulatorIdParam, fetchCreditSimulator }}>
      <form onSubmit={handleSubmit(onSubmit)} className='credit-simulator-form'>
        <fieldset className='borderless-fieldset full-page-form'>
          <Input
            useForm={inputForm}
            label='Intitulé de la simulation de prêt'
            bgColor='var(--pale-blue)'
            name='credit_simulator_name'
            error={errors.credit_simulator_name?.message}
          />
          <NumberInput
            useForm={inputForm}
            icon='euro'
            name='montantPret'
            label='Montant du prêt *'
            error={errors.montantPret?.message}
            validation={decimalValidation}
          />
          <div className='row-1000'>
            <NumberInput
              useForm={inputForm}
              icon='percent'
              name='tauxHorsAssurance'
              label='Taux hors assurance *'
              error={errors.tauxHorsAssurance?.message}
              validation={decimalValidation}
            />
            <NumberInput
              useForm={inputForm}
              icon='percent'
              name='tauxAssurance'
              label='Taux assurance *'
              error={errors.tauxAssurance?.message}
              validation={decimalValidation}
            />
          </div>
          <div className='row-1000'>
            <NumberInput useForm={inputForm} name='duree' label='Durée (années) *' error={errors.duree?.message} />
            <Input
              type='date'
              useForm={inputForm}
              name='dateDepart'
              label='Date de départ du prêt *'
              error={errors.dateDepart?.message}
            />
          </div>
          <div className='form-legend'>* Obligatoire</div>
          <div className='credit-simulator-form-buttons'>
            <UnfilledButton type='submit' padding='10px 25px'>
              Enregistrer
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => handleSaveCreditSimulator(true)}
              isLoading={isDownloadNotificationLoading}>
              Télécharger
            </FilledButton>
          </div>
          <div className='reset-credit-simulator-form' onClick={resetCreditSimulatorForm}>
            <p>Réinitialiser le formulaire</p>
          </div>
        </fieldset>
      </form>
    </CreditSimulatorContext.Provider>
  );
}

export default CreditSimulatorForm;
