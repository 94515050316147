import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import NumberInput from "../../../../molecules/formComponents/numberInput/NumberInput.js";
import { ParcoursContext } from "../../../../../context/ParcoursContext.js";
import { formatObjectForPosting, removeSpaces } from "../../../../../utils/Utils.js";
import Tuto from "../../../../atoms/icons/general/tuto/Tuto.js";
import AppContext from "../../../../../context/AppContext.js";

function ImpotsTaxes({ id, index, secondaryParcours }) {
  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const inputForm = { watch, setValue, register, unregister };
  const {
    handleTabs,
    moveToNextStep,
    step,
    getStepToGo,
    setGoToStep,
    setStep,
    submitFormsFdc,
    postValues,
    resetFormsRef,
    estimationValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);
  const { showTooltip } = useContext(AppContext);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
    resetFormsRef.current[index] = () => reset();
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(["impots_taxes", "remunerations"], setValue, estimationValues);
  }, [estimationValues]);

  async function formSubmit(values) {
    await postValues(formatObjectForPosting(values));

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
      <p className='text-sky-blue mb-3 text-xs'>Veuillez renseigner tous les champs (même si la somme est "0")</p>
      <fieldset className='borderless-fieldset'>
        <h2>impôts et taxes</h2>
        <div className='row-1000'>
          <NumberInput
            useForm={inputForm}
            label={
              <>
                total impots et taxes (hors impot société)
                <Tuto onClick={() => showTooltip("step_5_total_impots.webp")} />
              </>
            }
            name='impots_taxes.total_impots'
            error={errors?.impots_taxes?.total_impots?.message}
            icon='euro'
          />
          <NumberInput
            useForm={inputForm}
            label={
              <>
                csg crds exploitant (s'il y a lieu)
                <Tuto onClick={() => showTooltip("step_5_csg_crds_exploitant.webp")} />
              </>
            }
            name='impots_taxes.csg_crds_exploitant'
            error={errors?.impots_taxes?.csg_crds_exploitant?.message}
            icon='euro'
          />
        </div>
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2>rémunérations</h2>
        <div className='row-1000'>
          <NumberInput
            useForm={inputForm}
            label={
              <>
                total des rémunérations annuelles (salariés + dirigeants)
                <Tuto onClick={() => showTooltip("step_5_salaires.webp")} />
              </>
            }
            name='remunerations.salaires'
            error={errors?.remunerations?.salaires?.message}
            icon='euro'
          />
          <NumberInput
            useForm={inputForm}
            label={
              <>
                total des charges sociales annuelles (salariés + dirigeants)
                <Tuto onClick={() => showTooltip("step_5_charges_sociales.webp")} />
              </>
            }
            name='remunerations.charges_sociales'
            error={errors?.remunerations?.charges_sociales?.message}
            type='nmber'
            icon='euro'
            {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
          />
        </div>
      </fieldset>
    </form>
  );
}

export default ImpotsTaxes;
