import "./CardWithHeaders.css";
import React from "react";

function CardWithHeaders({
  borderColor = "var(--dark-blue)",
  bgColor = "var(--white)",
  header,
  footer,
  children,
  paddingFooter = "20px",
  className,
}) {
  return (
    <section
      className={"card-with-headers " + className}
      style={{ "--border-color": borderColor, "--padding": paddingFooter, "--background-color": bgColor }}>
      <div className='card-header'>{header}</div>
      <div className='card-content'>{children}</div>
      <div className='card-footer'>{footer}</div>
    </section>
  );
}

export default CardWithHeaders;
