import { createContext, useContext } from "react";

const ArticlesEditorContext = createContext();

export function useArticlesEditorContext() {
  return useContext(ArticlesEditorContext);
}

export function ArticlesEditorContextProvider({ children }) {
  const inlineStyle = `* {
        font-size: 16px;
        margin: 0;
    }
    body {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        max-width: 85ch !important;
        margin: auto;
    }
    .myBold {
        font-weight: bold;
    }
    .linkLeg {
        color: #647fdf;
        transition: .4s;
        text-decoration: none;
    }
    .linkLeg:hover {
        color: #252b41;
    }
    h2, h3 {
        font-weight: 600;
        font-variant: small-caps;
        text-transform: uppercase;
        color: #657fdf;
    }
    h2 {
        font-size: 2.2rem;
        line-height: 2.4rem;
    }
    h3 {
        font-size: 1.3rem;
        line-height: 1.5rem
    }
    h4 {
        font-size: 1.1rem;
        font-weight: bold;
        color: #657fdf;
    }
    h4 {
        font-size: 1.1rem;
        color: #657fdf;
    }
    ul > li {
        list-style-type: none;
    }
    ul > li::before {
        content: "-";
        color: #c8b478;
        font-size: 1rem;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
    }
    .hrArticle {
        width: 30% !important;
        height: 4px !important;
        opacity: 1 !important;
        border: none;
        color: #c8b478;
        background-color: #c8b478;
        margin: 0;
    }
    .gold-bordered-btn {
        color: #c8b478;
        font-weight: 600;
        padding: 15px;
        border: 3px solid #c8b478;
        border-radius: 10px;
        font-size: 1.2rem;
        background-color: white;
        transition: color .3s, background-color .3s;
        cursor: pointer;
    }
    .gold-bordered-btn:hover {
        color: white;
        background-color: #c8b478;
    }
    .mb-5 {
        margin-bottom: 3rem !important;
    }
    .mt-5 {
        margin-top: 3rem !important;
    }
    .maxHeightAuto {
        height: 100% !important;
        max-height: 100% !important;
        margin: 0 auto;
        border-radius: 6px;
        width: auto !important;
        max-width: 600px !important;
        display: block;
    }
    .maxHeightIconAuto {
        max-width: 100%;
        max-height: 250px;
        margin: 0 auto;
        border-radius: 6px;
        display: block;
    }
    .maxWidthAuto {
        margin: 0 auto;
        border-radius: 6px;
        max-width: 100% !important;
        max-height: 100% !important;
        display: block;
    }
    #leadMagnet::before {
        content: "Lead Magnet ";
        color: red;
        font-style: italic;
    }
    `;

  const smallCTA = `<button class="gold-bordered-btn d-flex m-auto" onclick="window.open('/debut-parcours', '_blank')">Evaluez en ligne l'entreprise que vous voulez acheter</button>`;
  const smallCTAPlaceholder = `<button class=\"gold-bordered-btn d-flex m-auto\">Evaluez en ligne l'entreprise que vous voulez acheter</button>`;
  const bigCTAPlaceholder = `<button class=\"gold-bordered-btn d-flex m-auto\" contenteditable="false">***** CTA PRINCIPAL *****</button>`;
  const bigCTA = `<div class="container mt-5 mb-5">
    <div class="row justify-content-center">
        <div class="col">
            <div class="card-article">
                <div class="row g-0 card-article-row justify-content-center">
                    <div class="col-12 col-lg-auto px-0 text-center text-lg-start">
                        <p class="card-article-title">Découvrez<br>notre module<br>d’estimation<br>en ligne</p>
                    </div>
                    <div class="col-auto d-none d-lg-block px-0">
                        <img src="../assets/img/DocInvitEst.svg" role="presentation" alt="doc invite" class="card-title-icon">
                    </div>
                    
                    <div class="col-auto px-0">
    
                        <div class="card-article-btn mt-4 mt-lg-0">
                            <div class="row justify-content-center">
                                <div class="col-auto cursorPointer">
                                    <a class="text-white" onclick="document.getElementById('formType1').submit();">Fonds de commerce</a>
                                </div>
                                <div class="col d-none d-lg-block"></div>
                            </div>
    
                            <div class="row justify-content-center mt-4">
                                <div class="col d-none d-lg-block"></div>
                                <div class="col-auto cursorPointer">
                                    <a class="text-white" onclick="document.getElementById('formType2').submit();">Titres de société</a>
                                </div>
                                <div class="col d-none d-lg-block"></div>
                            </div>
    
                            <div class="row justify-content-center mt-4">
                                <div class="col d-none d-lg-block"></div>
                                <div class="col-auto cursorPointer">
                                    <a class="text-white" onclick="document.getElementById('formType3').submit();">Murs commerciaux</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="white-arrow d-none d-lg-block">
                    <img src="../assets/img/white-arrow.svg" role="presentation" alt="icon arrow" class="w-100">
                </div>
    
            </div>
        </div>
    </div>
</div>`;

  return (
    <ArticlesEditorContext.Provider value={{ inlineStyle, smallCTAPlaceholder, smallCTA, bigCTAPlaceholder, bigCTA }}>
      {children}
    </ArticlesEditorContext.Provider>
  );
}
