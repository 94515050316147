import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef, useState } from "react";
import { useArticlesEditorContext } from "../../../context/ArticlesEditorContext";
import { getImageInfos } from "../../../utils/Utils";
import { uploadArticleImageApi } from "../../../api/ArticlesEditorApi";

function TextEditor({ editorValue, setEditorValue, currentArticle, setIsFullscreenOn }) {
  const editorRef = useRef(null);
  const removeLeadMagnetButtonRef = useRef(null);
  const addLeadMagnetButtonRef = useRef(null);
  const { inlineStyle, smallCTA, bigCTAPlaceholder, smallCTAPlaceholder } = useArticlesEditorContext();

  useEffect(() => {
    if (currentArticle.content) {
      setEditorValue(replaceCTAs(currentArticle.content));
    } else {
      setEditorValue("");
    }
  }, [currentArticle]);

  function replaceCTAs(content) {
    if (!content) return "";

    content = content.replaceAll(smallCTA, smallCTAPlaceholder);

    if (content.split("<div").length < 2) return content;

    const firstPart = content.split("<div")[0];
    const lastPart = content.split("</div>").reverse()[0];

    return firstPart + bigCTAPlaceholder + lastPart;
  }

  function addClassOnHr(e) {
    if (e.command === "InsertHorizontalRule") {
      const allHr = editorRef.current.editor.getBody().querySelectorAll("hr");
      for (const element of allHr) {
        if (!element.classList.contains("hrArticle")) element.classList.add("hrArticle");
      }
    }
  }

  function removeLeadMagnet() {
    const allNodes = editorRef.current.editor.getBody().querySelectorAll("*");
    for (const node of allNodes) {
      if (node.id === "leadMagnet") {
        node.removeAttribute("id");
      }
    }
    addLeadMagnetButtonRef.current.setEnabled(true);
    removeLeadMagnetButtonRef.current.setEnabled(false);
  }

  function addLeadMagnet() {
    removeLeadMagnet();
    const selectedNode = editorRef.current.editor.selection.getNode();
    selectedNode.setAttribute("id", "leadMagnet");
    addLeadMagnetButtonRef.current.setEnabled(false);
    removeLeadMagnetButtonRef.current.setEnabled(true);
  }

  function handleNodeChange(e) {
    if (!removeLeadMagnetButtonRef.current) return;

    const element = e.element;
    // handle leadMagnet buttons
    if (element.id === "leadMagnet") {
      removeLeadMagnetButtonRef.current.setEnabled(true);
      addLeadMagnetButtonRef.current.setEnabled(false);
    } else {
      removeLeadMagnetButtonRef.current.setEnabled(false);
      addLeadMagnetButtonRef.current.setEnabled(true);
    }
    //handle links
    if (element.nodeName === "A") {
      element.classList.add("linkLeg");
      element.setAttribute("target", "_blank");
    }
    //handle hr
    const children = editorRef.current.editor.getBody().children;
    for (let i = 0; i < children.length; i++) {
      if (children[i].nodeName === "HR") children[i].remove();
    }

    const firstH2 = editorRef.current.editor.getBody().querySelector("h2");
    const hr = document.createElement("hr");
    if (!firstH2) return;

    hr.classList.add("hrArticle");
    editorRef.current.editor.getBody().insertBefore(hr, firstH2);
  }

  async function handleImageUpload(callback) {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    input.addEventListener("change", async e => {
      const imageInfos = await getImageInfos(e);

      //upload image to server
      const res = (
        await uploadArticleImageApi(document.getElementById("article_id").value, document.getElementById("url").value, [
          imageInfos,
        ])
      ).data;

      callback(res.images[0], { title: res.images[0] });
    });

    input.click();
  }

  function handleEditorChange(content) {
    setEditorValue(content);
  }

  function handleFullscreenToggle(e) {
    setIsFullscreenOn(e.state);
  }

  return (
    <>
      <input id='article_id' type='hidden' value={currentArticle.id ? currentArticle.id : ""} />
      <input id='url' type='hidden' value={currentArticle.url ? currentArticle.url : ""} />
      <Editor
        ref={editorRef}
        value={editorValue}
        onEditorChange={handleEditorChange}
        onNodeChange={handleNodeChange}
        onExecCommand={addClassOnHr}
        apiKey={process.env.REACT_APP_TINY_API_KEY}
        init={{
          language: "fr_FR",
          height: 800,
          menubar: "edit format",
          menu: {
            edit: { title: "Modifier", items: "undo redo | cut copy paste pastetext | selectall" },
            format: {
              title: "Formater",
              items: "bold italic | bullist numlist listprops | blocks | code | removeformat",
            },
          },
          plugins: ["advlist", "lists", "code", "link", "fullscreen", "image", "wordcount"],
          toolbar: [
            "undo redo | blocks | wordcount | bold italic link unlink image  | bullist numlist listprops | removeformat | fullscreen ",
            "smallCTA bigCTA leadMagnetAdd leadMagnetRemove",
          ],
          contextmenu: "link image imagetools lists table",
          formats: {
            bold: {
              inline: "span",
              classes: "myBold",
            },
          },
          setup: editor => {
            editor.ui.registry.addButton("smallCTA", {
              text: "Petit CTA",
              tooltip: "Ajouter un petit CTA",
              onAction: () => editor.insertContent(smallCTAPlaceholder),
            });
            editor.ui.registry.addButton("bigCTA", {
              text: "Gros CTA",
              tooltip: "Ajouter un gros CTA",
              onAction: () => editor.insertContent(bigCTAPlaceholder),
            });
            editor.ui.registry.addButton("leadMagnetAdd", {
              icon: "link",
              text: "Lead magnet",
              tooltip: "Ajouter le lead magnet",
              onAction: addLeadMagnet,
              onSetup: buttonApi => (addLeadMagnetButtonRef.current = buttonApi),
            });
            editor.ui.registry.addButton("leadMagnetRemove", {
              icon: "unlink",
              text: "Lead magnet",
              tooltip: "Supprimer le lead magnet",
              onAction: removeLeadMagnet,
              onSetup: buttonApi => (removeLeadMagnetButtonRef.current = buttonApi),
            });
            editor.on("FullscreenStateChanged", handleFullscreenToggle);
          },
          // links
          link_title: false,
          allow_unsafe_link_target: true,
          relative_urls: true,
          document_base_url: "https://estimermoncommerce.fr",
          link_target_list: false,
          link_rel_list: [
            { title: "Lien interne (https://estimermoncommerce.fr/...)", value: "" },
            { title: "Lien externe (autre)", value: "nofollow" },
          ],
          // text fomatting
          block_formats: "paragraphe=p; Titre 2=h2; Titre 3=h3; Titre 4=h4; Titre 5=h5;",
          advlist_bullet_styles: "disc",
          advlist_number_styles: "default",
          // css
          content_style: inlineStyle,
          // images
          file_picker_types: "image",
          image_dimensions: false,
          image_class_list: [
            { title: "Petite image", value: "mt-5 mb-5 maxHeightIconAuto" },
            { title: "Hauteur max", value: "mt-5 mb-5 maxHeightAuto" },
            { title: "Largeur max", value: "mt-5 mb-5 maxWidthAuto" },
          ],
          file_picker_callback: handleImageUpload,
        }}
      />
    </>
  );
}

export default TextEditor;
