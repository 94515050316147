import { useForm } from "react-hook-form";
import VisitingCard from "../../../organisms/visitingCard/VisitingCard";
import "./VisitingCardForm.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import Input from "../../../molecules/formComponents/input/Input";
import Textarea from "../../../molecules/formComponents/textarea/Textarea";
import FileInput from "../../../molecules/formComponents/fileInput/FileInput";
import User from "../../../atoms/icons/general/user/User";
import { formatNumberWithSpaces, isStickyElementPinned } from "../../../../utils/Utils";
import { useWindowScroll } from "@uidotdev/usehooks";
import { deleteVisitingCardApi, updateVisitingCardApi } from "../../../../api/VisitingCardApi";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import UnfilledButton from "../../../molecules/buttons/unfilledButton/UnfilledButton";
import AppContext from "../../../../context/AppContext";
import Add from "../../../atoms/icons/general/add/Add";

function VisitingCardForm({ visitingCardData, navbarRef, id, refreshData, updateCardName, onClose }) {
  const { register, setValue, getValues, unregister } = useForm();
  const inputForm = { register, setValue, unregister };

  const [visitingCard, setVisitingCard] = useState(visitingCardData);
  const [windowScroll] = useWindowScroll();
  const cardContainerRef = useRef(null);
  const { setModalVisible, setModalContent, createNotification } = useContext(AppContext);

  useEffect(() => {
    setVisitingCard({
      ...visitingCard,
    });

    Object.keys(getValues()).forEach(key => {
      if (key !== "images") {
        setValue(key, visitingCardData[getInputNameWithoutId(key)]);
      }
    });

    if (navbarRef.current) {
      navbarRef.current.style.maxHeight = "100px";
      navbarRef.current.style.transition = "max-height 0.3s";
    }

    return () => {
      if (navbarRef.current) {
        navbarRef.current.style.maxHeight = "";
        navbarRef.current.style.transition = "";
        navbarRef.current.style.overflow = "";
      }
    };
  }, [visitingCardData]);

  useEffect(() => {
    hideNavbarIfCardSticky();
  }, [windowScroll]);

  async function onImageChange(compressedFile, input) {
    try {
      const response = (
        await updateVisitingCardApi(visitingCard.id, {
          ...visitingCard,
          [input + "_path"]: null,
          images: { ...visitingCard.images, [input]: compressedFile },
        })
      ).data;
      setVisitingCard(response);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la mise à jour de votre carte de visite</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function deleteImage(input) {
    setModalVisible(false);
    try {
      const response = (
        await updateVisitingCardApi(visitingCard.id, {
          [input + "_path"]: "none",
        })
      ).data;

      setVisitingCard({ ...response });
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression de votre image</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function showDeleteImageModal(input) {
    setModalContent({
      title: "Supprimer l'image",
      content: (
        <>
          <p>Voulez-vous supprimer votre {input} ? </p>
          <div className='modal-buttons-row mt-md'>
            <UnfilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                setModalContent({ title: "", content: <></> });
              }}>
              Annuler
            </UnfilledButton>
            <FilledButton padding='10px 25px' onClick={() => deleteImage(input)}>
              Valider
            </FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  async function setVisitingCardFormInput(input, event) {
    try {
      const response = (await updateVisitingCardApi(visitingCard.id, { [input]: event.target.value })).data;
      setVisitingCard(response);

      if (input === "card_name") refreshData(visitingCard.id);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la mise à jour de votre carte de visite</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function getInputNameWithoutId(inputName) {
    return inputName.split("-")[0];
  }

  function hideNavbarIfCardSticky() {
    if (document.getElementById("visiting-cards-expendable-group").value == id) {
      if (isStickyElementPinned(cardContainerRef, 80)) {
        if (navbarRef.current) {
          navbarRef.current.style.maxHeight = "0";
          navbarRef.current.style.overflow = "hidden";
        }
      } else {
        if (navbarRef.current) {
          navbarRef.current.style.maxHeight = "100px";
          navbarRef.current.style.overflow = "";
        }
      }
    }
  }

  async function deleteVisitingCard(visitingCardId) {
    try {
      setModalVisible(false);
      await deleteVisitingCardApi(visitingCardId);
      refreshData();
      document.getElementsByClassName("card")[0].scrollIntoView();
      createNotification(<>Votre carte de visite a été supprimée avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression de votre carte de visite</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function showDeleteVisitingCardModal(visitingCard) {
    setModalContent({
      title: "Supprimer la carte de visite",
      content: (
        <>
          <p>Voulez-vous supprimer la carte de visite "{visitingCard.card_name}"&nbsp;?</p>
          <div className='modal-buttons-row'>
            <UnfilledButton onClick={() => setModalVisible(false)}>Annuler</UnfilledButton>
            <FilledButton onClick={() => deleteVisitingCard(visitingCard.id)}>Confirmer</FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  function handleCardNameChange(event) {
    const newValue = event.target.value;
    setVisitingCard(prevCard => ({ ...prevCard, card_name: newValue }));
    updateCardName(visitingCard.id, newValue);
  }

  async function handleCardNameBlur(event) {
    try {
      const newValue = event.target.value;
      const response = (await updateVisitingCardApi(visitingCard.id, { card_name: newValue })).data;
      setVisitingCard(response);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la mise à jour de votre carte de visite</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function formatSiret(e) {
    setValue(
      e.target.name,
      e.target.value
        .replaceAll(" ", "")
        .match(/.{1,3}/g)
        ?.join(" "),
    );
  }

  return (
    <div>
      <form>
        <Input
          name={`card_name-${id}`}
          useForm={inputForm}
          label='Nom de la carte de visite'
          value={visitingCard.card_name}
          onChange={handleCardNameChange}
          onBlur={handleCardNameBlur}
        />
        {visitingCard.card_type === "imported_card" ? (
          <>
            <div className='imported-card-container'>
              <img className='imported-visiting-card' alt='carte visite importée' src={visitingCard.card_path} />
              <Textarea
                label='mentions légales'
                onBlur={e => setVisitingCardFormInput("mentions", e)}
                useForm={inputForm}
                name={`mentions-${id}`}
              />
            </div>
            <div className='flex gap-md f-center'>
              <FilledButton
                bgColor='var(--red)'
                padding='10px 20px'
                onClick={() => showDeleteVisitingCardModal(visitingCard)}>
                Supprimer la carte de visite
              </FilledButton>
              <FilledButton
                padding='10px 20px'
                onClick={() => {
                  createNotification(<>Votre carte de visite a été enregistrée avec succès</>);
                  if (onClose) onClose();
                }}>
                Enregistrer
              </FilledButton>
            </div>
          </>
        ) : (
          <>
            <div className='visiting-card-profile-container' ref={cardContainerRef}>
              <VisitingCard {...visitingCard} />
            </div>
            <div className='row-1000 gap-md'>
              <FileInput
                name={`avatar-${id}`}
                icon={<User width='25px' />}
                label='Avatar'
                onChange={compressedFile => onImageChange(compressedFile, "avatar")}
                onClear={() => showDeleteImageModal("avatar")}
                removeButton={visitingCard?.avatar_path}
              />
              <FileInput
                name={`logo_path-${id}`}
                icon={<Add width='25px' color='var(--white)' />}
                label='Logo'
                onChange={compressedFile => onImageChange(compressedFile, "logo")}
                onClear={() => showDeleteImageModal("logo")}
                removeButton={visitingCard?.logo_path}
              />
            </div>
            <div className='row-1000'>
              <Input
                onBlur={e => setVisitingCardFormInput("first_name", e)}
                name={`first_name-${id}`}
                useForm={inputForm}
                label='Prénom'
              />
              <Input
                onBlur={e => setVisitingCardFormInput("last_name", e)}
                name={`last_name-${id}`}
                useForm={inputForm}
                label='Nom'
              />
            </div>
            <Input
              onBlur={e => setVisitingCardFormInput("position", e)}
              name={`position-${id}`}
              useForm={inputForm}
              label='poste'
            />
            <Input
              onBlur={e => setVisitingCardFormInput("slogan", e)}
              name={`slogan-${id}`}
              useForm={inputForm}
              label='slogan'
            />
            <Input
              onBlur={e => setVisitingCardFormInput("company", e)}
              name={`company-${id}`}
              useForm={inputForm}
              label='société'
            />
            <Input
              onBlur={e => setVisitingCardFormInput("primary_address", e)}
              name={`primary_address-${id}`}
              useForm={inputForm}
              label="ligne d'adresse 1"
            />
            <Input
              onBlur={e => setVisitingCardFormInput("secondary_address", e)}
              name={`secondary_address-${id}`}
              useForm={inputForm}
              label="ligne d'adresse 2"
            />
            <div className='row-1000'>
              <Input
                onBlur={e => setVisitingCardFormInput("zip_code", e)}
                name={`zip_code-${id}`}
                useForm={inputForm}
                label='code postal'
              />
              <Input
                onBlur={e => setVisitingCardFormInput("city", e)}
                name={`city-${id}`}
                useForm={inputForm}
                label='ville'
              />
            </div>
            <hr className='visiting-card-form-hr' />
            <div className='row-1000'>
              <Input
                onBlur={e => setVisitingCardFormInput("email", e)}
                name={`email-${id}`}
                useForm={inputForm}
                label='e-mail'
              />
              <Input
                onBlur={e => setVisitingCardFormInput("website", e)}
                name={`website-${id}`}
                useForm={inputForm}
                label='site web'
              />
            </div>
            <div className='row-1000'>
              <Input
                onBlur={e => setVisitingCardFormInput("mobile_phone", e)}
                name={`mobile_phone-${id}`}
                useForm={inputForm}
                label='téléphone portable'
              />
              <Input
                onBlur={e => setVisitingCardFormInput("fixed_phone", e)}
                name={`fixed_phone-${id}`}
                useForm={inputForm}
                label='téléphone fixe'
              />
            </div>
            <div className='row-1000'>
              <Input
                onBlur={e => setVisitingCardFormInput("rsac", e)}
                name={`rsac-${id}`}
                useForm={inputForm}
                label='rsac'
              />
              <Input
                onBlur={e => setVisitingCardFormInput("siret", e)}
                name={`siret-${id}`}
                onChange={formatSiret}
                useForm={inputForm}
                label='siret'
              />
            </div>
            <hr className='visiting-card-form-hr' />
            <Textarea
              label='mentions légales'
              useForm={inputForm}
              name={`mentions-${id}`}
              onBlur={e => setVisitingCardFormInput("mentions", e)}
            />
            <div className='flex gap-md f-center'>
              <FilledButton
                bgColor='var(--red)'
                padding='10px 20px'
                onClick={() => showDeleteVisitingCardModal(visitingCard)}>
                Supprimer la carte de visite
              </FilledButton>
              <FilledButton
                padding='10px 20px'
                onClick={() => {
                  createNotification(<>Votre carte de visite a été enregistrée avec succès</>);
                  if (onClose) {
                    onClose();
                    navbarRef.current.style.maxHeight = "100px";
                    navbarRef.current.style.overflow = "";
                  }
                }}>
                Enregistrer
              </FilledButton>
            </div>
          </>
        )}
      </form>
    </div>
  );
}

export default VisitingCardForm;
