import "./DoubleInput.css";
import React, { useEffect, useRef } from "react";
import { defaultValidation, numberValidation } from "../../../../utils/formValidation/FormValidation.js";
import "../caseInput/CaseInput.css";
import { formatNumberWithSpaces, removeSpacesFromEvent, toggleCollapseElement } from "../../../../utils/Utils.js";
import Warning from "../../../atoms/icons/general/warning/Warning.js";
import Trash from "../../../atoms/icons/general/trash/Trash.js";

// useForm = {
//   register: () => {},
//   watch: () => {},
//   setValue: () => {},
// }
// icon : euro / percent / search
function DoubleInput({
  name,
  labelName,
  labelPlaceholder,
  defaultValue,
  validation = numberValidation,
  icon = "euro",
  onChange = () => {},
  onLabelChange = () => {},
  zeroByDefault = true,
  onFocus = () => {},
  disabled,
  onKeyDown = () => {},
  onDelete,
  useForm,
  value,
  error,
  className = "",
}) {
  const { register = () => {}, setValue = () => {}, watch = () => {} } = useForm;
  const labelInputRegister = register ? register(labelName, defaultValidation) : null;
  const inputRegister = register ? register(name, validation) : null;
  const containerRef = useRef(null);
  const listener = watch ? watch(name) : null;

  useEffect(() => {
    if (listener) setValue(name, formatNumberWithSpaces(listener));
  }, [listener]);

  function setZero(e) {
    if (e.target.value === "" && setValue && zeroByDefault) setValue(name, 0);

    if (zeroByDefault && (e.target.value === "" || e.target.value === "-")) {
      setValue(name, 0);
      setTimeout(() => {
        setValue(name, formatNumberWithSpaces(e.target.value));
        onChange(removeSpacesFromEvent(e));
      }, 100);
    }
  }

  function resetValue(e) {
    if (e.target.value === "0" && setValue) setValue(name, "");
  }

  function handleChange(e) {
    const cursorPosition = e.target.value.length - e.target.selectionStart;

    setValue(name, formatNumberWithSpaces(e.target.value));
    onChange(removeSpacesFromEvent(e));
    inputRegister && inputRegister.onChange(e);

    setTimeout(() => {
      e.target.setSelectionRange(e.target.value.length - cursorPosition, e.target.value.length - cursorPosition);
    }, 0);
  }

  function handleKeyDown(e) {
    // fix backspace when deleting white space between numbers
    const prevChar = e.target.value.at(e.target.selectionStart - 1);

    if (prevChar === " " && e.key === "Backspace") {
      e.preventDefault();
      const cursorPosition = e.target.value.length - e.target.selectionStart;

      setValue &&
        setValue(
          name,
          formatNumberWithSpaces(
            removeSpaces(
              e.target.value.slice(0, e.target.selectionStart - 2, 2) + e.target.value.slice(e.target.selectionStart),
            ),
          ),
        );

      setTimeout(() => {
        e.target.setSelectionRange(e.target.value.length - cursorPosition, e.target.value.length - cursorPosition);
      }, 0);
    }

    onKeyDown(e);
  }

  return (
    <>
      <div className={`w-100 d-flex ${className}`} ref={containerRef}>
        <div className={`case-input-container double-input-container ${error ? "with-error" : ""}`}>
          <input
            maxLength={250}
            className='first-input'
            disabled={disabled}
            id={labelName}
            {...labelInputRegister}
            placeholder={labelPlaceholder}
            onChange={e => {
              labelInputRegister && labelInputRegister.onChange(e);
              onLabelChange && onLabelChange(e);
            }}
          />
          {onDelete && (
            <div className='centered'>
              <Trash onClick={onDelete} />
            </div>
          )}

          <span className={"before-" + icon}>
            <input
              disabled={disabled}
              id={name}
              value={value !== undefined ? value : undefined}
              {...inputRegister}
              onChange={handleChange}
              onFocus={e => {
                resetValue(e);
                onFocus && onFocus(e);
              }}
              onKeyDown={handleKeyDown}
              defaultValue={defaultValue}
              onBlur={setZero}
            />
            {error && (
              <div className='form-error'>
                <Warning /> <p>Ce champ est obligatoire</p>
              </div>
            )}
          </span>
        </div>
      </div>
    </>
  );
}

export default DoubleInput;
