import React, { useContext } from "react";
import Input from "../../../molecules/formComponents/input/Input";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import { useForm } from "react-hook-form";
import { defaultValidation, emailValidation, phoneValidation } from "../../../../utils/formValidation/FormValidation";
import Textarea from "../../../molecules/formComponents/textarea/Textarea";
import { contactRequestApi } from "../../../../api/UtilsApi";
import AppContext from "../../../../context/AppContext";
import Checkbox from "../../../molecules/formComponents/checkbox/Checkbox";

function ContactForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { createNotification } = useContext(AppContext);

  async function onSubmit(values) {
    try {
      await contactRequestApi(
        values.first_name,
        values.last_name,
        values.email,
        values.phone,
        values.subject,
        values.content,
      );
      createNotification(<>Votre message a bien été envoyé</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'envoi de votre message</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='row-1000'>
        <Input
          useForm={{ register: register }}
          error={errors?.last_name?.message}
          validation={defaultValidation}
          name='last_name'
          label='Nom *'
        />
        <Input
          useForm={{ register: register }}
          error={errors?.first_name?.message}
          validation={defaultValidation}
          name='first_name'
          label='Prénom *'
        />
      </div>
      <div className='row-1000'>
        <Input
          useForm={{ register: register }}
          validation={emailValidation}
          error={errors?.email?.message}
          name='email'
          label='Adresse mail *'
        />
        <Input
          useForm={{ register: register }}
          validation={phoneValidation}
          error={errors?.phone?.message}
          name='phone'
          label='Téléphone *'
        />
      </div>
      <Input
        useForm={{ register: register }}
        error={errors?.subject?.message}
        validation={defaultValidation}
        name='subject'
        label='Objet *'
      />
      <Textarea
        useForm={{ register: register }}
        error={errors?.message?.message}
        validation={defaultValidation}
        name='content'
        label='Message *'
        className='mb-md'
        defaultValue={`Bonjour,\n\nJe souhaite entrer en contact avec vous. Merci de me recontacter au plus vite.\n\nCordialement,\n`}
      />
      <Checkbox
        useForm={{ register: register }}
        error={errors?.accept_terms?.message}
        validation={defaultValidation}
        name='accept_terms'
        label="En soumettant ce formulaire, j'accepte que mes données personnelles saisies soient utilisées pour traiter ma demande de contact. *"
      />
      <div className='form-legend'>* Obligatoire</div>
      <div className='centered mb-sm'>
        <FilledButton type='submit' padding='10px 25px'>
          Envoyer
        </FilledButton>
      </div>
    </form>
  );
}

export default ContactForm;
