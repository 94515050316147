import React, { useState, useEffect, useContext } from "react";
import "./ImportCreditSimulatorsFolderModal.css";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import AppContext from "../../../../../context/AppContext";
import Checkbox from "../../../../molecules/formComponents/checkbox/Checkbox";
import { addCreditSimulatorToFolderApi } from "../../../../../api/FolderApi";
import { useForm } from "react-hook-form";
import Searchbar from "../../../../molecules/searchbar/Searchbar";
import { fetchAllCreditSimulatorsApi } from "../../../../../api/CreditSimulatorApi";
import { normalizeString } from "../../../../../utils/Utils";

function ImportCreditSimulatorsFolderModal({ folderId, getUuid, onCreditSimulatorAdded = () => {}, folder }) {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [creditSimulators, setCreditSimulators] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedCreditSimulators, setSelectedCreditSimulators] = useState([]);
  const { setModalVisible, createNotification } = useContext(AppContext);

  useEffect(() => {
    async function fetchCreditSimulators() {
      try {
        const res = (await fetchAllCreditSimulatorsApi(getUuid())).data;

        const creditSimulators = [];
        creditSimulators.push(...res);

        setCreditSimulators(creditSimulators.filter(simulator => !simulator.archived));
      } catch (error) {
        createNotification(
          <>Une erreur est survenue lors de la récupération de vos simulations de prêt, veuillez réessayer.</>,
          "var(--red)",
          "var(--dark-blue)",
        );
      }
    }
    fetchCreditSimulators();
  }, [getUuid]);

  const filteredCreditSimulators = creditSimulators.filter(
    simulator => simulator.credit_name && normalizeString(simulator.credit_name).includes(normalizeString(searchValue)),
  );

  function toggleCreditSimulatorSelection(creditSimulatorId) {
    if (isCreditSimulatorInFolder(creditSimulatorId)) {
      return;
    }
    setSelectedCreditSimulators(prevSelected => {
      if (prevSelected.includes(creditSimulatorId)) {
        return prevSelected.filter(id => id !== creditSimulatorId);
      } else {
        return [...prevSelected, creditSimulatorId];
      }
    });
  }

  function isCreditSimulatorInFolder(creditSimulatorId) {
    return (
      Array.isArray(folder.credit_simulators) &&
      folder.credit_simulators.some(simulator => simulator.credit_id === creditSimulatorId)
    );
  }

  async function handleImportCreditSimulators() {
    try {
      const creditSimulatorsToAdd = selectedCreditSimulators.filter(
        creditSimulatorId => !isCreditSimulatorInFolder(creditSimulatorId),
      );

      if (creditSimulatorsToAdd.length === 0) {
        setModalVisible(false);
        return;
      }

      await Promise.all(
        creditSimulatorsToAdd.map(creditSimulatorId => addCreditSimulatorToFolderApi(folderId, creditSimulatorId)),
      );

      createNotification(<>Les simulations de prêt ont été ajoutés avec succès</>);

      onCreditSimulatorAdded();
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'ajout des simulations de prêt</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setModalVisible(false);
    }
  }

  return (
    <form onSubmit={handleSubmit(handleImportCreditSimulators)}>
      <div className='import-simulateur-checkbox-header'>
        <h3>Liste des simulations de prêt (terminés)</h3>
        <p>Sélectionner les simulations de prêt à importer dans votre dossier.</p>
      </div>
      <Searchbar
        onChange={value => setSearchValue(value)}
        placeholder='Rechercher une simulation de prêt...'
        bgColor='var(--white)'
        className='import-simulateur-searchbar'
      />
      <div className='import-simulateur-list-container'>
        {filteredCreditSimulators.length ? (
          filteredCreditSimulators.map(simulator => {
            const isInFolder = isCreditSimulatorInFolder(simulator.credit_id);
            return (
              <Checkbox
                key={simulator.credit_id}
                label={simulator.credit_name}
                name={`creditSimulator-${simulator.credit_id}`}
                onChange={() => toggleCreditSimulatorSelection(simulator.credit_id)}
                className='import-simulateur-checkbox'
                useForm={{ setValue }}
                errors={errors}
                checked={selectedCreditSimulators.includes(simulator.credit_id)}
                disabled={isInFolder}
              />
            );
          })
        ) : (
          <div className='import-simulateur-no-simulateur'>
            <p>Aucune simulation de prêt trouvé</p>
          </div>
        )}
      </div>
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' type='submit'>
          Importer
        </FilledButton>
      </div>
    </form>
  );
}

export default ImportCreditSimulatorsFolderModal;
