import React, { useState, useRef, useEffect } from "react";
import "./FileInputPreview.css";
import Warning from "../../../atoms/icons/general/warning/Warning";
import Trash from "../../../atoms/icons/general/trash/Trash";
import Pen from "../../../atoms/icons/account/pen/Pen";
import { checkIfSvgIsValid, checkImage, getCompressedImage, toggleCollapseInput } from "../../../../utils/Utils";
import FilledButton from "../../buttons/filledButton/FilledButton";

function FileInputPreview({
  label,
  name,
  previewDefaultValue,
  register,
  setValue = () => {}, // useForm setValue function
  bgColor = "var(--blue)",
  color = "var(--white)",
  hoverColor = "var(--blue)",
  borderRadius = "10px",
  padding = "5px 20px",
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onClear = () => {},
  disabled,
  icon,
  visible = true,
  accept = ".png, .jpg, .jpeg, .svg",
  buttonText = "Importer une image",
  className = "",
  fileMaxSize = 10,
}) {
  const [imgErrorMessage, setImgErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState(previewDefaultValue);
  const inputRef = useRef(null);
  const inputContainerRef = useRef(null);

  const inputRegister = register && (visible || visible === undefined) ? register(name) : null;

  useEffect(() => {
    if (visible !== undefined) toggleCollapseInput(inputRef.current, visible);
  }, [visible]);

  useEffect(() => {
    setPreview(previewDefaultValue);
  }, [previewDefaultValue]);

  async function handleChange(e) {
    let file = e.target.files[0];
    const errorMsg = checkImage(file, fileMaxSize);
    // check if SVG is valid
    if (file.type === "image/svg+xml") {
      const updatedSvgContent = await checkIfSvgIsValid(file);

      if (updatedSvgContent) {
        const updatedFile = new Blob([updatedSvgContent], { type: "image/svg+xml" });

        file = new File([updatedFile], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });
      }
    }

    if (errorMsg) {
      setIsLoading();
      return setImgErrorMessage(errorMsg);
    }

    try {
      const compressedFile = await getCompressedImage(file);

      setValue(name, JSON.stringify(compressedFile));
      setPreview(compressedFile.data);
      setImgErrorMessage("");

      onChange(compressedFile);
    } catch (error) {
      setImgErrorMessage("Erreur lors de la lecture de l'image");
    } finally {
      setIsLoading(false);
    }
  }

  function replaceImage() {
    triggerInput();
  }

  function deletePreview(e) {
    setPreview();
    setValue(name, "");
    setImgErrorMessage("");
    onClear(e, name);
  }

  function triggerInput() {
    setIsLoading(true);
    inputContainerRef.current.children[1].children[1].click();
  }

  return (
    <div
      className={`file-input-preview-container ${className}`}
      ref={inputRef}
      style={{
        "--padding": padding,
        "--background-color": bgColor,
        "--color": color,
        "--hover-color": hoverColor,
        "--border-radius": borderRadius,
        "display": visible ? "block" : "none",
      }}>
      {label && <label className='form-label'>{label}</label>}
      <div className='file-input-preview-content' ref={inputContainerRef}>
        <div className={`file-input-preview ${preview ? "" : "d-none"}`}>
          <img src={preview} alt={`preview-${name}`} />
          <button type='button' className='file-input-preview-edit' onClick={replaceImage}>
            <Pen color='white' width='18px' />
          </button>
          <button type='button' className='file-input-preview-remove' onClick={deletePreview}>
            <Trash color='white' width='20px' />
          </button>
        </div>
        <div className={preview ? "d-none" : ""}>
          <label htmlFor={name + "_file_input"}>
            <FilledButton isLoading={isLoading} onClick={triggerInput} className='file-input-preview-label'>
              {icon}
              {buttonText}
            </FilledButton>
          </label>
          <input
            id={name + "_file_input"}
            type='file'
            className='file-input-img'
            onChange={handleChange}
            disabled={(visible !== undefined && !visible) || disabled}
            tabIndex={visible ? 0 : -1}
            onFocus={onFocus}
            onBlur={onBlur}
            accept={accept}
          />
          <input type='hidden' id={name} {...inputRegister} />
        </div>
      </div>
      {imgErrorMessage && (
        <div className='form-error'>
          <Warning /> <p>{imgErrorMessage}</p>
        </div>
      )}
    </div>
  );
}

export default FileInputPreview;

export function getFileInputValue(value) {
  return value ? JSON.parse(value) : null;
}
