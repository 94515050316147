import { updateVisitingCardApi } from "../../../api/VisitingCardApi";
import { toggleCollapseElement } from "../../../utils/Utils";
import Bracket from "../../atoms/icons/general/bracket/Bracket";
import "./ExpendableGroup.css";
import React, { useEffect, useRef, useState } from "react";

// data =  [
//     {
//         title: "",
//         content: ""
//     },
// ]

function ExpendableGroup({ data, className = "", id = "expendable-group", refreshData }) {
  const [openedElement, setOpenedElement] = useState(-1);
  const parentRef = useRef(null);
  const containerRefs = useRef([]);

  useEffect(() => {
    for (let i = 0; i < parentRef.current.children.length; i++) {
      const child = parentRef.current.children[i];
      if (child.classList.contains("active")) {
        toggleCollapseElement(child.children[1], true, 60);
      } else {
        toggleCollapseElement(child.children[1], false, 60);
      }
    }
  }, [openedElement]);

  useEffect(() => {
    for (const index in data)
      if (data[index].is_opened) {
        changeOpenedElement(Number(index));
        setTimeout(() => {
          window.scrollTo(0, containerRefs.current[index].offsetTop - 75);
        }, 200);
      }
  }, [data]);

  function changeOpenedElement(index) {
    setOpenedElement(value => (value === index ? -1 : index));
  }

  function closeCurrentElement(key) {
    setOpenedElement(-1);

    if (containerRefs.current[key]) {
      const offsetTop = containerRefs.current[key].getBoundingClientRect().top + window.scrollY;
      const offset = 100;
      window.scrollTo({
        top: offsetTop - offset,
        behavior: "smooth",
      });
    }
  }

  async function handleDefaultVisitingCard(e) {
    e.preventDefault();
    e.stopPropagation();

    try {
      await updateVisitingCardApi(e.target.getAttribute("card-id"), { is_default: e.target.checked ? 1 : 0 });

      const checkboxes = document.getElementsByClassName("expendable-hidden-checkbox");
      for (const checkbox of checkboxes) {
        if (!checkbox.isSameNode(e.target) && checkbox.checked)
          await updateVisitingCardApi(checkbox.getAttribute("card-id"), { is_default: 0 });
      }

      refreshData();
    } catch (error) {}
  }

  return (
    <>
      <input type='hidden' id={id} value={openedElement} />
      <section className={"expendable-group-container " + className} ref={parentRef}>
        {data.map((element, key) => {
          return (
            <div
              className={"expendable-container " + (openedElement === key ? "active" : "")}
              key={key}
              ref={el => (containerRefs.current[key] = el)}>
              <div className='expendable-top' onClick={() => changeOpenedElement(key)}>
                <div className='expendable-title'>{element.title}</div>
                {refreshData && (
                  <div className='expendable-checkbox-container'>
                    <label htmlFor={`default_checkbox_${element.id}`}>Par défaut</label>
                    <input
                      type='checkbox'
                      className='expendable-hidden-checkbox'
                      onChange={handleDefaultVisitingCard}
                      card-id={element.id}
                      checked={element.is_default}
                      id={`default_checkbox_${element.id}`}
                    />
                    <label
                      htmlFor={`default_checkbox_${element.id}`}
                      className={"expendable-checkbox " + (element.is_default ? "expendable-checked" : "")}></label>
                  </div>
                )}
                <div className='expendable-arrow'>
                  <Bracket width='20px' rotation={openedElement === key ? "0" : "180deg"} />
                </div>
              </div>
              <div className='expendable-content'>
                {React.cloneElement(element.content, { onClose: () => closeCurrentElement(key) })}
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
}

export default ExpendableGroup;
