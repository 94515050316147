import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../../../../context/AppContext";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import Checkbox from "../../../../molecules/formComponents/checkbox/Checkbox";
import { addCreditSimulatorToDeliverableApi, fetchDeliverableByIdApi } from "../../../../../api/FolderApi";

function AddCreditSimulatorToDeliverableModal({ folder, deliverableId, onCreditSimulatorsAdded = () => {} }) {
  const { setModalVisible, createNotification } = useContext(AppContext);
  const [selectedCreditSimulators, setSelectedCreditSimulators] = useState([]);
  const [folderCreditSimulators, setFolderCreditSimulators] = useState([]);
  const [existingCreditSimulatorIds, setExistingCreditSimulatorIds] = useState([]);
  const [displayedCreditSimulators, setDisplayedCreditSimulators] = useState([]);

  useEffect(() => {
    if (folder?.credit_simulators) setFolderCreditSimulators(folder.credit_simulators);
    else setFolderCreditSimulators([]);
  }, [folder]);

  useEffect(() => {
    if (deliverableId) fetchExistingSimulators();
  }, [deliverableId]);

  useEffect(() => {
    setDisplayedCreditSimulators(
      folderCreditSimulators.filter(simulator => !existingCreditSimulatorIds.includes(simulator.credit_id)),
    );
  }, [existingCreditSimulatorIds, folderCreditSimulators]);

  async function fetchExistingSimulators() {
    try {
      const res = await fetchDeliverableByIdApi(deliverableId);

      setExistingCreditSimulatorIds(
        res.data.deliverable.credit_simulators.map(simulator => simulator.credit_simulator.credit_id),
      );
    } catch (error) {
      createNotification(<>Une erreur est survenue lors de la récupération des simulations de prêt</>);
    }
  }

  function toggleCreditSimulatorSelection(creditSimulator) {
    if (selectedCreditSimulators.some(e => e.credit_id === creditSimulator.credit_id)) {
      setSelectedCreditSimulators(selectedCreditSimulators.filter(e => e.credit_id !== creditSimulator.credit_id));
    } else {
      setSelectedCreditSimulators([...selectedCreditSimulators, creditSimulator]);
    }
  }

  async function handleAddCreditSimulators() {
    try {
      for (const creditSimulator of selectedCreditSimulators) {
        await addCreditSimulatorToDeliverableApi(deliverableId, creditSimulator.credit_id);
      }

      onCreditSimulatorsAdded();

      createNotification(<>Simulation(s) de prêt ajouté(s) avec succès au livrable</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'ajout des simulations de prêt au livrable</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setModalVisible(false);
    }
  }

  return (
    <div>
      <div className='info-add-financing-plans'>
        <p>Sélectionnez les simulations de prêt à ajouter au livrable.</p>
      </div>
      <div className='checkbox-list-container'>
        <h3>Simulation(s) de prêt disponibles dans le dossier</h3>
        <div className='import-financingplans-to-deliverable'>
          {displayedCreditSimulators.length > 0 ? (
            displayedCreditSimulators.map(simulator => (
              <Checkbox
                key={simulator.credit_id}
                name={`financingPlan-${simulator.credit_id}`}
                className='checkbox-financingplans-item'
                label={simulator.credit_name}
                onChange={() => toggleCreditSimulatorSelection(simulator)}
                checked={selectedCreditSimulators.some(e => e.credit_id === simulator.credit_id)}
              />
            ))
          ) : (
            <p>Aucune simulation de prêt disponible à ajouter.</p>
          )}
        </div>
      </div>
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton
          padding='10px 25px'
          onClick={handleAddCreditSimulators}
          disabled={selectedCreditSimulators.length === 0}>
          Ajouter au livrable
        </FilledButton>
      </div>
    </div>
  );
}

export default AddCreditSimulatorToDeliverableModal;
