import React, { useContext, useEffect } from "react";
import Input from "../../../../molecules/formComponents/input/Input";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import AppContext from "../../../../../context/AppContext";
import { defaultValidation } from "../../../../../utils/formValidation/FormValidation";
import { useForm } from "react-hook-form";
import { duplicateDeliverableApi } from "../../../../../api/FolderApi";

function DuplicateDeliverableModal({ deliverableId, defaultValue, onDuplicateSuccess = () => {} }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { setModalVisible, createNotification } = useContext(AppContext);

  useEffect(() => {
    setValue("deliverable_name", defaultValue);
  }, [defaultValue]);

  async function duplicateDeliverable(values) {
    try {
      const res = await duplicateDeliverableApi(deliverableId, values.deliverable_name);
      const duplicatedDeliverable = res.data;

      onDuplicateSuccess(duplicatedDeliverable);
      createNotification(<>Le livrable a été dupliqué avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la duplication du livrable. Veuillez réessayer plus tard</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
    setModalVisible(false);
  }

  return (
    <form onSubmit={handleSubmit(duplicateDeliverable)}>
      <Input
        validation={defaultValidation}
        useForm={{ register, setValue }}
        error={errors?.deliverable_name?.message}
        name='deliverable_name'
        label='Nom du livrable'
      />
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' type='submit'>
          Dupliquer le livrable
        </FilledButton>
      </div>
    </form>
  );
}

export default DuplicateDeliverableModal;
