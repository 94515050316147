import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/icons/account/report_example.svg";

function ReportExample({ width = "30px", className = "", onClick }) {
  return (
    <Icon
      style={{ cursor: onClick ? "pointer" : "auto" }}
      width={width}
      className={className + (onClick ? " clickable-icon" : "")}
      onClick={onClick}
    />
  );
}

export default ReportExample;
