import React, { useRef, useEffect } from "react";
import "./Chart.css";
import Chart from "chart.js/auto";
import { formatNumberWithSpaces } from "../../../utils/Utils";

function BarChart({ data, onImageReady, verticalText = false }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const { max, min } = calculateChartLegendScale(data, verticalText);

    const options = {
      scales: {
        x: {},
        y: {
          max: max,
          min: min,
          ticks: {
            callback: function (value) {
              return formatNumberWithSpaces(value, true, "€");
            },
          },
        },
      },
      layout: {
        padding: {
          left: 10,
          top: 30,
          bottom: 20,
        },
      },
      animation: {
        duration: 0,
        onComplete: function () {
          const ctx = this.ctx;
          this.config.data.datasets.forEach((dataset, i) => {
            const meta = this.getDatasetMeta(i);
            meta.data.forEach((bar, index) => {
              const data = dataset.data[index];
              ctx.fillStyle = "rgba(29, 38, 98, 1)";
              const text = formatNumberWithSpaces(data, true, "€");
              const barWidth = bar.width;
              const fontSize = Math.max(8.4, Math.min(10, (barWidth / text.length) * 2));
              ctx.font = `${fontSize}px Helvetica`;
              const textWidth = ctx.measureText(text).width;
              const positionX = bar.x - textWidth / 2;
              const positionY = data >= 0 ? bar.y - 6 : bar.y + 18;

              if (verticalText) {
                ctx.save();
                ctx.translate(positionX + textWidth / 2, positionY);
                ctx.rotate(-Math.PI / 2);
                ctx.fillText(text, 0, 0);
                ctx.restore();
              } else {
                ctx.fillText(text, positionX, positionY);
              }
            });
          });

          const imageUrl = canvasRef.current.toDataURL();
          onImageReady(imageUrl);
        },
      },
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            boxWidth: 30,
          },
          display: false,
          position: "bottom",
        },
        tooltip: {
          enabled: false,
        },
      },
    };

    Chart.defaults.font.size = 14;
    const chart = new Chart(canvasRef.current, {
      type: "bar",
      data,
      options,
    });

    return () => {
      chart.destroy();
    };
  }, [data, onImageReady, verticalText]);

  return (
    <div className='bar-chart-container'>
      <canvas ref={canvasRef} style={{ maxWidth: "650px", maxHeight: "380px", display: "none" }}></canvas>
    </div>
  );
}

export default BarChart;

export function calculateChartLegendScale(data) {
  const allData = data.datasets.flatMap(dataset => dataset.data);
  let max = Math.max(...allData);
  let min = Math.min(...allData);

  const range = max - min;
  const magnitude = Math.pow(10, Math.floor(Math.log10(range)));
  let stepSize = Math.ceil(range / 4 / magnitude) * magnitude;

  let finalMax = Math.ceil(max / stepSize) * stepSize;
  let finalMin = Math.floor(min / stepSize) * stepSize;

  if (finalMin >= 0 || min > 0) finalMin = 0;
  if (finalMax <= 0 || max < 0) finalMax = 0;

  return { finalMax, finalMin };
}
