import React, { useState, useEffect, useContext } from "react";
import "./ImportFinancingPlansFolderModal.css";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import AppContext from "../../../../../context/AppContext";
import Checkbox from "../../../../molecules/formComponents/checkbox/Checkbox";
import { addFinancingPlanToFolderApi } from "../../../../../api/FolderApi";
import { useForm } from "react-hook-form";
import Searchbar from "../../../../molecules/searchbar/Searchbar";
import { fetchAllFinancingPlanApi } from "../../../../../api/FinancingPlanApi";

function ImportFinancingPlansFolderModal({ folderId, getUuid, onFinancingPlanAdded = () => {}, folder }) {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [financingPlans, setFinancingPlans] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedFinancingPlans, setSelectedFinancingPlans] = useState([]);
  const { setModalVisible, createNotification } = useContext(AppContext);

  useEffect(() => {
    async function fetchFinancingPlans() {
      const res = (await fetchAllFinancingPlanApi(getUuid())).data;

      const fdcPlans = res["financing_plan_fdc"]
        .map(plan => ({ ...plan, financing_plan_type: "fdc" }))
        .filter(plan => !plan.archived);
      const mursPlans = res["financing_plan_murs"]
        .map(plan => ({ ...plan, financing_plan_type: "murs" }))
        .filter(plan => !plan.archived);

      const allFinancingPlans = [...fdcPlans, ...mursPlans];

      setFinancingPlans(allFinancingPlans);
    }
    fetchFinancingPlans();
  }, [getUuid]);

  const filteredFinancingPlans = financingPlans.filter(
    plan => plan.financing_plan_name && plan.financing_plan_name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  function toggleFinancingPlanSelection(financingPlanId) {
    if (isFinancingPlanInFolder(financingPlanId)) {
      return;
    }
    if (selectedFinancingPlans.includes(financingPlanId)) {
      setSelectedFinancingPlans(selectedFinancingPlans.filter(id => id !== financingPlanId));
    } else {
      setSelectedFinancingPlans([...selectedFinancingPlans, financingPlanId]);
    }
  }

  function isFinancingPlanInFolder(financingPlanId) {
    return folder.financing_plans.some(plan => plan.financing_plan_id === financingPlanId);
  }

  async function handleImportFinancingPlans() {
    try {
      const financingPlansToAdd = selectedFinancingPlans.filter(
        financingPlanId => !isFinancingPlanInFolder(financingPlanId),
      );

      if (financingPlansToAdd.length === 0) return setModalVisible(false);

      await Promise.all(
        financingPlansToAdd.map(financingPlanId => addFinancingPlanToFolderApi(folderId, financingPlanId)),
      );

      onFinancingPlanAdded();
      createNotification(<>Les plans de financement ont été ajoutés avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'ajout des plans de financement</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setModalVisible(false);
    }
  }

  return (
    <form onSubmit={handleSubmit(handleImportFinancingPlans)}>
      <div className='import-financingplan-checkbox-header'>
        <h3>Liste des plans de financement (terminés)</h3>
        <p>Sélectionner les plans de financement à importer dans votre dossier.</p>
      </div>
      <Searchbar
        onChange={value => setSearchValue(value)}
        placeholder='Rechercher un plan de financement...'
        bgColor='var(--white)'
        className='import-financingplan-searchbar'
      />
      <div className='import-financingplans-list-container'>
        {filteredFinancingPlans.length ? (
          filteredFinancingPlans.map(plan => {
            const isInFolder = isFinancingPlanInFolder(plan.financing_plan_id);
            return (
              <Checkbox
                key={plan.financing_plan_id}
                label={plan.financing_plan_name}
                name={`financingPlan-${plan.financing_plan_id}`}
                onChange={() => toggleFinancingPlanSelection(plan.financing_plan_id)}
                className='import-financingplan-checkbox'
                useForm={{ setValue }}
                errors={errors}
                checked={selectedFinancingPlans.includes(plan.financing_plan_id)}
                disabled={isInFolder}
              />
            );
          })
        ) : (
          <div className='import-financingplan-no-financingplan'>
            <p>Aucun plan de financement trouvé</p>
          </div>
        )}
      </div>
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' type='submit'>
          Importer
        </FilledButton>
      </div>
    </form>
  );
}

export default ImportFinancingPlansFolderModal;
