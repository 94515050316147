import axiosInstance from "./AxiosConfig";

export const downloadEstimationPdfApi = async estimationId => axiosInstance.get(`/estimation/download/${estimationId}`);

export const downloadFinancingPlanPdfApi = async financingPlanId =>
  axiosInstance.get(`/financing-plan/download/${financingPlanId}`);

export const downloadCreditSimulatorPdfApi = async creditSimulatorId =>
  axiosInstance.get(`/credit-simulator/download/${creditSimulatorId}`);

export const downloadFolderApi = async folderId => axiosInstance.get(`/folders/download/${folderId}`);

export const downloadFolderDeliverableApi = async deliverableId =>
  axiosInstance.get(`/folders/deliverable/download/${deliverableId}`);
