import React from "react";
import { ReactComponent as RayonnementImplantation } from "../../../../assets/pdf/icons/rayonnement_implantation.svg";
import { ReactComponent as DonnesComplementaires } from "../../../../assets/pdf/icons/donnes_complementaires.svg";
import { ReactComponent as LoyerCharges } from "../../../../assets/pdf/icons/loyer_charges.svg";
import { ReactComponent as MaterielAgencements } from "../../../../assets/pdf/icons/materiel_agencements.svg";
import { ReactComponent as NormesAccessibilite } from "../../../../assets/pdf/icons/normes_accessibilite.svg";
import { ReactComponent as QualiteLocal } from "../../../../assets/pdf/icons/qualite_local.svg";
import { ReactComponent as RatiosClefs } from "../../../../assets/pdf/icons/ratios_clefs.svg";
import { ReactComponent as SituationLocative } from "../../../../assets/pdf/icons/situation_locative.svg";

function TitleIcon({ type, width = "30px", color = "var(--dark-blue)", className = "", onClick }) {
  return (
    <>
      {type === "rayonnement_implantation" && (
        <RayonnementImplantation
          style={{ "--color": color, "stroke": "var(--color)", "cursor": onClick ? "pointer" : "auto" }}
          width={width}
          height={width}
          className={className + (onClick ? " clickable-icon" : "")}
          onClick={onClick}
        />
      )}
      {type === "donnees_complementaires" && (
        <DonnesComplementaires
          style={{ "--color": color, "stroke": "var(--color)", "cursor": onClick ? "pointer" : "auto" }}
          width={width}
          height={width}
          className={className + (onClick ? " clickable-icon" : "")}
          onClick={onClick}
        />
      )}
      {type === "loyer_charges" && (
        <LoyerCharges
          style={{ "--color": color, "stroke": "var(--color)", "cursor": onClick ? "pointer" : "auto" }}
          width={width}
          height={width}
          className={className + (onClick ? " clickable-icon" : "")}
          onClick={onClick}
        />
      )}
      {type === "materiel_agencements" && (
        <MaterielAgencements
          style={{ "--color": color, "stroke": "var(--color)", "cursor": onClick ? "pointer" : "auto" }}
          width={width}
          height={width}
          className={className + (onClick ? " clickable-icon" : "")}
          onClick={onClick}
        />
      )}
      {type === "normes_accessibilite" && (
        <NormesAccessibilite
          style={{ "--color": color, "stroke": "var(--color)", "cursor": onClick ? "pointer" : "auto" }}
          width={width}
          height={width}
          className={className + (onClick ? " clickable-icon" : "")}
          onClick={onClick}
        />
      )}
      {type === "qualite_local" && (
        <QualiteLocal
          style={{ "--color": color, "stroke": "var(--color)", "cursor": onClick ? "pointer" : "auto" }}
          width={width}
          height={width}
          className={className + (onClick ? " clickable-icon" : "")}
          onClick={onClick}
        />
      )}
      {type === "ratios_clefs" && (
        <RatiosClefs
          style={{ "--color": color, "stroke": "var(--color)", "cursor": onClick ? "pointer" : "auto" }}
          width={width}
          height={width}
          className={className + (onClick ? " clickable-icon" : "")}
          onClick={onClick}
        />
      )}
      {type === "situation_locative" && (
        <SituationLocative
          style={{ "--color": color, "stroke": "var(--color)", "cursor": onClick ? "pointer" : "auto" }}
          width={width}
          height={width}
          className={className + (onClick ? " clickable-icon" : "")}
          onClick={onClick}
        />
      )}
    </>
  );
}

export default TitleIcon;
