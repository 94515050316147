import React from "react";
import { ReactComponent as FullLogoDark } from "../../../../assets/logos/logo_emc_full_dark.svg";
import { ReactComponent as IconLogoDark } from "../../../../assets/logos/logo_emc_icon_dark.svg";
import { ReactComponent as TextLogoDark } from "../../../../assets/logos/logo_emc_text_dark.svg";
import { ReactComponent as FullLogoLight } from "../../../../assets/logos/logo_emc_full_light.svg";
import { ReactComponent as IconLogoLight } from "../../../../assets/logos/logo_emc_icon_light.svg";
import { ReactComponent as TextLogoLight } from "../../../../assets/logos/logo_emc_text_light.svg";

//typeLogo = 1 : text / 2 : icon / 3 : full
function EmcLogo({ dark, className, typeLogo = 1, height = "85px" }) {
  return typeLogo === 1 ? (
    dark ? (
      <TextLogoDark style={{ height: height }} className={className} />
    ) : (
      <TextLogoLight style={{ height: height }} className={className} />
    )
  ) : typeLogo === 2 ? (
    dark ? (
      <IconLogoDark style={{ height: height }} className={className} />
    ) : (
      <IconLogoLight style={{ height: height }} className={className} />
    )
  ) : dark ? (
    <FullLogoDark style={{ height: height }} className={className} />
  ) : (
    <FullLogoLight style={{ height: height }} className={className} />
  );
}

export default EmcLogo;
