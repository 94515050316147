import "./BusinessImagesPdf.css";
import React, { Fragment, useContext, useEffect, useState } from "react";
import H2Pdf from "../h2Pdf/H2Pdf";
import { PdfContext } from "../../../context/PdfContext";

function BusinessImagesPdf() {
  const { businessImages } = useContext(PdfContext);
  const [displayedImages, setDisplayedImages] = useState([]);

  useEffect(() => {
    setDisplayedImages(businessImages.filter((image, index) => index && image));
  }, [businessImages]);

  return displayedImages?.length ? (
    <>
      {displayedImages.map((imagePath, key) => (
        <Fragment key={imagePath}>
          <div className='pdf-business-image-container'>
            <img src={imagePath} className='pdf-business-img' alt={`Photo de l'affaire ${key}`} />
          </div>
          <div possible-page-break='true' />
        </Fragment>
      ))}
    </>
  ) : null;
}

export default BusinessImagesPdf;
