import React, { Fragment } from "react";
import "./StrongWeakPointsGroupPdf.css";
import H3Pdf from "../h3Pdf/H3Pdf";
import Star from "../../atoms/pdf/icons/Star";

// data = {
//   title: '',
//   content: [
//     {
//       title: '',
//       starsCount: 3,
//       points: [
//         {
//           isStrongPoint: true,
//           content: '',
//         },
//       ],
//     },
//   ],
// };

function StrongWeakPointsGroupPdf({ data }) {
  return (
    <>
      <H3Pdf title={data?.title} />
      {data.content?.map(section => (
        <Fragment key={section.title}>
          <div className='strong-weak-points-h4'>
            <h4>{section.title}</h4>
            <div className='strong-weak-points-stars'>
              {Array.from(
                new Array(3)
                  .fill(0)
                  .map((_, index) => (
                    <Star
                      key={index}
                      color={index >= section.starsCount ? "var(--light-grey)" : "var(--gold)"}
                      isBright={index === 2 && section.starsCount === 3}
                    />
                  )),
              )}
            </div>
          </div>
          {section.points.map((point, index) => (
            <Fragment key={index}>
              <div className='pdf-point-container' key={index}>
                <div className={`${point.isStrongPoint ? "strong" : "weak"}-point`} key={index}>
                  {point.isStrongPoint ? "Force" : "Faiblesse"}
                </div>
                <p>{point.content}</p>
              </div>
              <div possible-page-break='true' />
            </Fragment>
          ))}
        </Fragment>
      ))}
    </>
  );
}

export default StrongWeakPointsGroupPdf;
