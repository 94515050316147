import React, { useContext } from "react";
import "./ReviewButton.css";
import Rocket from "../../../../assets/icons/rocket/rocket.webp";
import AppContext from "../../../../context/AppContext";
import UnfilledButton from "../unfilledButton/UnfilledButton";
import FilledButton from "../filledButton/FilledButton";

function ReviewButton({
  modalTitle,
  modalText,
  children,
  link,
  borderColor = "var(--gold)",
  backgroundColor = "white",
  hoverColor = "var(--gold)",
  textColor = "var(--dark-blue)",
}) {
  const { setModalContent, setModalVisible } = useContext(AppContext);

  function openReviewModal() {
    setModalContent({
      title: modalTitle,
      content: (
        <>
          <p className='text-justify'>
            Votre avis est précieux pour nous ! Avant d'accéder au formulaire, assurez-vous d'avoir créé {modalText}{" "}
            pour pouvoir donner votre avis.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton padding='10px 25px' to={link} target='_blank'>
              Accéder
            </FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  return (
    <div
      className='google-form-review-link'
      onClick={openReviewModal}
      style={{
        "--border-color": borderColor,
        "--background-color": backgroundColor,
        "--hover-color": hoverColor,
        "--text-color": textColor,
      }}>
      {children}
      <img className='rocket-img' src={Rocket} alt='Rocket' />
    </div>
  );
}

export default ReviewButton;
