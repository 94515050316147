import "./FrameTextPdf.css";
import React from "react";

function FrameTextPdf({ children, className = "" }) {
  return (
    <div className={`frame-gradient-border-container ${className}`}>
      <div className='frame-text-content'>{children}</div>
    </div>
  );
}

export default FrameTextPdf;
