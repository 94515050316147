import React, { useEffect, useContext } from "react";
import { ParcoursContext } from "../../../../../context/ParcoursContext";
import { useForm } from "react-hook-form";
import axios from "axios";
import NumberInput from "../../../../molecules/formComponents/numberInput/NumberInput";
import { numberValidation } from "../../../../../utils/formValidation/FormValidation.js";
import Textarea from "../../../../molecules/formComponents/textarea/Textarea.js";
import { formatObjectForPosting, removeSpaces } from "../../../../../utils/Utils.js";

function LoyerCharges({ index }) {
  const {
    register,
    reset,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const inputForm = { register, setValue, watch, unregister };

  const {
    step,
    resetFormsRef,
    handleTabs,
    moveToNextStep,
    setStep,
    submitFormsMc,
    postValues,
    getStepToGo,
    setGoToStep,
    estimationValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);

  useEffect(() => {
    submitFormsMc.current[index] = handleSubmit(formSubmit);
    resetFormsRef.current[index] = () => reset();
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(["surface"], setValue, estimationValues);
  }, [estimationValues]);

  async function formSubmit(values) {
    await postValues(formatObjectForPosting(values));

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }
  return (
    <>
      <form onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
        <fieldset className='borderless-fieldset'>
          <h2>Surface</h2>
          <div className='row-1000 mb-sm'>
            <NumberInput
              useForm={inputForm}
              label='surface de plain-pied'
              name='surface.surface_plain_pied'
              error={errors?.surface?.surface_plain_pied?.message}
            />
            <NumberInput
              useForm={inputForm}
              label='surface étage(s)'
              name='surface.surface_etage'
              error={errors?.surface?.surface_etage?.message}
            />
          </div>
          <NumberInput
            useForm={inputForm}
            label='surface sous-sol'
            name='surface.surface_sous_sol'
            error={errors?.surface?.surface_sous_sol?.message}
            {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
          />
        </fieldset>
      </form>
    </>
  );
}

export default LoyerCharges;
