import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../../context/AppContext";
import UnfilledButton from "../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import Select from "../../../molecules/formComponents/select/Select";

function AddBilanYearModal({ options, preSelectedYear, onSubmit }) {
  const [value, setValue] = useState(preSelectedYear.toString() || "");
  const { setModalVisible } = useContext(AppContext);

  useEffect(() => {
    setValue(preSelectedYear.toString() || "");
  }, [preSelectedYear]);

  return (
    <form>
      <Select value={value} onChange={e => setValue(e.target.value)} label='Année'>
        {options.map(year => (
          <option key={year} value={year + ""}>
            {year}
          </option>
        ))}
      </Select>
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' onClick={() => onSubmit(value)}>
          Ajouter l'année
        </FilledButton>
      </div>
    </form>
  );
}

export default AddBilanYearModal;
