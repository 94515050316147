import React, { useContext, useState, useRef, useEffect } from "react";
import "./FolderEstimations.css";
import ImportEstimationsFolderModal from "../../../../../../../components/forms/modals/account/importEstimationsFolderModal/ImportEstimationsFolderModal";
import AppContext from "../../../../../../../context/AppContext";
import { deleteAllEstimationsFromFolderApi, deleteEstimationFromFolderApi } from "../../../../../../../api/FolderApi";
import { useWindowSize } from "@uidotdev/usehooks";
import FilledButton from "../../../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../../../components/atoms/icons/general/add/Add";
import Download from "../../../../../../../components/atoms/icons/general/download/Download";
import Bracket from "../../../../../../../components/atoms/icons/general/bracket/Bracket";
import Pen from "../../../../../../../components/atoms/icons/account/pen/Pen";
import Trash from "../../../../../../../components/atoms/icons/general/trash/Trash";
import UnfilledButton from "../../../../../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import Emc from "../../../../../../../components/atoms/icons/header/emc/Emc";
import { useAuth } from "../../../../../../../context/AuthContext";
import { Link } from "react-router-dom";
import { downloadEstimationPdfApi } from "../../../../../../../api/DownloadApi";
import { copyToClipboard, createDownloadLink } from "../../../../../../../utils/Utils";
import Eye from "../../../../../../../components/atoms/icons/general/eye/Eye";
import {
  EstimationsTabFilters,
  EstimationTabRowItem,
  EstimationsTabDropdownMenu,
} from "../../../accountsToolsTab/EstimationsTabComponents";
import DuplicateEstimationModal from "../../../../../../../components/forms/modals/account/duplicateEstimationModal/DuplicateEstimationModal";
import { archiveEstimationApi } from "../../../../../../../api/EstimationApi";

function FolderEstimations({ folder, triggerFolderUpdate }) {
  const { setModalContent, setModalVisible, createNotification, setIsDownloadNotificationLoading } =
    useContext(AppContext);
  const [openedItemDropdown, setOpenedItemDropdown] = useState(false);
  const [itemsContainerScroll, setItemsContainerScroll] = useState(0);
  const itemsContainerRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const { width } = useWindowSize();
  const { getUuid } = useAuth();

  useEffect(() => {
    document.addEventListener("click", handleCloseDropdownMenu);

    return () => {
      document.removeEventListener("click", handleCloseDropdownMenu);
    };
  }, [dropdownMenuRef.current]);

  useEffect(() => {
    if (!itemsContainerRef.current) return;

    onItemsContainerScroll();

    itemsContainerRef.current?.addEventListener("scroll", onItemsContainerScroll);

    return () => {
      itemsContainerRef.current?.removeEventListener("scroll", onItemsContainerScroll);
    };
  }, [openedItemDropdown]);

  function handleCloseDropdownMenu() {
    if (!dropdownMenuRef.current) return;

    setOpenedItemDropdown(false);
  }

  function onItemsContainerScroll() {
    if (!openedItemDropdown) return setItemsContainerScroll(itemsContainerRef.current.scrollTop);

    const elementOffsetBottom =
      itemsContainerRef.current.children[openedItemDropdown.key]?.offsetTop ??
      0 + itemsContainerRef.current.children[openedItemDropdown.key]?.offsetHeight ??
      0;

    const containerOffsetBottom = itemsContainerRef.current.scrollTop + itemsContainerRef.current.offsetHeight;
    let dropdownMenuHeight = 20;
    for (const child of dropdownMenuRef.current.children) dropdownMenuHeight += child.offsetHeight + 10;

    if (elementOffsetBottom + dropdownMenuHeight + 25 > containerOffsetBottom)
      setItemsContainerScroll(itemsContainerRef.current.scrollTop + dropdownMenuHeight + 15);
    else setItemsContainerScroll(itemsContainerRef.current.scrollTop);

    if (
      (openedItemDropdown && elementOffsetBottom < itemsContainerRef.current.scrollTop) ||
      elementOffsetBottom > containerOffsetBottom
    )
      setOpenedItemDropdown(false);
  }

  function toggleOpenedItemDropdown(e, key) {
    e.preventDefault();

    if (!dropdownMenuRef.current)
      return createNotification(<>Une erreur est survenue. Veuillez réessayer.</>, "var(--red)", "var(--dark-blue)");

    const tmp = openedItemDropdown;
    const top = itemsContainerRef.current.children[key].offsetTop + 60;

    if (openedItemDropdown) setOpenedItemDropdown(false);

    setTimeout(() => {
      if (tmp.key !== key)
        setOpenedItemDropdown({
          item: folder.estimations[key],
          key,
          top,
        });
    }, 10);
  }

  function openImportEstimationModal() {
    setModalContent({
      title: "Importer des estimations",
      content: (
        <ImportEstimationsFolderModal
          folderId={folder.data.folder_id}
          getUuid={getUuid}
          folder={folder}
          onEstimationAdded={triggerFolderUpdate}
        />
      ),
    });
    setModalVisible(true);
  }

  function handleDeleteAllEstimations() {
    setModalContent({
      title: "⚠️ Attention",
      content: (
        <div>
          <p>
            Êtes-vous sûr de vouloir retirer{" "}
            {folder.estimations.length > 1 ? "toutes les estimations du dossier" : "l'estimation du dossier"} ?
            <br /> Cette action est irréversible et retirera également les estimations des livrables associés.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                confirmDeleteAllEstimations();
              }}>
              Retirer
            </FilledButton>
          </div>
        </div>
      ),
    });
    setModalVisible(true);
  }

  async function confirmDeleteAllEstimations() {
    try {
      await deleteAllEstimationsFromFolderApi(folder.data.folder_id, true);

      triggerFolderUpdate();
      createNotification(<>Toutes les estimations ont été retirées avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du retrait des estimations</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function handleDeleteSingleEstimation(estimationId) {
    setModalContent({
      title: "⚠️ Attention",
      content: (
        <div>
          <p>
            Êtes-vous sûr de vouloir retirer cette estimation de votre dossier ?<br />
            Cette action est irréversible et retirera également l'estimation des livrables associés.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                confirmDeleteSingleEstimation(estimationId);
              }}>
              Retirer
            </FilledButton>
          </div>
        </div>
      ),
    });
    setModalVisible(true);
  }

  async function confirmDeleteSingleEstimation(estimationId) {
    try {
      await deleteEstimationFromFolderApi(folder.data.folder_id, estimationId, true);

      triggerFolderUpdate();
      createNotification(<>L'estimation a été retirée avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du retrait de l'estimation</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function downloadEstimation(estimationId) {
    try {
      setIsDownloadNotificationLoading(true);

      const res = await downloadEstimationPdfApi(estimationId);

      const link = createDownloadLink(res.data);
      link.click();
      link.remove();

      createNotification(<>La simulation de prêt a été téléchargée avec succès.</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du téléchargement de la simulation de prêt. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setIsDownloadNotificationLoading(false);
    }
  }

  function getDuplicateEstimationName(estimationId) {
    const estimation = folder.estimations.find(estimation => estimation.id === estimationId);
    const estimationName = estimation.infos.enseigne ?? estimation.infos.nom;
    const regex = /#\d+$/;

    if (regex.test(estimationName)) {
      const match = estimationName.match(/\d+$/);

      return estimationName.replace(regex, `#${parseInt(match[0]) + 1}`);
    } else {
      return `${estimationName} #1`;
    }
  }

  function showDuplicateItemModal(estimationId) {
    setModalContent({
      title: "Dupliquer l'estimation",
      content: (
        <DuplicateEstimationModal
          defaultValue={getDuplicateEstimationName(estimationId)}
          fetchEstimationsFunction={() => {}}
          estimationId={estimationId}
        />
      ),
    });

    setModalVisible(true);
  }

  function copyIdToClipboard(e, id) {
    e.preventDefault();

    copyToClipboard(id, <>La référence a été copiée dans votre presse-papier avec succès.</>, createNotification);
  }

  return (
    <div>
      <div className='tab-content-header'>
        <h2>
          <Emc />
          Estimations
        </h2>
        <p>Retrouvez ici toutes les estimations liées à votre dossier.</p>
      </div>
      <div className='folder-estimations-buttons'>
        <FilledButton to='/debut-parcours'>
          <Add width={width > 576 ? "30px" : "20px"} /> Créer une estimation
        </FilledButton>
        <FilledButton onClick={openImportEstimationModal}>
          <Download width={width > 576 ? "30px" : "20px"} /> Importer une estimation
        </FilledButton>
      </div>
      <div className='folder-table-estimations items-table estimations-table'>
        <div className='items-filters'>
          <EstimationsTabFilters />
        </div>
        <ul
          ref={dropdownMenuRef}
          className={`dropdown-menu-item ${openedItemDropdown ? "show" : ""}`}
          style={{ "--top": openedItemDropdown?.top - itemsContainerScroll + "px" }}>
          <EstimationsTabDropdownMenu
            openedItemDropdown={openedItemDropdown}
            downloadItemPdf={downloadEstimation}
            showDuplicateItemModal={showDuplicateItemModal}
            removeFromFolder={handleDeleteSingleEstimation}
          />
        </ul>
        <div className='items-table-body' ref={itemsContainerRef}>
          {folder.estimations.length ? (
            folder.estimations.map((estimation, index) => (
              <EstimationTabRowItem
                key={estimation?.id}
                index={index}
                copyIdToClipboard={copyIdToClipboard}
                item={estimation}
                date={estimation.date_creation_estimation?.substring(0, 10)}
                toggleOpenedItemDropdown={toggleOpenedItemDropdown}
                openedItemDropdown={openedItemDropdown}
              />
            ))
          ) : (
            <div className='info-block-estimations'>
              <p>Aucune estimation dans ce dossier</p>
            </div>
          )}
        </div>
        <div className='folder-table-estimations-footer mt-sm'>
          {folder.estimations.length > 0 && (
            <UnfilledButton bgColor='var(--dark-red)' onClick={handleDeleteAllEstimations}>
              {folder.estimations.length > 1
                ? "Retirer toutes les estimations du dossier"
                : "Retirer l'estimation du dossier"}
            </UnfilledButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default FolderEstimations;
