import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/icons/header/chart.svg";

function Chart({ width = "30px", color = "var(--dark-blue)", className }) {
  return (
    <Icon
      style={{ "--color": color, "fill": "var(--color)", "stroke": "var(--color)", "minWidth": width }}
      width={width}
      height={width}
      className={className}
    />
  );
}

export default Chart;
