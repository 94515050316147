import { Chart } from "chart.js/auto";
import { calculateChartLegendScale } from "./BarChart";
import "./Chart.css";
import React, { useEffect, useRef } from "react";
import { formatNumberWithSpaces } from "../../../utils/Utils";

function LineChart({ data, onImageReady }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const { max, min } = calculateChartLegendScale(data);

    const options = {
      responsive: true,
      boxWidth: 100,
      layout: {
        padding: {
          left: 10,
          top: 30,
          bottom: 20,
        },
      },
      elements: {
        point: {
          radius: 5,
          pointStyle: "circle",
          hoverRadius: 8,
        },
      },
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            color: "rgb(0, 0, 0)",
            boxWidth: 30,
            padding: 20,
          },
        },
      },
      scales: {
        y: {
          max: max,
          min: min,
          ticks: {
            callback: function (value) {
              return `${formatNumberWithSpaces(value, true)} €`;
            },
          },
        },
      },
      animation: {
        onComplete: () => {
          const imageUrl = canvasRef.current.toDataURL();
          onImageReady(imageUrl);
        },
      },
    };

    Chart.defaults.font.size = 14;
    const chart = new Chart(canvasRef.current, {
      type: "line",
      data,
      options,
    });

    return () => {
      chart.destroy();
    };
  }, [data, onImageReady]);

  return (
    <div className='line-chart-container'>
      <canvas ref={canvasRef} style={{ maxWidth: "650px", maxHeight: "410px", display: "none" }}></canvas>
    </div>
  );
}

export default LineChart;
