import React, { useContext, useEffect, useRef, useState } from "react";
import { FinancingPlanContext } from "../financingPlanContext/FinancingPlanContext";
import { toggleCollapseElement } from "../../../../utils/Utils";
import CardWithHeaders from "../../../molecules/cards/cardWithHeaders/CardWithHeaders";
import CaseInput from "../../../molecules/formComponents/caseInput/CaseInput";
import MultiplesInputs, {
  calcMultiplesInputsTotal,
} from "../../../molecules/formComponents/multiplesInputs/MultiplesInputs";

function FinancingPlanMurs({ visible }) {
  const {
    inputForm,
    linkedEstimationInfos,
    errors,
    getValidation,
    getFormValues,
    setFormValue,
    getValues,
    planIdParam,
    defaultValues,
    setValue,
    multipleInputsDefaultValues,
  } = useContext(FinancingPlanContext);
  const containerRef = useRef(null);

  useEffect(() => {
    toggleCollapseElement(containerRef.current, visible);
    if (visible)
      setTimeout(() => {
        containerRef.current.style.maxHeight = "unset";
      }, 500);
  }, [visible]);

  useEffect(() => {
    if (!planIdParam) fillDefaultValues();
  }, [planIdParam]);

  useEffect(() => {
    if (linkedEstimationInfos?.type !== "murs") return;

    if (linkedEstimationInfos?.total_loyer_murs) setFormValue("loyer", linkedEstimationInfos?.total_loyer_murs);

    calcFraisNotaire();
    calcRbsCredit();
  }, [linkedEstimationInfos]);

  function fillDefaultValues() {
    setValue("murs.ratio_frais_notaire", defaultValues.murs.ratio_frais_notaire, true);
    setValue("murs.taux_emprunt", defaultValues.murs.taux_emprunt, true);
  }

  function getErrorMessage(name) {
    return errors?.murs?.[name]?.message;
  }

  function getSharedProps(name, label, required, icon = "euro") {
    return {
      name: `murs.${name}`,
      label,
      icon,
      useForm: inputForm,
      error: getErrorMessage(name),
      validation: required ? getValidation(visible) : {},
    };
  }

  // AUTO CALCULATIONS
  function calcRatioFraisNotaire() {
    const values = getFormValues(["frais_notaire", "prix_murs"]);

    setFormValue("ratio_frais_notaire", values.frais_notaire / values.prix_murs / 0.01, true);
    calcTotalAFinancer();
  }

  function calcFraisNotaire() {
    const values = getFormValues(["prix_murs", "ratio_frais_notaire"]);

    setFormValue("frais_notaire", values.prix_murs * values.ratio_frais_notaire * 0.01);
    calcTotalAFinancer();
  }

  function calcTotalAFinancer() {
    const values = getFormValues(["prix_murs", "frais_notaire", "travaux"]);
    const result =
      values.prix_murs + values.frais_notaire + values.travaux + calcMultiplesInputsTotal(getValues("murs.postes"));

    setFormValue("total_a_financer", result);
    setFormValue("total_finance", result);
    calcApportFinancier();
    calcRatioApportFinancier();
    calcMontantAEmprunter();
  }

  function calcRatioApportFinancier() {
    const values = getFormValues(["apport_financier", "total_a_financer"]);

    setFormValue("ratio_apport_financier", values.apport_financier / values.total_a_financer / 0.01, true);
    calcMontantAEmprunter();
  }

  function calcApportFinancier() {
    const values = getFormValues(["ratio_apport_financier", "total_a_financer"]);

    setFormValue("apport_financier", values.ratio_apport_financier * values.total_a_financer * 0.01);
    calcMontantAEmprunter();
  }

  function calcMontantAEmprunter() {
    const values = getFormValues(["total_finance", "apport_financier"]);

    setFormValue("montant_a_emprunter", values.total_finance - values.apport_financier);
    calcRbsCredit();
  }

  function calcRbsCredit() {
    const values = getFormValues(["taux_emprunt", "duree_pret", "montant_a_emprunter"]);
    values.taux_emprunt /= 100;
    const taux_mensuel = values.taux_emprunt / 12;

    const mensualite =
      (values.montant_a_emprunter * taux_mensuel) / (1 - Math.pow(1 + taux_mensuel, -values.duree_pret));

    const annuite = mensualite * 12;

    setFormValue("rbs_credit", Math.round(annuite * 100) / 100);
    calcBeneficePerte();
  }

  function calcBeneficePerte() {
    const values = getFormValues(["loyer", "rbs_credit"]);

    setFormValue("benefice_perte", values.loyer - values.rbs_credit);
  }

  return (
    <div className='financing-plan-murs-container' ref={containerRef}>
      <section className='financing-plan-cards-container'>
        <CardWithHeaders
          padding='20px'
          paddingFooter='10px'
          header={<h2>Besoins</h2>}
          footer={
            <CaseInput
              name='murs.total_a_financer'
              useForm={inputForm}
              label='TOTAL À FINANCER'
              icon='euro'
              color='white'
              disabled
            />
          }>
          <CaseInput {...getSharedProps("prix_murs", "Prix des murs", true)} onChange={() => calcFraisNotaire()} />
          <CaseInput
            {...getSharedProps("ratio_frais_notaire", "Frais de notaire", false, "percent")}
            onChange={() => calcFraisNotaire()}
          />
          <CaseInput
            {...getSharedProps("frais_notaire", "Montant des frais de notaire")}
            onChange={() => calcRatioFraisNotaire()}
          />
          <CaseInput {...getSharedProps("travaux", "Travaux")} onChange={() => calcTotalAFinancer()} />
          <MultiplesInputs
            defaultValues={multipleInputsDefaultValues?.murs?.postes}
            onInputChange={() => calcTotalAFinancer()}
            errors={errors?.murs?.postes}
            useForm={inputForm}
            name='murs.postes'
            buttonText='Ajouter un poste à financer'
          />
        </CardWithHeaders>
        <CardWithHeaders
          borderColor='var(--dark-blue-alt)'
          padding='20px'
          paddingFooter='10px'
          header={<h2>Ressources</h2>}
          footer={
            <CaseInput
              name='murs.total_finance'
              useForm={inputForm}
              label='TOTAL FINANCÉ'
              icon='euro'
              color='white'
              disabled
            />
          }>
          <CaseInput
            {...getSharedProps("ratio_apport_financier", "Apport financier (% du total à financer)", false, "percent")}
            onChange={() => calcApportFinancier()}
          />
          <CaseInput
            {...getSharedProps("apport_financier", "Montant de l'apport", true)}
            onChange={() => calcRatioApportFinancier()}
          />
          <CaseInput
            {...getSharedProps("montant_a_emprunter", "Montant à emprunter")}
            onChange={() => calcRbsCredit()}
          />
          <CaseInput
            {...getSharedProps("duree_pret", "Durée du prêt (années)", true, null)}
            onChange={() => calcRbsCredit()}
          />
          <CaseInput
            {...getSharedProps("taux_emprunt", "Taux d'emprunt (TAEG)", true, "percent")}
            onChange={() => calcRbsCredit()}
          />
        </CardWithHeaders>
      </section>
      <div className='financing-plan-bottom'>
        <h2 className='financing-plan-title' style={{ "--background-color": "var(--sky-blue)" }}>
          Le loyer perçu dans le local d'activité
        </h2>
        <CaseInput
          {...getSharedProps("loyer", "Loyer annuel payé par l'occupant", true)}
          onChange={() => calcBeneficePerte()}
        />
        <h2 className='financing-plan-title' style={{ "--background-color": "var(--blue)" }}>
          ...Sert à rembourser le prêt sur l'achat des murs...
        </h2>
        <CaseInput
          {...getSharedProps("rbs_credit", "Remboursement annuel du crédit (annuité capital + intérêts)")}
          disabled
        />
        <h2 className='financing-plan-title' style={{ "--background-color": "var(--dark-blue)" }}>
          ...Et dégage un excédent bénéficiaire ou une perte à combler
        </h2>
        <CaseInput {...getSharedProps("benefice_perte", "Bénéfice annuel ou perte annuelle")} disabled />
      </div>
    </div>
  );
}

export default FinancingPlanMurs;
