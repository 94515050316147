import "./H2Pdf.css";
import React from "react";

function H2Pdf({ title, number, className = "", notOnSummary, importanceLevel = 1 }) {
  function getNormalizedTitle(title) {
    if (!title) return "";

    if (typeof title === "string") return title.normalize();

    return title.props.children.join(" ");
  }

  return (
    <div
      className={`text-h2-container ${className}`}
      h2-title={getNormalizedTitle(title)}
      not-on-summary={notOnSummary ? "true" : null}
      importance-level={importanceLevel}>
      <div className='h2-pdf-number' data-number={number}>
        <svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'>
          <text
            x='50'
            y='85'
            fontSize='70'
            textAnchor='middle'
            fontFamily='Montserrat'
            fontWeight='bold'
            fill='black'
            stroke='#ebd595'
            strokeWidth='7'
            paintOrder='stroke fill'
            strokeLinejoin='miter'
            strokeMiterlimit='2'>
            {number}
          </text>
        </svg>
      </div>
      <h2 data-number={number} className='h2-pdf-title'>
        {title}
      </h2>
    </div>
  );
}

export default H2Pdf;
