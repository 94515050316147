import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

function RequireCode({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const accessCode = localStorage.getItem("accessCode");
  const urlParams = new URLSearchParams(location.search);
  const codeFromUrl = urlParams.get("code");
  const requiredCode = process.env.REACT_APP_REQUIRED_ACCESS_CODE;
  const publicAccess = process.env.REACT_APP_PUBLIC_ACCESS === "1";
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (codeFromUrl && codeFromUrl === requiredCode) {
      localStorage.setItem("accessCode", codeFromUrl);
      navigate(location.pathname);
    }
  }, [codeFromUrl, requiredCode, navigate, location.pathname]);

  useEffect(() => {
    if (searchParams.get("key") === "ka65uiIUba_987aa") {
      localStorage.setItem("accessCode", requiredCode);
      return navigate(location.pathname);
    }

    if (!publicAccess && (!accessCode || accessCode !== requiredCode)) {
      navigate("/access", { replace: true });
    }
  }, [accessCode, requiredCode, publicAccess, navigate]);

  if (publicAccess || accessCode === requiredCode) {
    return children;
  } else {
    return null;
  }
}

export default RequireCode;
