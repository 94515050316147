import React, { useEffect, useContext } from "react";
import Radio from "../../../../molecules/formComponents/radio/Radio";
import { useForm } from "react-hook-form";
import { ParcoursContext } from "../../../../../context/ParcoursContext";
import Textarea from "../../../../molecules/formComponents/textarea/Textarea.js";
import { max2250Validation } from "../../../../../utils/formValidation/FormValidation.js";

function SituationLocative({ index }) {
  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    reset,
    formState: { errors },
  } = useForm();
  const inputForm = { register, unregister, setValue };
  const {
    step,
    handleTabs,
    moveToNextStep,
    setStep,
    submitFormsMc,
    postValues,
    getStepToGo,
    resetFormsRef,
    setGoToStep,
    estimationValues,
  } = useContext(ParcoursContext);

  useEffect(() => {
    submitFormsMc.current[index] = handleSubmit(formSubmit);
    resetFormsRef.current[index] = () => reset();
  }, [step, estimationValues]);

  useEffect(() => {
    setValue("situation_locative.occupation_local.id", estimationValues?.situation_locative?.occupation_local?.id + "");
    setValue("notes.note_step_3", estimationValues?.notes?.note_step_3);
  }, [estimationValues]);

  async function formSubmit(values) {
    await postValues(values);

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }
  return (
    <>
      <form onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
        <fieldset className='borderless-fieldset'>
          <h2>situation locative</h2>
          <Radio
            label='le local est actuellement :'
            useForm={inputForm}
            name='situation_locative.occupation_local.id'
            error={errors?.situation_locative?.occupation_local?.id?.message}
            content={[
              {
                label: "Occupé",
                value: 1,
              },
              {
                label: "Vide",
                value: 2,
              },
            ]}
          />
          <Textarea
            useForm={inputForm}
            name='notes.note_step_3'
            className='mt-md'
            label='notes et remarques'
            validation={max2250Validation}
            error={errors?.notes?.note_step_3?.message}
            {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
          />
        </fieldset>
      </form>
    </>
  );
}

export default SituationLocative;
