import React from "react";
import "./GlobalReviewNotification.css";
import ReviewButton from "../../molecules/buttons/reviewButton/ReviewButton";

function GlobalReviewNotification() {
  return (
    <div className='review-notification-container'>
      <p className='title-review-notification'>Votre avis compte pour nous !</p>
      <p className='text-review-notification'>Répondez à notre questionnaire en 30sec chrono</p>
      <ReviewButton
        borderColor='var(--dark-blue)'
        backgroundColor='white'
        hoverColor='var(--dark-blue)'
        textColor='var(--dark-blue)'
        modalTitle='Votre avis sur notre site'
        modalText='un compte'
        link='https://docs.google.com/forms/d/e/1FAIpQLScgs6eDdVyelTtynefTwVXkZtiRiP_52AcWsVs1pCo2B6qz-A/viewform?usp=pp_url'>
        C'est parti
      </ReviewButton>
    </div>
  );
}

export default GlobalReviewNotification;
