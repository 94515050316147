import "./Toggle.css";
import React, { useRef, useEffect } from "react";
import { toggleCollapseElement } from "../../../../utils/Utils";

// useForm = {
//   register: () => {},
//   unregister: () => {},
//   setValue: () => {},
// }
function Toggle({
  useForm = {},
  label,
  label1,
  name,
  visible = true,
  color = "var(--sky-blue)",
  bgColor = "var(--blue)",
  onChange = () => {},
  checked,
  className = "",
}) {
  const { register = () => {}, unregister = () => {}, setValue = () => {} } = useForm;
  const ref = useRef(null);

  useEffect(() => {
    toggleCollapseElement(ref.current, visible, 0);

    if (!visible) {
      unregister(name, { keepValue: true });
    }
  }, [visible]);

  function handleChange(e) {
    setValue(name, e.target.checked);
    onChange(e);
  }

  return (
    <div className={`toggle-error-container ${className}`} ref={ref} style={{ display: visible ? "block" : "none" }}>
      <div className='field-container'>
        <div className='toggle-container'>
          <label className='form-label' htmlFor={name}>
            {label}
          </label>
          <input
            {...(visible && register(name))}
            disabled={visible !== undefined && !visible}
            tabIndex={visible ? 0 : -1}
            checked={checked}
            name={name}
            className='toggle-input'
            type='checkbox'
            id={name}
            onChange={handleChange}
          />
          <label htmlFor={name} className='toggle-front' style={{ "--bg-color": bgColor, "--color": color }} />
          <label className='form-label' htmlFor={name}>
            {label1}
          </label>
        </div>
      </div>
    </div>
  );
}

export default Toggle;
