import React from "react";
import { ReactComponent as Icon } from "../../../../../assets/icons/general/tuto.svg";

function Tuto({ width = "50px", className = "", onClick }) {
  return (
    <Icon
      style={{ cursor: onClick ? "pointer" : "auto", minWidth: width }}
      width={width}
      className={className + (onClick ? " clickable-icon" : "")}
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
    />
  );
}

export default Tuto;
