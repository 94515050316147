import React from "react";
import "./TablePdf.css";

// data = {
//   thead: [''],
//   tbody: [
//     {
//       type: 1,
//       cells: [''],
//     },
//   ],
// };

function TablePdf({ data, className = "", hasTh = true, isHeadFullLine }) {
  function getCellClass(type) {
    switch (type) {
      case 2:
        return "cell-light-color-background";
      case 3:
        return "cell-medium-color-background";
      case 4:
        return "cell-dark-color-background";
      case 5:
        return "cell-pale-color-background";
      default:
        return "";
    }
  }

  return (
    <table className={`table-pdf-container ${className}`}>
      <thead>
        <tr>
          {data.thead.map((label, index) => (
            <th
              colSpan={isHeadFullLine ? data.tbody[0].cells.length : 1}
              key={index}
              className={label ? "" : "hidden-th"}>
              {label?.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.tbody.map((row, index) => (
          <tr key={index} className={getCellClass(row.type)}>
            {hasTh ? (
              <th
                colSpan={row.fullLine ? data.tbody[0].cells.length : 1}
                className={row.fullLine ? " text-center" : ""}>
                {row?.cells[0].split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </th>
            ) : (
              <td>{row.cells[0]}</td>
            )}
            {row.cells.map((cell, index) => index > 0 && <td key={index}>{cell}</td>)}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default TablePdf;
