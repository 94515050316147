import React, { useContext, useEffect } from "react";
import Input from "../../../../molecules/formComponents/input/Input";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import AppContext from "../../../../../context/AppContext";
import { defaultValidation } from "../../../../../utils/formValidation/FormValidation";
import { useForm } from "react-hook-form";
import { duplicateFinancingPlanApi } from "../../../../../api/FinancingPlanApi";

function DuplicateFinancingPlanModal({ financingPlanId, defaultValue, fetchFinancingPlansFunction = async () => {} }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { setModalVisible, createNotification } = useContext(AppContext);

  useEffect(() => {
    setValue("name", defaultValue);
  }, [defaultValue]);

  async function duplicateFinancingPlan(values) {
    try {
      await duplicateFinancingPlanApi(financingPlanId, values.name);
      await fetchFinancingPlansFunction();
      createNotification(<>Le plan de financement {values.name} a été dupliqué avec succès.</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la duplication de votre plan de financement. Veuillez réessayer plus tard</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
    setModalVisible(false);
  }
  return (
    <form onSubmit={handleSubmit(duplicateFinancingPlan)}>
      <Input
        validation={defaultValidation}
        useForm={{ register, setValue }}
        error={errors?.enseigne?.message}
        name='name'
        label='Nom du plan de financement'
      />
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' type='submit'>
          Dupliquer le plan de financement
        </FilledButton>
      </div>
    </form>
  );
}

export default DuplicateFinancingPlanModal;
