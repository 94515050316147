import React, { useContext, useState, useEffect } from "react";
import "./AddEstimationToDeliverableModal.css";
import AppContext from "../../../../../context/AppContext";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import Checkbox from "../../../../molecules/formComponents/checkbox/Checkbox";
import { addEstimationToDeliverableApi, fetchDeliverableByIdApi } from "../../../../../api/FolderApi";

function AddEstimationToDeliverableModal({ folder, deliverableId, onEstimationsAdded = () => {} }) {
  const { setModalVisible, createNotification } = useContext(AppContext);
  const [selectedEstimations, setSelectedEstimations] = useState([]);
  const [folderEstimations, setFolderEstimations] = useState([]);
  const [existingEstimations, setExistingEstimations] = useState([]);

  useEffect(() => {
    if (folder?.estimations) {
      setFolderEstimations(folder.estimations);
    }
  }, [folder]);

  useEffect(() => {
    async function fetchExistingEstimations() {
      try {
        const res = await fetchDeliverableByIdApi(deliverableId);
        const deliverableData = res.data.deliverable;
        const estimationsInDeliverable = [
          ...deliverableData.estimations.fonds_de_commerce,
          ...deliverableData.estimations.titres_de_societe,
          ...deliverableData.estimations.murs_commerciaux,
        ];
        setExistingEstimations(estimationsInDeliverable);
      } catch (error) {
        createNotification(
          <>Une erreur est survenue lors de la récupération des estimations. Veuillez réessayer</>,
          "var(--red)",
          "var(--dark-blue)",
        );
      }
    }

    if (deliverableId) {
      fetchExistingEstimations();
    }
  }, [deliverableId]);

  function toggleEstimationSelection(estimation) {
    if (selectedEstimations.some(e => e.id === estimation.id)) {
      setSelectedEstimations(selectedEstimations.filter(e => e.id !== estimation.id));
    } else {
      setSelectedEstimations([...selectedEstimations, estimation]);
    }
  }

  async function handleAddEstimations() {
    try {
      for (const estimation of selectedEstimations) {
        await addEstimationToDeliverableApi(deliverableId, estimation.id);
      }
      createNotification(<>Estimation(s) ajoutée(s) avec succès au livrable</>);
      onEstimationsAdded();
      setModalVisible(false);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'ajout des estimations au livrable</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  const estimationsToDisplay = folderEstimations.filter(
    estimation => !existingEstimations.some(e => e.id === estimation.id),
  );

  return (
    <div>
      <div className='info-add-estimation'>
        <p>Sélectionnez les estimations à ajouter au livrable.</p>
      </div>
      <div className='checkbox-list-container'>
        <h3>Estimation(s) disponibles dans le dossier</h3>
        <div className='import-estimations-to-deliverable'>
          {estimationsToDisplay.length > 0 ? (
            estimationsToDisplay.map(estimation => (
              <Checkbox
                key={estimation.id}
                name={`estimation-${estimation.id}`}
                className='checkbox-estimation-item'
                label={estimation.infos.nom || estimation.infos.enseigne}
                onChange={() => toggleEstimationSelection(estimation)}
                checked={selectedEstimations.some(e => e.id === estimation.id)}
              />
            ))
          ) : (
            <p>Aucune estimation disponible à ajouter.</p>
          )}
        </div>
      </div>
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' onClick={handleAddEstimations} disabled={selectedEstimations.length === 0}>
          Ajouter au livrable
        </FilledButton>
      </div>
    </div>
  );
}

export default AddEstimationToDeliverableModal;
