import "./MethodePdf.css";
import React from "react";

function MethodePdf({ methodeNumber, methodeApproach, className = "" }) {
  return (
    <div className={`methode-pdf-container ${className}`}>
      <h3 className='methode-pdf-number'>{methodeNumber}</h3>
      <h4 className='methode-pdf-approach'>{methodeApproach}</h4>
    </div>
  );
}

export default MethodePdf;
