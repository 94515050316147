import "./Textarea.css";
import React, { useEffect, useRef, useState } from "react";
import { defaultValidation } from "../../../../utils/formValidation/FormValidation.js";
import Warning from "../../../atoms/icons/general/warning/Warning";
import { toggleCollapseElement } from "../../../../utils/Utils";

// useForm = {
//   register: () => {},
//   unregister: () => {},
//   setValue: () => {},
//   watch: () => {},
// }
function Textarea({
  useForm = {},
  className = "",
  label,
  visible = true,
  min,
  max,
  error,
  name,
  defaultValue,
  validation = { defaultValidation },
  onChange = () => {},
  onKeyDown = () => {},
  onFocus = () => {},
  onBlur = () => {},
  tip,
  showWordsCount,
  showCharsCount,
  resizable = true,
}) {
  const { register = () => {}, unregister = () => {}, setValue = () => {}, watch = () => {} } = useForm;
  const ref = useRef(null);
  const [wordsCount, setWordsCount] = useState(0);
  const [charsCount, setCharsCount] = useState(0);
  const watcher = watch(name);

  useEffect(() => {
    toggleCollapseElement(ref.current, visible, 0);

    if (!visible) {
      unregister(name, { keepValue: true });
    }
  }, [visible]);

  function handleChange(e) {
    setValue(name, e.target.value);
    onChange(e);
  }
  useEffect(() => {
    updateWordsCount(watcher);
    updateCharsCount(watcher);
  }, [watcher]);

  function updateWordsCount(value) {
    watcher && setWordsCount(value.split(" ").filter(word => word.length).length);
  }

  function updateCharsCount(value) {
    watcher && setCharsCount(value.length);
  }

  return (
    <div className={`w-100  ${className}`} ref={ref} style={{ display: visible ? "block" : "none" }}>
      <div className='field-container textarea-container'>
        <label className='form-label' htmlFor={name}>
          {label}
        </label>
        <p className={`label-tip ${tip ? "" : " d-none"}`}>{tip}</p>
        {
          <div className='input-words-count'>
            {showWordsCount && <>{wordsCount} mots</>}
            {showWordsCount && showCharsCount && " - "}
            {showCharsCount && <>{charsCount} caractères</>}
          </div>
        }
        <textarea
          {...(visible && register(name, validation))}
          tabIndex={visible ? 0 : -1}
          onKeyDown={onKeyDown}
          disabled={visible !== undefined && !visible}
          name={name}
          id={name}
          minLength={min}
          maxLength={max}
          className={`form-textarea ${error ? "with-error" : ""} ${resizable ? "" : "non-resizable"}`}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          defaultValue={defaultValue}
        />
        {error && (
          <div className='form-error'>
            <Warning /> <p>{error}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Textarea;
