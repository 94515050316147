import axiosInstance from "./AxiosConfig";

export const registerUserApi = async values => axiosInstance.post("/user/add", values);

export const loginUserApi = async values => axiosInstance.post("/auth/login", values);

export const logoutUserApi = async () => axiosInstance.post("/auth/logout");

export const fetchUserDataApi = async uuid => axiosInstance.get(`/user/get?method=uuid&uuid=${uuid}`);

// Get user types
export const fetchUserDropdownApi = async dropdown => axiosInstance.get(`/user/dropdown?field=${dropdown}`);

// Account creation
export const validateAccountApi = async (uuid, code) =>
  axiosInstance.post("/user/activate-account", { uuid: uuid, code: code });

export const refreshAccountCodeApi = async uuid => axiosInstance.post("/user/refresh-account-code", { uuid: uuid });

export const requestEditPasswordApi = async email => axiosInstance.post("/user/request-edit-password", { email });

export const resetPasswordApi = async (email, password) =>
  axiosInstance.post("/user/reset-password", { email, password });

export const checkPasswordCodeApi = async (email, code) =>
  axiosInstance.post("/user/check-password-code", { email, code });

export const deactivateAccountApi = async uuid => axiosInstance.post("/user/deactivate-account", { uuid });

export const deleteAccountApi = async uuid => axiosInstance.delete(`/user/delete/${uuid}`);

export const updateUserDataApi = async (values, uuid) => axiosInstance.put("/user/edit", { ...values, uuid });

export const askReactivationEmailApi = async email => axiosInstance.post("/user/reactivation-mail", { email });

// Pdf customisation
export const fetchUserPdfCustomisationApi = async uuid => axiosInstance.get(`/rapport/style/user/${uuid}`);

export const updateUserPdfCustomisationApi = async (uuid, data) =>
  axiosInstance.post(`/rapport/style`, { user_id: uuid, ...data });
