import React, { useContext, useEffect, useRef, useState } from "react";
import { FinancingPlanContext } from "../financingPlanContext/FinancingPlanContext";
import Input from "../../../molecules/formComponents/input/Input";
import { toggleCollapseElement } from "../../../../utils/Utils";
import CardWithHeaders from "../../../molecules/cards/cardWithHeaders/CardWithHeaders";
import CaseInput from "../../../molecules/formComponents/caseInput/CaseInput";
import MultiplesInputs, {
  calcMultiplesInputsTotal,
} from "../../../molecules/formComponents/multiplesInputs/MultiplesInputs";
import Card from "../../../molecules/cards/card/Card";
import { fetchCotisationSalarieRatiosApi, fetchCotisationsTnsRatiosApi } from "../../../../api/ToolsApi";
import AppContext from "../../../../context/AppContext";
import Checkbox from "../../../molecules/formComponents/checkbox/Checkbox";
import { set } from "react-hook-form";

function FinancingPlanFdc({ visible }) {
  const {
    inputForm,
    errors,
    setLinkedEstimationInfos,
    getValidation,
    getFormValues,
    linkedEstimationInfos,
    setFormValue,
    getValues,
    planIdParam,
    defaultValues,
    setValue,
    multipleInputsDefaultValues,
  } = useContext(FinancingPlanContext);
  const { createNotification } = useContext(AppContext);
  const [cotisationsRatios, setCotisationsRatios] = useState({ tns: null, salarie: null });
  const [displayedRatios, setDisplayedRatios] = useState({ tns: 0, salarie: 0 });
  const [hasSetupCheckboxes, setHasSetupCheckboxes] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    fetchCotisationRatios();
  }, []);

  useEffect(() => {
    toggleCollapseElement(containerRef.current, visible, 300);

    setTimeout(() => {
      containerRef.current.style.maxHeight = visible ? "unset" : "0px";
    }, 500);
  }, [visible]);

  useEffect(() => {
    setTimeout(() => {
      calcRemunerationTns();
      calcRemunerationSalarie();
    }, 200);
  }, [cotisationsRatios]);

  useEffect(() => {
    if (!planIdParam) fillDefaultValues();
  }, [planIdParam]);

  useEffect(() => {
    if (planIdParam && linkedEstimationInfos && !hasSetupCheckboxes && linkedEstimationInfos.ebe_by_year)
      setupEbeCheckboxes(getFormValues(["ebe_retraite"]).ebe_retraite, linkedEstimationInfos.ebe_by_year);
  }, [planIdParam, linkedEstimationInfos, hasSetupCheckboxes]);

  useEffect(() => {
    if (linkedEstimationInfos?.type !== "fdc") return;

    if (linkedEstimationInfos && linkedEstimationInfos.ebe_by_year)
      setupEbeCheckboxes(getFormValues(["ebe_retraite"]).ebe_retraite, linkedEstimationInfos.ebe_by_year);

    calcHonorairesJuridiques();
    calcDroitsEnregistrement();
  }, [linkedEstimationInfos]);

  async function fetchCotisationRatios() {
    try {
      const tnsRatios = [];
      const salarieRatios = [];

      let res = (await fetchCotisationsTnsRatiosApi()).data;

      res.forEach(row =>
        tnsRatios.push({
          remunerationNette: row.rem_nette_tns,
          ratio: row.ratio,
          cotSociales: (row.rem_nette_tns * row.ratio) / 100,
        }),
      );

      res = (await fetchCotisationSalarieRatiosApi()).data.sort((a, b) => Number(a.net_annuel) - Number(b.net_annuel));

      res.forEach(row => {
        const cotSalarieAnnuelles = Math.round((row.net_annuel * row.ratio_cot_soc_salarie) / 100);
        const brutAnnuel = row.net_annuel + cotSalarieAnnuelles;
        const cotPatronAnnuelles = Math.round((brutAnnuel * row.ratio_cot_soc_patronales) / 100);
        const coutEntrepriseAnnuel = brutAnnuel + cotPatronAnnuelles;

        salarieRatios.push({
          netAnnuel: row.net_annuel,
          ratioCotSociales: row.ratio_cot_soc_salarie,
          ratioCotPatronales: row.ratio_cot_soc_patronales,
          cotSalarieAnnuelles: cotSalarieAnnuelles,
          brutAnnuel: brutAnnuel,
          cotPatronAnnuelles: cotPatronAnnuelles,
          coutEntrepriseAnnuel: coutEntrepriseAnnuel,
        });
      });

      setCotisationsRatios({ tns: tnsRatios, salarie: salarieRatios });
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de données. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function fillDefaultValues() {
    setValue("fdc.taux_emprunt", defaultValues.fdc.taux_emprunt, true);
    setValue("fdc.fonds_roulement", defaultValues.fdc.fonds_roulement, true);
  }

  function getErrorMessage(name) {
    return errors?.fdc?.[name]?.message;
  }

  function getSharedProps(name, label, required, icon = "euro") {
    return {
      name: `fdc.${name}`,
      label,
      icon,
      useForm: inputForm,
      error: getErrorMessage(name),
      validation: required ? getValidation(visible) : {},
    };
  }

  // AUTO CALCULATIONS
  function calcHonorairesJuridiques() {
    const prixVente = getFormValues(["prix_vente"]).prix_vente;

    setFormValue("honoraires_juridiques", prixVente * 0.03);

    calcTotalAFinancer();
  }

  function calcDroitsEnregistrement() {
    const prixVente = getFormValues(["prix_vente"]).prix_vente;
    let result = 0;

    if (prixVente >= 23000 && prixVente < 200000) result = (prixVente - 23000) * 0.03;
    else if (prixVente >= 200000) result = 5310 + (prixVente - 200000) * 0.05;

    setFormValue("droits_enregistrement", result);

    calcTotalAFinancer();
  }

  function calcTotalAFinancer() {
    const values = getFormValues([
      "prix_vente",
      "stocks",
      "honoraires_juridiques",
      "droits_enregistrement",
      "fonds_roulement",
      "travaux",
      "materiel_agencements",
    ]);

    const postes = calcMultiplesInputsTotal(getValues("fdc.postes"));

    const result =
      values.prix_vente +
      values.stocks +
      values.honoraires_juridiques +
      values.droits_enregistrement +
      values.fonds_roulement +
      values.travaux +
      values.materiel_agencements +
      postes;

    setFormValue("total_a_financer", result);
    setFormValue("total_finance", result);
    calcApportFinancier();
    calcRatioApportFinancier();
    calcMontantAEmprunter();
  }

  function calcRatioApportFinancier() {
    const values = getFormValues(["apport_financier", "total_a_financer"]);

    setFormValue("ratio_apport_financier", values.apport_financier / values.total_a_financer / 0.01, true);
  }

  function calcApportFinancier() {
    const values = getFormValues(["ratio_apport_financier", "total_a_financer"]);

    setFormValue("apport_financier", values.ratio_apport_financier * values.total_a_financer * 0.01);
    calcMontantAEmprunter();
  }

  function calcMontantAEmprunter() {
    const values = getFormValues(["total_finance", "apport_financier"]);
    const apports = calcMultiplesInputsTotal(getValues("fdc.apports"));

    setFormValue("montant_sept_ans", values.total_finance - values.apport_financier - apports);
    calcRbsCreditAnnuel();
  }

  function calcRbsCreditAnnuel() {
    const values = getFormValues(["taux_emprunt", "montant_sept_ans"]);
    values.taux_emprunt /= 100;
    const taux_mensuel = values.taux_emprunt / 12;
    const duree_mois = 7 * 12;

    const mensualite = (values.montant_sept_ans * taux_mensuel) / (1 - Math.pow(1 + taux_mensuel, -duree_mois));

    const annuite = mensualite * 12;

    setFormValue("rbs_credit", Math.round(annuite * 100) / 100);
    calcResteAVivre();
  }

  function calcResteAVivre() {
    const values = getFormValues(["ebe_retraite", "rbs_credit"]);

    setFormValue("reste_a_vivre", values.ebe_retraite - values.rbs_credit);
    calcRemunerationTns();
    calcRemunerationSalarie();
  }

  function calcRemunerationTns() {
    if (!cotisationsRatios.tns) return;

    const resteAVivre = getFormValues(["reste_a_vivre"]).reste_a_vivre;

    let ratio = cotisationsRatios.tns
      .filter(row => resteAVivre / (1 + row.ratio / 100) >= row.remunerationNette)
      .at(-1)?.ratio;

    if (!ratio) ratio = cotisationsRatios.tns[0].ratio;

    setDisplayedRatios(prev => ({ ...prev, tns: resteAVivre ? Math.max(0, ratio) : 0 }));
    setFormValue("ratio_cot_sociales_tns", ratio);

    const remunerationNette = resteAVivre / (1 + ratio / 100);
    setFormValue("rem_nette_tns", remunerationNette);
    setFormValue("rem_nette_mensuelle_tns", remunerationNette / 12);
    setFormValue("cot_sociales_tns", Math.max(0, (remunerationNette * ratio) / 100));
  }

  function calcRemunerationSalarie() {
    if (!cotisationsRatios.salarie) return;

    const resteAVivre = getFormValues(["reste_a_vivre"]).reste_a_vivre;
    const brutAnnuel = resteAVivre / (1 + getSalariePercent(resteAVivre, "brutAnnuel", "ratioCotPatronales"));
    const remunerationNette = brutAnnuel / (1 + getSalariePercent(brutAnnuel, "brutAnnuel", "ratioCotSociales"));
    const cotSociales = Math.max(0, resteAVivre - remunerationNette);
    const ratio = remunerationNette ? Math.max(0, Math.round((cotSociales * 100) / remunerationNette)) : 0;

    setFormValue("rem_nette_salarie", remunerationNette);
    setFormValue("rem_nette_mensuelle_salarie", remunerationNette / 12);
    setFormValue("cot_sociales_salarie", cotSociales);

    setDisplayedRatios(prev => ({ ...prev, salarie: ratio }));
    setFormValue("ratio_cot_sociales_salarie", ratio);
  }

  function getSalariePercent(value, refField, ratioName) {
    return (
      (cotisationsRatios.salarie.filter(row => value >= row[refField]).at(-1)?.[ratioName] ??
        cotisationsRatios.salarie[0][ratioName]) / 100
    );
  }

  function updateEbeFromEstimation(e, index) {
    const estimationInfos = { ...linkedEstimationInfos };
    estimationInfos.ebe_by_year[index].checked = e.target.checked;
    const checkedEbes = estimationInfos.ebe_by_year.filter(year => year.checked).map(year => year.ebe_retraite);

    const ebe = checkedEbes.length ? checkedEbes.reduce((acc, curr) => acc + curr) / checkedEbes.length : 0;

    setFormValue("ebe_retraite", ebe);

    if (checkedEbes.length === estimationInfos.ebe_by_year.length && estimationInfos.ebe_by_year.length > 1) {
      estimationInfos.allYearsChecked = true;
      estimationInfos.ebe_by_year.forEach(year => (year.checked = false));
    } else {
      estimationInfos.allYearsChecked = false;
    }

    setLinkedEstimationInfos(estimationInfos);

    calcResteAVivre();
  }

  function resetEbeCheckboxes() {
    if (!linkedEstimationInfos) return;

    if (linkedEstimationInfos?.ebe_by_year)
      setLinkedEstimationInfos(prev => ({
        ...prev,
        allYearsChecked: false,
        ebe_by_year: prev.ebe_by_year.map(year => ({ ...year, checked: false })),
      }));

    calcResteAVivre();
  }

  function updateEbeAllYears() {
    let ebe = 0;

    if (!linkedEstimationInfos.allYearsChecked) {
      ebe =
        linkedEstimationInfos.ebe_by_year.reduce((acc, curr) => acc + curr.ebe_retraite, 0) /
        linkedEstimationInfos.ebe_by_year.length;
    }

    setLinkedEstimationInfos(prev => ({
      ...prev,
      allYearsChecked: !prev.allYearsChecked,
      ebe_by_year: prev.ebe_by_year.map(year => ({ ...year, checked: false })),
    }));
    setFormValue("ebe_retraite", ebe);

    calcResteAVivre();
  }

  function setupEbeCheckboxes(ebe, ebeByYear) {
    setHasSetupCheckboxes(true);

    const checkedYears = getCheckedEbeYears(ebe, ebeByYear);

    if (checkedYears.length === ebeByYear.length && !linkedEstimationInfos.allYearsChecked)
      return setLinkedEstimationInfos(prev => ({ ...prev, allYearsChecked: true }));

    const newEbeByYear = ebeByYear.map(year => ({ ...year, checked: checkedYears.includes(year) }));

    if (JSON.stringify(newEbeByYear) !== JSON.stringify(linkedEstimationInfos.ebe_by_year))
      setLinkedEstimationInfos(prev => ({
        ...prev,
        ebe_by_year: newEbeByYear,
      }));
  }

  function getCheckedEbeYears(ebe, ebeByYear) {
    const combinations = [];
    const n = ebeByYear.length;

    for (let i = 1; i < 1 << n; i++) {
      const subset = [];
      for (let j = 0; j < n; j++) {
        if (i & (1 << j)) {
          subset.push(ebeByYear[j]);
        }
      }
      combinations.push(subset);
    }

    for (const subset of combinations) {
      const sum = subset.reduce((acc, val) => acc + val.ebe_retraite, 0);

      if (subset.length > 0 && Math.round(sum / subset.length) === ebe) {
        return subset;
      }
    }

    return [];
  }

  return (
    <div className='financing-plan-fdc-container' ref={containerRef}>
      <section className='financing-plan-cards-container'>
        <Input className='d-none' type='hidden' useForm={inputForm} validation={{}} name='fdc.ratio_cot_sociales_tns' />
        <Input
          className='d-none'
          type='hidden'
          useForm={inputForm}
          validation={{}}
          name='fdc.ratio_cot_sociales_salarie'
        />
        <CardWithHeaders
          padding='20px'
          paddingFooter='10px'
          header={<h2>Besoins</h2>}
          footer={
            <CaseInput
              name='fdc.total_a_financer'
              useForm={inputForm}
              label='TOTAL À FINANCER'
              icon='euro'
              color='white'
              disabled
            />
          }>
          <CaseInput
            {...getSharedProps("prix_vente", "Prix du fonds", true)}
            onChange={() => {
              calcHonorairesJuridiques();
              calcDroitsEnregistrement();
            }}
          />
          <CaseInput
            {...getSharedProps("stocks", "Stocks à reprendre")}
            onChange={() => {
              calcTotalAFinancer();
            }}
          />
          <CaseInput
            {...getSharedProps("honoraires_juridiques", "Honoraires juridiques (en moyenne 3% du prix de vente)")}
            onChange={() => {
              calcTotalAFinancer();
            }}
          />
          <CaseInput
            {...getSharedProps("droits_enregistrement", "Droits d'enregistrement")}
            onChange={() => {
              calcTotalAFinancer();
            }}
          />
          <CaseInput
            {...getSharedProps("fonds_roulement", "Fonds de roulement (trésorerie de démarrage)")}
            onChange={() => {
              calcTotalAFinancer();
            }}
          />
          <CaseInput
            {...getSharedProps("travaux", "Travaux")}
            onChange={() => {
              calcTotalAFinancer();
            }}
          />
          <CaseInput
            {...getSharedProps("materiel_agencements", "Matériel & agencements")}
            onChange={() => {
              calcTotalAFinancer();
            }}
          />
          <MultiplesInputs
            defaultValues={multipleInputsDefaultValues?.fdc?.postes}
            onInputChange={() => calcTotalAFinancer()}
            errors={errors?.fdc?.postes}
            useForm={inputForm}
            name='fdc.postes'
            buttonText='Ajouter un poste à financer'
          />
        </CardWithHeaders>
        <CardWithHeaders
          borderColor='var(--dark-blue-alt)'
          padding='20px'
          paddingFooter='10px'
          header={<h2>Ressources</h2>}
          footer={
            <CaseInput
              name='fdc.total_finance'
              useForm={inputForm}
              label='TOTAL FINANCÉ'
              icon='euro'
              color='white'
              disabled
            />
          }>
          <CaseInput
            {...getSharedProps("ratio_apport_financier", "Apport financier (% du total à financer)", false, "percent")}
            onChange={() => calcApportFinancier()}
          />
          <CaseInput
            {...getSharedProps("apport_financier", "Montant de l'apport", true)}
            onChange={() => calcRatioApportFinancier()}
          />
          <MultiplesInputs
            defaultValues={multipleInputsDefaultValues?.fdc?.apports}
            onInputChange={() => calcMontantAEmprunter()}
            errors={errors?.fdc?.apports}
            useForm={inputForm}
            name='fdc.apports'
            buttonText='Ajouter une source de financement'
          />
          <CaseInput {...getSharedProps("montant_sept_ans", "Montant à emprunter sur 7 ans", true)} disabled />
          <CaseInput
            {...getSharedProps("taux_emprunt", "Taux d'emprunt (TAEG)", true, "percent")}
            onChange={() => calcRbsCreditAnnuel()}
          />
        </CardWithHeaders>
      </section>
      <div className='financing-plan-bottom'>
        <h2 className='financing-plan-title' style={{ "--background-color": "var(--sky-blue)" }}>
          La rentabilité annuelle dégagée par l'affaire
        </h2>
        <div className='relative'>
          <CaseInput
            {...getSharedProps("ebe_retraite", "EBE retraité (avant cotisations de l'exploitant)", true)}
            onChange={() => {
              resetEbeCheckboxes();
              calcResteAVivre();
            }}
            tip={
              linkedEstimationInfos &&
              `Vous pouvez remplir le champ OU cocher ${linkedEstimationInfos.ebe_by_year?.length > 1 ? "les cases" : "la case"} ci-contre`
            }
          />
          {linkedEstimationInfos && (
            <div className='financing-plan-ebe-checkboxes-container'>
              {linkedEstimationInfos.ebe_by_year?.map((year, index) => (
                <Checkbox
                  key={index}
                  name={year.year}
                  label={year.year}
                  checked={year.checked}
                  onChange={e => updateEbeFromEstimation(e, index)}
                />
              ))}
              {linkedEstimationInfos.ebe_by_year?.length > 1 && (
                <Checkbox
                  name='all_ebe_years'
                  label='Moyenne des années renseignées'
                  checked={linkedEstimationInfos.allYearsChecked}
                  onChange={updateEbeAllYears}
                />
              )}
            </div>
          )}
        </div>
        <h2 className='financing-plan-title' style={{ "--background-color": "var(--blue)" }}>
          ...Sert à rembourser le prêt sur l'achat du fonds de commerce...
        </h2>
        <CaseInput
          {...getSharedProps("rbs_credit", "Remboursement annuel du crédit (annuité capital + intérêts)")}
          disabled
          onChange={() => calcResteAVivre()}
        />
        <h2 className='financing-plan-title' style={{ "--background-color": "var(--dark-blue)" }}>
          ...Et permet au repreneur de se rémunérer, payer sa protection sociale et ses impôts
        </h2>
        <CaseInput {...getSharedProps("reste_a_vivre", "Reste à vivre annuel avant imposition")} disabled />
        <h2 className='financing-plan-title last-financing-plan-title'>
          Simulations de revenus annuels nets après cotisations sociales <span>(à titre indicatif)</span>
        </h2>
        <div className='row-1000 gap-lg'>
          <div>
            <p className='financing-plan-subtitle'>Repreneur en statut TNS à l'IS</p>
            <Card bgColor='var(--beige)' padding='20px' className='mb-md'>
              <CaseInput
                {...getSharedProps("cot_sociales_tns")}
                label={`Cotisations sociales annuelles (${displayedRatios.tns}%)`}
              />
              <CaseInput
                {...getSharedProps("rem_nette_tns", "Rémunération annuelle nette avant imposition personnelle")}
              />
              <CaseInput
                {...getSharedProps("rem_nette_mensuelle_tns", "Soit une rémunération mensuelle nette de")}
                className='financing-plan-last-label'
              />
            </Card>
          </div>
          <div>
            <p className='financing-plan-subtitle'>Repreneur en statut salarié</p>
            <Card bgColor='var(--beige)' padding='20px' className='mb-md'>
              <CaseInput
                {...getSharedProps("cot_sociales_salarie")}
                label={`Cotisations sociales annuelles (${displayedRatios.salarie}%)`}
              />
              <CaseInput
                {...getSharedProps("rem_nette_salarie", "Rémunération annuelle nette avant imposition personnelle ")}
              />
              <CaseInput
                {...getSharedProps("rem_nette_mensuelle_salarie", "Soit une rémunération mensuelle nette de")}
                className='financing-plan-last-label'
              />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinancingPlanFdc;
