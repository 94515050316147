import React, { useRef, useEffect } from "react";
import { toggleCollapseElement } from "../../../../utils/Utils.js";
import { selectValidation } from "../../../../utils/formValidation/FormValidation.js";
import Warning from "../../../atoms/icons/general/warning/Warning";

// useForm = {
//     register: () => {},
//     unregister: () => {},
//     setValue: () => {},
//   }
function Select({
  useForm = {},
  label,
  name,
  children,
  error,
  visible = true,
  onChange = () => {},
  onKeyDown = () => {},
  onFocus = () => {},
  className = "",
  shouldStickBottom = true, //false if the input should be aligned to the top of the row
  tip,
  validation = selectValidation,
  value,
}) {
  const { register = () => {}, unregister = () => {}, setValue = () => {} } = useForm;
  const ref = useRef(null);

  useEffect(() => {
    toggleCollapseElement(ref.current, visible, 0);

    if (!visible) {
      unregister(name, { keepValue: true });
    } else {
      register(name, validation);
    }
  }, [visible]);

  function handleChange(e) {
    setValue(name, e.target.value);
    onChange(e);
  }

  return (
    <div
      className={`w-100 d-flex ${className} ${shouldStickBottom ? "" : "mt-0 mb-auto"}`}
      ref={ref}
      style={{ display: visible ? "block" : "none" }}>
      <div className='w-100'>
        <div className='field-container'>
          <label className='form-label'>{label}</label>
          <p className={`label-tip ${tip ? "" : " d-none"}`}>{tip}</p>
          <span className={`input-container ${error ? "with-error" : ""}`}>
            <select
              {...(visible && register(name, validation))}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              id={name}
              disabled={visible !== undefined && !visible}
              tabIndex={visible ? 0 : -1}
              onChange={handleChange}
              value={value}>
              <option value='_null'>...</option>
              {children}
            </select>
            {error && (
              <div className='form-error'>
                <Warning /> <p>{error}</p>
              </div>
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Select;
