import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { max2250Validation } from "../../../../../utils/formValidation/FormValidation.js";
import { ParcoursContext } from "../../../../../context/ParcoursContext.js";
import "./Retraitements.css";
import NumberInput from "../../../../molecules/formComponents/numberInput/NumberInput.js";
import Input from "../../../../molecules/formComponents/input/Input.js";
import Textarea from "../../../../molecules/formComponents/textarea/Textarea.js";
import LightBulb from "../../../../atoms/icons/header/lightBulb/LightBulb.js";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton.js";
import Cursor from "../../../../atoms/icons/parcours/cursor/Cursor.js";
import { formatNumberWithSpaces, formatObjectForPosting, removeSpaces } from "../../../../../utils/Utils.js";
import ContributionCalculatorForm from "../../../contributionCalculatorForm/ContributionCalculatorForm.js";
import Tuto from "../../../../atoms/icons/general/tuto/Tuto.js";
import AppContext from "../../../../../context/AppContext.js";

function Retraitements({ id, index, secondaryParcours }) {
  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const {
    handleTabs,
    estimationValues,
    moveToNextStep,
    parcoursTopRef,
    step,
    setStep,
    submitFormsFdc,
    postValues,
    getStepToGo,
    setGoToStep,
    resetFormsRef,
    CAInputs,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);
  const { showTooltip } = useContext(AppContext);

  const inputForm = { watch, setValue, register, unregister };
  const [recapValues, setRecapValues] = useState(new Array(16));
  const [ebe, setEbe] = useState(0);
  const [calculateurVisible, setCalculateurVisible] = useState(false);
  const anchorRef = useRef(null);
  const inputsWatcher = watch();

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
    resetFormsRef.current[index] = () => reset();
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(["retraitements"], setValue, estimationValues);

    if (!secondaryParcours) setValue("notes.note_step_7", estimationValues?.notes?.note_step_7);
  }, [estimationValues]);

  useEffect(() => {
    scrollForCalculateur();
  }, [calculateurVisible]);

  useEffect(() => {
    calcRecapValues(estimationValues, inputsWatcher, CAInputs);
  }, [estimationValues, CAInputs]);

  function calcRecapValues(estimationValues, inputsWatcher, CAInputs) {
    if (!Object.keys(estimationValues).length || !Object.keys(inputsWatcher).length || !CAInputs.length) return;

    const totalCa = CAInputs.map(input => estimationValues?.ca?.[input.input_name]).reduce(
      (acc, curr) => (acc ?? 0) + (curr ?? 0),
      0,
    );

    let tmp = [
      totalCa,
      estimationValues?.ca?.total_commissions_nettes,
      estimationValues?.impots_taxes?.csg_crds_exploitant,
      inputsWatcher?.retraitements?.salaire_gerant_annuel,
      inputsWatcher?.retraitements?.charges_sociales_gerant_annuelles,
      inputsWatcher?.retraitements?.charges_ext_retraitements,
      inputsWatcher?.retraitements?.remontees_holding_dirigeant,
      estimationValues?.charges?.achat_marchandise,
      estimationValues?.charges?.total_charges_externes,
      inputsWatcher?.retraitements?.majoration_charges_externes,
      estimationValues?.impots_taxes?.total_impots,
      estimationValues?.remunerations?.salaires,
      estimationValues?.remunerations?.charges_sociales,
      estimationValues?.redevance?.redevance,
    ];

    tmp = tmp.map(value => Number(removeSpaces(value) + 0));
    const ebe = tmp.slice(0, 7).reduce((a, b) => a + b, 0) - tmp.slice(7).reduce((a, b) => a + b, 0);

    setRecapValues(tmp.map(value => formatNumberWithSpaces(value, true)));
    setEbe(ebe);
    setValue("retraitements.ebe", ebe);
  }

  async function formSubmit(values) {
    await postValues(formatObjectForPosting(values));

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  function scrollForCalculateur() {
    if (step != 7) return;
    if (calculateurVisible) parcoursTopRef?.current.scrollIntoView({ behavior: "smooth" });
    else
      setTimeout(() => {
        anchorRef?.current.scrollIntoView({ behavior: "smooth" });
      }, 500);
  }

  function setParcoursValues(salaire, charges) {
    setValue("retraitements.salaire_gerant_annuel", salaire);
    setValue("retraitements.charges_sociales_gerant_annuelles", charges);

    calcRecapValues(estimationValues, inputsWatcher, CAInputs);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
      <ContributionCalculatorForm
        showTooltip={showTooltip}
        visible={calculateurVisible}
        setVisible={setCalculateurVisible}
        setParcoursValues={setParcoursValues}
        className={calculateurVisible ? "mb-md" : ""}
      />
      <p className='warning-parcours'>Renseigner cette étape est primordial pour une bonne évaluation</p>
      <p className='text-sky-blue mb-3 text-xs'>Veuillez renseigner tous les champs (même si la somme est "0")</p>
      <fieldset className='borderless-fieldset'>
        <h2>retraitements</h2>
        <div className='row-1000 mb-md'>
          <FilledButton
            bgColor='var(--gold)'
            className='retraitement-button'
            onClick={() => showTooltip("step_7_comment_retraiter_charges.webp")}>
            Comment bien retraiter vos charges pour obtenir une meilleure estimation&nbsp;?
            <Cursor />
            <div className='shine-effect' />
          </FilledButton>
          <FilledButton
            bgColor='var(--dark-blue)'
            className='retraitement-button'
            onClick={() => setCalculateurVisible(!calculateurVisible)}>
            Calculez automatiquement vos charges sociales depuis le salaire net ou brut
            <Cursor />
            <div className='shine-effect' />
          </FilledButton>
        </div>
        <Input
          type='hidden'
          useForm={inputForm}
          name='retraitements.ebe'
          validation={{}}
          error={errors?.retraitements?.ebe?.message}
          visible={false}
        />
        <div className='row-1000' ref={anchorRef}>
          <NumberInput
            onKeyDown={e => {
              if (e.keyCode === 9 && e.shiftKey && !e.ctrlKey) {
                e.preventDefault();
                setStep(step - 1);
              }
            }}
            useForm={inputForm}
            label={
              <>
                rémunération annuelle du (des) dirigeant(s)
                <Tuto onClick={() => showTooltip("step_7_salaire_gerant_annuel.webp")} />
              </>
            }
            onChange={() => calcRecapValues(estimationValues, inputsWatcher, CAInputs)}
            tip='Et des salariés à ne pas conserver'
            name='retraitements.salaire_gerant_annuel'
            error={errors?.retraitements?.salaire_gerant_annuel?.message}
            icon='euro'
          />
          <NumberInput
            useForm={inputForm}
            label={
              <>
                charges sociales annuelles du (des) dirigeant(s)
                <Tuto onClick={() => showTooltip("step_7_charges_sociales_gerant_annuelles.webp")} />
              </>
            }
            onChange={() => calcRecapValues(estimationValues, inputsWatcher, CAInputs)}
            tip='Et des salariés à ne pas conserver'
            name='retraitements.charges_sociales_gerant_annuelles'
            error={errors?.retraitements?.charges_sociales_gerant_annuelles?.message}
            icon='euro'
          />
        </div>
        <NumberInput
          useForm={inputForm}
          label={
            <>
              charges externes a retraiter & complements de remuneration du/des dirigeants
              <Tuto onClick={() => showTooltip("step_7_charges_ext_retraitements.webp")} />
            </>
          }
          onChange={() => calcRecapValues(estimationValues, inputsWatcher, CAInputs)}
          name='retraitements.charges_ext_retraitements'
          error={errors?.retraitements?.charges_ext_retraitements?.message}
          icon='euro'
          bgColor='var(--pale-blue)'
        />
        <NumberInput
          useForm={inputForm}
          label={
            <>
              majoration des charges externes
              <Tuto onClick={() => showTooltip("step_7_majoration_charges_externes.webp")} />
            </>
          }
          onChange={() => calcRecapValues(estimationValues, inputsWatcher, CAInputs)}
          name='retraitements.majoration_charges_externes'
          error={errors?.retraitements?.majoration_charges_externes?.message}
          icon='euro'
        />
        <NumberInput
          useForm={inputForm}
          label={
            <>
              remontées holding dirigeant(s)
              <Tuto onClick={() => showTooltip("step_7_remontees_holding_dirigeant.webp")} />
            </>
          }
          onChange={() => calcRecapValues(estimationValues, inputsWatcher, CAInputs)}
          name='retraitements.remontees_holding_dirigeant'
          error={errors?.retraitements?.remontees_holding_dirigeant?.message}
          icon='euro'
          {...(secondaryParcours && handleTabs(step, index, () => handleSubmit(formSubmit)()))}
        />
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2 className='mb-3'>vérifiez vos données chiffrées</h2>
        <p className='form-subtitle'>
          Ce récapitulatif vous permet de vérifier les chiffres que vous avez renseignés. Il vous indique le montant de
          rentabilité (EBE) retraitée issue du calcul. L'évaluation étant notamment basée sur l'EBE retraité, vérifiez
          sa cohérence et au besoin, ajustez-le en corrigeant vos chiffres renseignés en étapes 4 à 7.
        </p>
        <div className='recap-ebe'>
          <div className='recap-row recap-bold'>
            <p>EBE retraité</p>
            <p>{formatNumberWithSpaces(ebe, true)}€</p>
          </div>
          <div className='recap-header-first'>Obtenu ainsi :</div>
          <div className='recap-row'>
            <p className='text-gold'>Chiffre d'affaires</p>
            <p className='text-gold'>{recapValues[0]}€</p>
          </div>
          {estimationValues?.ca?.total_commissions_nettes && (
            <div className='recap-row'>
              <p className='text-gold'>Commissions nettes</p>
              <p className='text-gold'>{recapValues[1]}€</p>
            </div>
          )}
          <div className='recap-header'>
            <div className='minus' />
          </div>
          <div className='recap-row'>
            <p>Achat marchandises & mat° premières</p>
            <p>{recapValues[7]}€</p>
          </div>
          <div className='recap-row'>
            <p>Autres achats & charges externes</p>
            <p>{recapValues[8]}€</p>
          </div>
          <div className='recap-row'>
            <p>Charges externes majorées</p>
            <p>{recapValues[9]}€</p>
          </div>
          <div className='recap-row'>
            <p>Impôts & taxes (hors IS)</p>
            <p>{recapValues[10]}€</p>
          </div>
          <div className='recap-row'>
            <p>Rémunérations annuelles</p>
            <p>{recapValues[11]}€</p>
          </div>
          <div className='recap-row'>
            <p>Charges sociales annuelles</p>
            <p>{recapValues[12]}€</p>
          </div>
          <div className='recap-row'>
            <p>Autres charges et redevances</p>
            <p>{recapValues[13]}€</p>
          </div>
          <div className='recap-header'>
            <div className='minus' />
            <div className='minus-plus' />
          </div>
          <h2 className='text-gold p-0 mb-md'>Retraitements</h2>
          <div className='recap-row'>
            <p className='text-gold'>CSG CRDS exploitant</p>
            <p className='text-gold'>{recapValues[2]}€</p>
          </div>
          <div className='recap-row'>
            <p className='text-gold'>Rém° annuelle dirigeant(s)</p>
            <p className='text-gold'>{recapValues[3]}€</p>
          </div>
          <div className='recap-row'>
            <p className='text-gold'>Charges soc° annuelles dirigeant(s)</p>
            <p className='text-gold'>{recapValues[4]}€</p>
          </div>
          <div className='recap-row'>
            <p className='text-gold'>Compléments de rém° dirigeant(s)</p>
            <p className='text-gold'>{recapValues[5]}€</p>
          </div>
          <div className='recap-row'>
            <p className='text-gold'>Remontées holding dirigeant(s)</p>
            <p className='text-gold'>{recapValues[6]}€</p>
          </div>
          <div className='recap-header-last'>
            <LightBulb width='35px' />
            Moduler vos retraitements impacte directement l'EBE retraité
          </div>
          <div className='recap-row'>
            <p>Le résultat net est de</p>
            <p>{formatNumberWithSpaces(estimationValues?.resultat_net?.resultat_net_comptable, true)}€</p>
          </div>
        </div>
      </fieldset>
      {!secondaryParcours && (
        <fieldset className='borderless-fieldset'>
          <h2 className='pb-0'>Notes et remarques</h2>
          <Textarea
            useForm={inputForm}
            name='notes.note_step_7'
            error={errors?.notes?.note_step_7?.message}
            className='mt-0'
            validation={max2250Validation}
            {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
          />
        </fieldset>
      )}
    </form>
  );
}

export default Retraitements;
